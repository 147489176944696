import React, { Component } from "react";
import PatientDataService from "../../services/patient.service";
import PatientSchema from "./patients-schema"
import DatePicker from 'react-datepicker'
import ReCAPTCHA from "react-google-recaptcha";
import "react-datepicker/dist/react-datepicker.css";
import firestore from "../../firebase";
//import MailDataService from "../../services/mail.service";

const patindex = firestore.collection("/public").doc('patIndex');

async function getDocument() {
    // [START get_document]
    const doc = await patindex.get();
    if (!doc.exists) {
        console.log('No such document!');
    } else {
        return doc.data()['size']
    }
    // [END get_document]
}

const STAGE = 0;
const recaptchaRef = React.createRef();
const patientTemplate_strings = PatientSchema.filter(checkStage);

function checkStage(s) {
    return s["stage"] <= STAGE;
}


export default class AddPatient extends Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCovidSymptoms = this.handleCovidSymptoms.bind(this)
        this.handleCovidRadio = this.handleCovidRadio.bind(this);
        this.handleCovidInput = this.handleCovidInput.bind(this);
        this.savePatient = this.savePatient.bind(this);
        this.returnFormList = this.returnFormList.bind(this);
        this.returnCovidForm = this.returnCovidForm.bind(this);
        this.newPatient = this.newPatient.bind(this);

        this.state = {
            agreement: false,
            submitted: false,
            captcha: false,
            pickedDate: new Date(),
            "100-stage": 0,
            covid: {
                q2_: false,
                q2b: "",
                q3a: false,
                q3ab: "",
                q3b: false,
                q4_: false,
                q4a: "",
                q5_: false,
                q5a: "",
                symptoms: {
                    "Fever": false,
                    "Difficulty breathing": false,
                    "Cough": false,
                    "Running nose": false,
                    "Sore throat": false,
                    "Body aches": false,
                    "Severe weakness": false,
                    "Persistent pain and pressure in chest": false
                }
            }
        }

    }

    componentDidMount() {
        // eslint-disable-next-line
        patientTemplate_strings.map((each) => {
            if (each.type === "text") {
                this.setState({
                    [each.id]: ""
                });
            }
            else if (each.type === "select") {
                this.setState({
                    [each.id]: each.options[0]
                });

            }

            this.setState({
                covid: {
                    q2_: false,
                    q2b: "",
                    q3a: false,
                    q3ab: "",
                    q3b: false,
                    q4_: false,
                    q4a: "",
                    q5_: false,
                    q5a: "",
                    symptoms: {
                        "Fever": false,
                        "Difficulty breathing": false,
                        "Cough": false,
                        "Running nose": false,
                        "Sore throat": false,
                        "Body aches": false,
                        "Severe weakness": false,
                        "Persistent pain and pressure in chest": false
                    }
                }
            })


        });
    }

    UNSAFE_componentWillMount() {
        getDocument()
            .then(index => {
                this.setState({ index });
            })
            .catch((err) => {
                // Handle error here (maybe fetch user?)
            })
    }

    returnFormList() {
        let FormItems;
        let fullFORM = [];
        // eslint-disable-next-line
        patientTemplate_strings.map((d, index) => {
            if (d.type === "text" || d.type === "email") {
                FormItems = (<tr key={index}>
                    <td key={index + 1000}>
                        <label key={index + 1250} htmlFor={d.id} className="block">
                            {d.text}
                        </label>
                    </td>
                    <td key={index + 1500}>
                        <input
                            key={index + 1750}
                            type={d.type}
                            name={d.id}
                            required={d.required}

                            value={this.state[d.id] || ""}
                            id={d.id}
                            onChange={this.handleInputChange}
                        /></td>
                </tr>);
                fullFORM.push(FormItems);
            }
            else if (d.type === "select") {
                let optionItems = d.options.map((o, index2) => <option key={index + (3000 + (1000 * index2))} value={o}>{o}</option>);
                FormItems = (<tr key={index}>
                    <td key={index + 2000}>
                        <label key={index + 2250} htmlFor={d.id} className="block">
                            {d.text}
                        </label>
                    </td>
                    <td key={index + 2500}>
                        <select
                            key={index + 2750}
                            required={d.required}
                            value={this.state[d.id] || ""}
                            id={d.id}
                            onChange={this.handleChange}
                        >
                            {optionItems}
                        </select></td>
                </tr>);

                fullFORM.push(FormItems);
            }
            else if (d.type === "date") {
                FormItems = (<tr key={index}>
                    <td key={index + 1000}>
                        <label key={index + 1250} htmlFor={d.id} className="block">
                            {d.text}
                        </label>
                    </td>
                    <td key={index + 1500} >
                        <DatePicker
                            selected={this.state.pickedDate}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                                const formattedDate = date.getDate().toString() + "/" + date.getMonth().toString() + "/" + + date.getFullYear().toString();
                                this.setState({
                                    pickedDate: date,
                                    [d.id]: formattedDate,
                                });
                            }}
                        />
                    </td>
                </tr>);
                fullFORM.push(FormItems);
            }
        });

        return fullFORM
    }

    returnCovidForm() {
        return (
            <>
                <tr>
                    <td>
                        <label htmlFor={"symptoms"} className="block">
                            Currently, are you experiencing any of the symptoms below?
                        </label>
                    </td>

                    <td>
                        <input
                            type="checkbox"
                            value="Fever"
                            checked={this.state.covid.symptoms["Fever"] || false}
                            onChange={this.handleCovidSymptoms}
                        />
                        Fever
                        <br />
                        <input
                            type="checkbox"
                            value="Difficulty breathing"
                            checked={this.state.covid.symptoms["Difficulty breathing"] || false}
                            onChange={this.handleCovidSymptoms}
                        />
                        Difficulty breathing
                        <br />
                        <input
                            type="checkbox"
                            value="Cough"
                            checked={this.state.covid.symptoms["Cough"] || false}
                            onChange={this.handleCovidSymptoms}
                        />
                        Cough
                        <br />
                        <input
                            type="checkbox"
                            value="Running nose"
                            checked={this.state.covid.symptoms["Running nose"] || false}
                            onChange={this.handleCovidSymptoms}
                        />
                        Running nose
                        <br />
                        <input
                            type="checkbox"
                            value="Sore throat"
                            checked={this.state.covid.symptoms["Sore throat"] || false}
                            onChange={this.handleCovidSymptoms}
                        />
                        Sore throat
                        <br />
                        <input
                            type="checkbox"
                            value="Body aches"
                            checked={this.state.covid.symptoms["Body aches"] || false}
                            onChange={this.handleCovidSymptoms}
                        />
                        Body aches
                        <br />
                        <input
                            type="checkbox"
                            value="Severe weakness"
                            checked={this.state.covid.symptoms["Severe weakness"] || false}
                            onChange={this.handleCovidSymptoms}
                        />
                        Severe weakness
                        <br />
                        <input
                            type="checkbox"
                            value="Persistent pain and pressure in chest"
                            checked={this.state.covid.symptoms["Persistent pain and pressure in chest"] || false}
                            onChange={this.handleCovidSymptoms}
                        />
                        Persistent pain and pressure in chest
                        <br />
                    </td>
                </tr>

                <tr>
                    <td>
                        <label htmlFor={"q2"} className="block">
                            Did you suffer from Covid-19 in the past?
                        </label>
                    </td>
                    <td>
                        <input
                            type="radio"
                            value="Yes"
                            id="q2_y"
                            checked={this.state.covid.q2_}
                            onChange={this.handleCovidRadio}
                        />
                        Yes

                        <input
                            type="radio"
                            value="No"
                            id="q2_n"
                            checked={!this.state.covid.q2_}
                            onChange={this.handleCovidRadio}
                        />
                        No
                    </td>
                </tr>

                {
                    this.state.covid.q2_ &&

                    <tr>
                        <td>
                            <label htmlFor={"q2b"} className="block">
                                Please specify when:
                        </label>
                        </td>
                        <td>
                            <input
                                type={"text"}
                                required={this.state.covid.q2_}
                                value={this.state.covid.q2b || ""}
                                id={"q2b"}
                                onChange={this.handleCovidInput}
                            />
                        </td>
                    </tr>
                }

                <tr>
                    <td>
                        <label htmlFor={"q3a"} className="block">
                            Did you travel with in the last 1 month?
                        </label>
                    </td>
                    <td>
                        <input
                            type="radio"
                            value="Yes"
                            id="q3ay"
                            checked={this.state.covid.q3a}
                            onChange={this.handleCovidRadio}
                        />
                        Yes

                        <input
                            type="radio"
                            value="No"
                            id="q3an"
                            checked={!this.state.covid.q3a}
                            onChange={this.handleCovidRadio}
                        />
                        No
                    </td>
                </tr>

                {
                    this.state.covid.q3a &&

                    <tr>
                        <td>
                            <label htmlFor={"q3ab"} className="block">
                                Please specify when and where:
                        </label>
                        </td>
                        <td>
                            <input
                                type={"text"}
                                required={this.state.covid.q3a}
                                value={this.state.covid.q3ab || ""}
                                id={"q3ab"}
                                onChange={this.handleCovidInput}
                            />
                        </td>
                    </tr>
                }

                <tr>
                    <td>
                        <label htmlFor={"q3b"} className="block">
                            Did you have close contact with any confirmed case of Covid-19 in last 2 weeks
                        </label>
                    </td>
                    <td>
                        <input
                            type="radio"
                            value="Yes"
                            id="q3by"
                            checked={this.state.covid.q3b}
                            onChange={this.handleCovidRadio}
                        />
                        Yes

                        <input
                            type="radio"
                            value="No"
                            id="q3bn"
                            checked={!this.state.covid.q3b}
                            onChange={this.handleCovidRadio}
                        />
                        No
                    </td>
                </tr>

                <tr>
                    <td>
                        <label htmlFor={"q4"} className="block">
                            Did you get a Covid test recently?
                        </label>
                    </td>
                    <td>
                        <input
                            type="radio"
                            value="Yes"
                            id="q4_y"
                            checked={this.state.covid.q4_}
                            onChange={this.handleCovidRadio}
                        />
                        Yes

                        <input
                            type="radio"
                            value="No"
                            id="q4_n"
                            checked={!this.state.covid.q4_}
                            onChange={this.handleCovidRadio}
                        />
                        No
                    </td>
                </tr>

                {
                    this.state.covid.q4_ &&

                    <tr>
                        <td>
                            <label htmlFor={"q4a"} className="block">
                                Please specify what type (RT-PCR or Covid Antigen) and when:
                        </label>
                        </td>
                        <td>
                            <input
                                type={"text"}
                                required={this.state.covid.q4_}
                                value={this.state.covid.q4a || ""}
                                id={"q4a"}
                                onChange={this.handleCovidInput}
                            />
                        </td>
                    </tr>
                }

                <tr>
                    <td>
                        <label htmlFor={"q5"} className="block">
                            Have you received the Covid vaccine?
                        </label>
                    </td>
                    <td>
                        <input
                            type="radio"
                            value="Yes"
                            id="q5_y"
                            checked={this.state.covid.q5_}
                            onChange={this.handleCovidRadio}
                        />
                        Yes

                        <input
                            type="radio"
                            value="No"
                            id="q5_n"
                            checked={!this.state.covid.q5_}
                            onChange={this.handleCovidRadio}
                        />
                        No
                    </td>
                </tr>

                {
                    this.state.covid.q5_ &&

                    <tr>
                        <td>
                            <label htmlFor={"q5a"} className="block">
                                Please specify when:
                        </label>
                        </td>
                        <td>
                            <input
                                type={"text"}
                                required={this.state.covid.q5_}
                                value={this.state.covid.q5a || ""}
                                id={"q5a"}
                                onChange={this.handleCovidInput}
                            />
                        </td>
                    </tr>
                }
            </>
        )
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleCovidSymptoms(event) {
        const target = event.target;
        const value = target.checked;

        this.setState({
            covid: {
                ...this.state.covid,
                symptoms: {
                    ...this.state.covid.symptoms,
                    [target.value]: value
                },

            }
        })
    }

    handleCovidRadio(event) {
        const target = event.target;
        const id = target.id;
        const q = id.substring(0, 3);
        const ans = id.substring(3, 4);
        let d = ans === 'y'

        this.setState({
            covid: {
                ...this.state.covid,
                [q]: d,

            }
        })

    }

    handleCovidInput(event) {
        const target = event.target;
        const value = target.value;
        const id = target.id;

        this.setState({
            "covid": {
                ...this.state.covid,
                [id]: value,

            }
        })
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }

    onChangeCaptcha = value => {
        if (value === null) {
            this.setState({ captcha: false })
        }
        else {
            console.log("captcha verified.");
            this.setState({ captcha: true })
        }
    };

    savePatient = (event) => {
        event.preventDefault();
        let idx = this.state.index;
        let data = {};
        let currentdate = new Date();
        let unixtime = + new Date();
        let datetime = currentdate.getDate() + "/"
            + (currentdate.getMonth() + 1) + "/"
            + currentdate.getFullYear() + " "
            + currentdate.getHours() + ":"
            + currentdate.getMinutes() + ":"
            + currentdate.getSeconds();

        let PID = "P-" + (currentdate.getMonth() + 1).toString() + Math.floor(Math.random() * (100 - 10 + 1) + 10).toString() + idx.toString();
        // eslint-disable-next-line
        patientTemplate_strings.map((each) => {
            if (this.state[each.id]) {
                data[each.id] = this.state[each.id]
            }
        });
        data["1-04-registerDT"] = unixtime;
        data["1-05-updatedDT"] = datetime + " Registered";
        data["100-stage"] = "1";
        data["1-03-PID"] = PID;
        data["1-01-POI"] = "Reception";
        data["patientMedData"] = {};
        data['1-02-appointments'] = [];
        data['3-11-COVIDSCREEN'] = this.state.covid;

        if (this.state.captcha) {

            PatientDataService.create(data)
                .then(() => {
                    console.log("Created new item successfully!");
                    //MailDataService.sendNewRegEmail(this.state['0-11-email'], PID, this.state['0-01-name']);
                    this.setState({
                        submitted: true,
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            this.setState({
                error: "Sorry, the captcha wasn't verified. Please try again."
            })
        }

        patindex.update({
            size: idx + 1
        })

    };

    newPatient() {
        // eslint-disable-next-line
        patientTemplate_strings.map((each) => {
            this.setState({
                [each.id]: ""
            });
        });
        this.setState({
            submitted: false,
            agreement: false,
            captcha: false
        });
    }

    render() {
        return (
            <div>
                <h1>New Patient Registration Form</h1>
                <br /><br />
                {this.state.submitted ? (
                    <div>
                        <h4>Form submitted successfully.</h4>
                        <button style={{ width: "13rem", height: "3.5rem" }} id={"form-submit"} onClick={this.newPatient}>
                            Add More
                        </button>

                    </div>
                ) : (

                    <div>

                        <form onSubmit={this.savePatient}>

                            <table id={"form-table"}>
                                <tbody>
                                    {this.returnFormList()}
                                </tbody>
                            </table>

                            <br />
                            <h3>COVID-19 SCREENING FORM</h3>
                            <br />

                            <table id={"form-table-covid"}>
                                <tbody>
                                    {this.returnCovidForm()}
                                </tbody>
                            </table>

                            <br />
                            <h3>GENERAL CONSENT FOR TREATMENT</h3>
                            <br />
                            <div className={"col-md-6"} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <p>By clicking submit, I hereby consent to the authorize Prana, its physicians and medical personnel to administer and perform medical examination, investigations, medical treatment, procedures, vaccinations and immunizations during the course of patient care.
                                    <br /><br />
                                    I also consent to the use of medical information for research or insurance purpose. I also consent to Prana contacting me / my family if needed, regarding appointments and follow-up care.
                                    <br /><br />
                                    Kindly show your Photo ID (Pan card/ Aadhar Card/ Driving License) issued by authorities when requested.
                                    <br /><br />
                                </p>
                                <div className={"capt"} >
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={process.env['REACT_APP_CAPTCHA_KEY']}
                                        //sitekey={process.env['REACT_APP_CAPTCHA_DEV_KEY']}
                                        onChange={this.onChangeCaptcha}
                                    />
                                </div>
                                <div className={"subm"} >
                                    <input id={"form-submit"} type="submit" value="Submit" />
                                    {this.state.error !== null && (
                                        <div style={{ color: '#f15454' }}>
                                            {this.state.error}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>


                    </div>

                )}
            </div>
        );
    }
}