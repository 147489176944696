import React, { useState, useEffect } from "react";
import Header from "../header";
import Footer from "../footer";
import MailDataService from "../../services/mail.service";
import firestore from "../../firebase";
import EventDataService from "../../services/event.service";

async function getDocument(eid) {
    // [START get_document]
    const patindex = firestore.collection("/events").doc(eid);
    const doc = await patindex.get();
    if (!doc.exists) {
        console.log('No such document!');
    } else {
        return doc.data()
    }
    // [END get_document]
}

const Message = ({ message }) => (
    <section>
        <p>{message}</p>
    </section>
);
export default function EventReturn() {
    const [message, setMessage] = useState("");
    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
            const entries = performance.getEntriesByType("navigation");
            if(entries[0].type !== 'reload'){
                getDocument(query.get("e"))
                    .then(data => {
                        // eslint-disable-next-line
                        MailDataService.sendEventReg(query.get("em"), data, query.get("p"), query.get("n"));
                        let Registered = data['Registered'];
                        Registered.push({
                            "Name": query.get("n"),
                            "Phone": query.get("ph"),
                            "Email": query.get("em"),
                            "PID": query.get("p")
                        });
                        data['Registered'] = Registered;
                        EventDataService.update(query.get("e"),data)
                            .then(() => {
                                console.log("event updated")
                            })
                            .catch((e) => {
                                console.log(e);
                            });

                    })
                    .catch((err) => {
                        // Handle error here (maybe fetch user?)
                    });

            }

        }
        if (query.get("canceled")) {
            setMessage(
                "Transaction canceled -- Please try again later. Sorry for the inconvenience."
            );
        }
    }, []);


    return (
        <div>
            <Header/>
            <div style={{padding: "5%", textAlign: "center"}}>
                <h1>
                    <Message message={message} />
                </h1>
            </div>

            <Footer/>
        </div>
    );
}