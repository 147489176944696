import React from 'react'
import ServiceTemplate from "./serviceTemplate";

const HTMLcont = (
    <>
        <h5 className="subs" style={{"textAlign":"justify"}}>
            Psychotherapy is a way to help people with a broad variety of mental illnesses and
            emotional difficulties. Psychotherapy can help eliminate or control troubling
            symptoms so a person can function better and can increase well-being and healing.
            A team of expert Psychologists, Psychotherapists and Counsellors offer
            Psychotherapy and counselling sessions at Prana to address anxiety, depression,
            and behavioural issues.
            <br/><br/>
            Problems helped by psychotherapy include difficulties in coping with daily life; the
            impact of trauma, medical illness or loss, the death of a loved one; and specific
            mental disorders. We at Prana offer different types of Psychotherapies like CBT, TA,
            Talk therapy and more. Psychotherapy may be used in combination with medication
            or other therapies. We specialize in Anxiety, Depression, Adolescent issues, Marital
            and relationship counselling, Life coaching and enhancing peak performance.
            <br/><br/>
            Psychotherapy sessions are also offered online. Sessions are typically held once or
            twice a week for about 45 minutes or 1 hour. Both patient and therapist need to be
            actively involved in psychotherapy. The trust and relationship between a person and
            his/her therapist are essential to working together effectively and benefiting from
            psychotherapy. The goals of treatment and arrangements for how often and how
            long to meet are planned jointly by the patient and therapist.
            <br/><br/>
            <bold style={{fontWeight:600}}>At Prana we offer counselling and therapies to manage:</bold>
        </h5>
        <ul className="list-group">
            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Anxiety
            </li>
            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Depression
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Bipolar depression
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Chronic Stress
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                OCD
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Mental health issues
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Adolescent issues
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Marital issues
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Relationship issues
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Behaviour modifications
            </li>
        </ul>


    </>
);

const content =
    {
        title: "Counselling and Psychotherapy",
        imgurl: "#",
        para: HTMLcont
    };


class Counselling extends React.Component {
    render() {
        return (
            <ServiceTemplate serviceContent={content}/>
        )
    }
}

export default Counselling