import React from "react";
import { auth, createUserDocument } from "../../../firebase";
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/lab/Autocomplete/Autocomplete";
import {TeamInfo} from "../../../providers/TeamProvider";

const DOCTORS = TeamInfo;

class CreateUser extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            email: "",
            designation : "",
            password: "",
            displayName: "",
            error: null,
            doctor:false,
            nurse: false,
            reception: false,
            admin: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.createUserWithEmailAndPasswordHandler = this.createUserWithEmailAndPasswordHandler.bind(this);
        this.handleDocChange = this.handleDocChange.bind(this);
    }


    async createUserWithEmailAndPasswordHandler(event){
        event.preventDefault();
        const displayName = this.state.displayName;
        const designation = this.state.designation;
        const isNurse = this.state.nurse;
        const isReceptionist = this.state.reception;
        const isAdmin = this.state.admin;
        const isDoctor = this.state.doctor;
        const access = true;
        try{
            const {user} = await auth.createUserWithEmailAndPassword(this.state.email, this.state.password);
            createUserDocument(user, {displayName, designation, isNurse, isReceptionist, isAdmin, isDoctor, access});
        }
        catch(error){
            this.setState({
                error: "Error Signing up with email and password"
            });
        }

        this.setState({
            displayName: "",
            email: "",
            password: "",
            designation: "",
            nurse: false,
            reception: false,
            admin: false
        })
    };

    handleDocChange(e,newValue){
        if(newValue){
            this.setState({displayName: newValue.name});
        }


    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'admin') {
            this.setState({
                nurse: value,
                reception: value,
                doctor: value
            })
        }

        this.setState({
            [name]: value
        });
    }

    render() {

        return (

            <div>
                <h1>Create User</h1>
                <div>
                    {this.state.error !== null && (
                        <div>
                            {this.state.error}
                        </div>
                    )}

                    <form onSubmit={this.createUserWithEmailAndPasswordHandler}>
                        <br/><br/>
                        <table id={"form-table"}>
                            <tbody>
                            <tr>
                                <td>
                                    <label htmlFor="displayName" className="block">
                                        Name:
                                    </label>
                                </td>
                                <td>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        freeSolo
                                        options={DOCTORS}
                                        getOptionLabel={(option) => option.name}
                                        style={{ width: 300 }}
                                        onChange={this.handleDocChange}
                                        renderInput={(params) => <TextField {...params} label="Doctor" variant="outlined" />}
                                    />
                                </td>
                            </tr>
                            <br/>
                            <tr>
                                <td>
                                    <label htmlFor="email" className="block">
                                        Email:
                                    </label>
                                </td>
                                <td>
                                    <input
                                        type="email"
                                        name="email"
                                        value={this.state.email}
                                        id="email"
                                        onChange={this.handleInputChange}
                                    />
                                </td>
                            </tr><br/>
                            <tr>
                                <td>
                                    <label htmlFor="Password" className="block">
                                        Password:
                                    </label>
                                </td>
                                <td>
                                    <input
                                        type="password"
                                        name="password"
                                        value={this.state.password}
                                        id="password"
                                        onChange={this.handleInputChange}
                                    />
                                </td>
                            </tr><br/>
                            <tr>
                                <td>
                                    <label htmlFor="designation" className="block">
                                        Designation:
                                    </label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="designation"
                                        value={this.state.designation}
                                        id="designation"
                                        onChange={this.handleInputChange}
                                    />
                                </td>
                            </tr><br/>
                            <tr>
                                <td>
                                    <label>
                                        Doctor
                                        <input
                                            name="doctor"
                                            type="checkbox"
                                            checked={this.state.doctor}
                                            onChange={this.handleInputChange}/>
                                    </label></td>
                                <td>
                                    <label>
                                        Nurse
                                        <input
                                            name="nurse"
                                            type="checkbox"
                                            checked={this.state.nurse}
                                            onChange={this.handleInputChange}/>
                                    </label>
                                </td>
                                <td>
                                    <label>
                                        Reception
                                        <input
                                            name="reception"
                                            type="checkbox"
                                            checked={this.state.reception}
                                            onChange={this.handleInputChange}/>
                                    </label></td>
                                <td>
                                    <label>
                                        Admin
                                        <input
                                            name="admin"
                                            type="checkbox"
                                            checked={this.state.admin}
                                            onChange={this.handleInputChange}/>
                                    </label></td>
                            </tr>
                            </tbody>
                        </table>
                        <input id={"form-submit"} type="submit" value="Submit" />
                    </form>

                </div>
            </div>

        );
    }
}

export default CreateUser;