import React, {Component} from "react";
import { UserContext } from "../providers/UserProvider";
import Footer from "./footer";
import InternalHeader from "./internalHeader";
import AppointmentDataService from "../services/appointments.service"

export default class Databaser extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.onDataChange = this.onDataChange.bind(this);
        this.addToDB = this.addToDB.bind(this);

        this.state = {
            appointments: {},
            currentAppointment: null,
            currentIndex: -1
        };

        this.unsubscribe = undefined;
    }

    componentDidMount = async () => {
        const currUser = this.context;
        this.unsubscribe = AppointmentDataService.getAll().onSnapshot(this.onDataChange);

        this.setState({
            user: currUser,
        });
    };

    componentWillUnmount() {
        this.unsubscribe();
    }

    onDataChange(items) {
        let appointments = {};

        items.forEach((item) => {
            appointments[item.id] = item.data()
        });

        this.setState({
            appointments: appointments,
        });
    }

    addToDB(){
        const a = this.state.appointments;
        // eslint-disable-next-line
        Object.keys(a).map((key, index) => {
            a[key]['appointmentType'] = "Doctor's Appointment";
            AppointmentDataService.update(key, a[key])
                .then(() => {
                    this.setState({
                        message: "The event was updated successfully!",
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        });
        console.log(a)
    }


    render() {
        if(this.context){
            return (
                <div>
                    <InternalHeader user={this.context}/>
                    <div style={{textAlign: "center"}}>
                        <div className="list row">
                            <div className="col-md-3">
                                <h4>DB CHANGE</h4>
                                <button
                                    id={"submit-button"}
                                    style={{width:'80%'}}
                                    onClick={this.addToDB}
                                >
                                    Change
                                </button>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            );
        }
        else{
            return (<>.</>)
        }


    }

}