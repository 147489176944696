import React, { useContext } from "react";
import InternalHeader from "../internalHeader"
import Header from "../header";
import Footer from "../footer";
import SignIn from "./SignIn";
import Dashboard from "./Dashboard";
import { UserContext } from "../../providers/UserProvider";

function Login() {
    const user = useContext(UserContext);

    if (user) {
        return (
            <div>
                <InternalHeader user={user}/>
                <div>
                    <div style={{padding: "1% 3% 1% 3%"}}>
                        <Dashboard />
                    </div>
                </div>

                <Footer/>
            </div>
        )
    }
    return (
        <div>
            <Header/>
            <div>
                <div style={{padding: "5%", textAlign: "center"}}>
                    <SignIn/>
                </div>
            </div>

            <Footer/>
        </div>
    )


    }

export default Login
