import React, { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import PatientList from "../../patientsdb/patients-list.component"
import Footer from "../../footer";
import InternalHeader from "../../internalHeader";

const Appointments = () => {
    const user = useContext(UserContext);
    return (
        <div>
            {user &&
            <InternalHeader user={user}/>
            }
            <div style={{textAlign: "center"}}>
                {user &&
                <PatientList/>
                }
            </div>
            <Footer/>
        </div>

    )
};

export default Appointments;