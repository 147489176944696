import React from 'react';
import { makeStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Card from 'react-bootstrap/Card'
import Typography from '@mui/material/Typography';
import firestore from "../../firebase";
import PatientDataService from "../../services/patient.service";
import MailDataService from "../../services/mail.service";
import ReCAPTCHA from "react-google-recaptcha";
import { loadStripe } from '@stripe/stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const stripePromise = loadStripe(process.env['REACT_APP_STRIPE_CLIENT_KEY']);


const patindex = firestore.collection("/public").doc('patIndex');
const recaptchaRef = React.createRef();


async function getDocument() {
    // [START get_document]
    const doc = await patindex.get();
    if (!doc.exists) {
        console.log('No such document!');
    } else {
        return doc.data()['size']
    }
    // [END get_document]
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Existing User?', 'Register User', 'Payment'];
}



export default function RegistrationStepper(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [Index, setIndex] = React.useState(0);
    const [Message, setMessage] = React.useState("");
    const [PID, setPID] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [Name, setName] = React.useState("");
    const [Age, setAge] = React.useState("");
    const [Gender, setGender] = React.useState("");
    const [Phone, setPhone] = React.useState("");
    const [Heard, setHeard] = React.useState("");
    const [registered, setRegistered] = React.useState(false);
    const [Captcha, setCaptcha] = React.useState(false);
    const steps = getSteps();



    const isStepOptional = (step) => {
        return false;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        setMessage("");
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setMessage("");
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleRegT = () => {
        setRegistered(true);
        handleNext();
    };

    const handleRegF = () => {
        setRegistered(false);
        handleNext();
    };

    const handlePID = (event) =>{
        setPID(event.target.value)
    };

    const handleEmail = (event) =>{
        setEmail(event.target.value)
    };

    const handleName = (event) =>{
        setName(event.target.value)
    };

    const handleAge = (event) =>{
        setAge(event.target.value)
    };

    const handleGender = (event) =>{
        setGender(event.target.value)
    };

    const handlePhone = (event) =>{
        setPhone(event.target.value)
    };

    const handleHeard = (event) =>{
        setHeard(event.target.value)
    };


    const onChangeCaptcha = value => {
        if (value === null){
            setCaptcha(false)
        }
        else {
            console.log("captcha verified.");
            setCaptcha(true)
        }
    };

    const handleClick = async (event) => {
        const stripe = await stripePromise;
        const response = await fetch("https://api.pranaintegrative.com/create-checkout-session", {
            method: "POST",
            mode: 'cors',
            headers: {
                //'Content-Type': 'application/json'
                 'Content-Type': 'application/x-www-form-urlencoded',

            },
            body: new URLSearchParams({
                'email':Email,
                'name': Name,
                'phone': Phone,
                'eventID':props.eid,
                'PID':PID,
                'cost':props.cost,
                'imageU':props.imageURL,
                'eventName':props.title
            }),
        });
        const session = await response.json();
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        }
    };


    const validateForm = (fields) => {
        let valid = true;
        fields.forEach( (each) =>
            {
                if(each.length < 1){
                    valid = false
                }
            }
        );

        return valid;
    };

    const handleForm = (e) =>{
        //check PID
        e.preventDefault();
        let data = {}
        let fields = [];
        if(registered){
            fields = [PID, Email];
        }else{
            fields = [Name, Age, Gender, Email, Phone, Heard];
            data = {
                '0-01-name': Name,
                '0-02-age': Age,
                '0-04-gender': Gender,
                '0-11-email': Email,
                '0-12-phone': Phone,
                '0-13-howHeard': Heard
            };
        }
        if (validateForm(fields)){
            // eslint-disable-next-line
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(re.test(String(Email).toLowerCase())){
                if(!registered){
                    getDocument()
                        .then(index => {
                            setIndex(index);
                            let currentdate = new Date();
                            let unixtime = + new Date();
                            let datetime = currentdate.getDate() + "/"
                                + (currentdate.getMonth()+1)  + "/"
                                + currentdate.getFullYear() + " "
                                + currentdate.getHours() + ":"
                                + currentdate.getMinutes() + ":"
                                + currentdate.getSeconds();

                            let PID_new = "P-" + (currentdate.getMonth()+1).toString() + Math.floor(Math.random() * (100 - 10 + 1) + 10).toString() + Index.toString();
                            setPID(PID);
                            data["1-04-registerDT"] = unixtime;
                            data["1-05-updatedDT"] = datetime + " Registered";
                            data["100-stage"] = "1";
                            data["1-03-PID"] = PID_new;
                            data["1-01-POI"] = "Reception";
                            data["patientMedData"] = {};
                            data['1-02-appointments'] = [];
                            if(Captcha) {
                                PatientDataService.create(data)
                                    .then(() => {
                                        console.log("Created new item successfully!");
                                        MailDataService.sendNewRegEmail(Email, PID_new, Name);
                                        handleNext();
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                    });
                            }else {
                                setMessage("Sorry, the captcha wasn't verified. Please try again.");
                            }

                            patindex.update({
                                size: Index+1
                            });
                        })
                        .catch((err) => {
                            // Handle error here (maybe fetch user?)
                        });


                }else{
                    handleNext();
                }

            }
            else{
                setMessage("Please enter correct format of email.")
            }
        }else{
            setMessage("Please fill out all fields.")
        }

    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return regCheck;
            case 1:
                return regForm;
            case 2:
                return checkO;
            default:
                return 'Unknown step';
        }
    };

    const regCheck = (
        <Typography className={classes.instructions}>
            Have you registered with Prana before?
            <br/>
            <Typography variant="caption">(Do you have a P-ID)</Typography>
            <br/><br/>
            <Button
                variant="contained"
                color="primary"
                onClick={handleRegT}
                className={classes.button}
                id={"form-submit"}
            >
                Yes
            </Button>
            <Button
                variant="contained"

                onClick={handleRegF}
                className={classes.button}
                id={"form-no"}
            >
                No
            </Button>
        </Typography>
        );

    const regForm = (
        <form onSubmit={handleForm}>
        <Card>
                <Card.Header>
                    User Registration
                </Card.Header>

                <Card.Body>
                    <table id={"form-table"}>
                        <tbody>
                        {registered ? (
                            <>

                                <tr style={{height:"50px"}}>
                                    <td>
                                        <label htmlFor={"Name"} className="block">
                                            Name:
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            required
                                            name={"Name"}
                                            value={Name || ""}
                                            id={"Name"}
                                            onChange={handleName}
                                        />
                                    </td>
                                </tr>

                                <tr style={{height:"50px"}}>
                                    <td>
                                        <label htmlFor={"PID"} className="block">
                                            Prana ID (PID)
                                        </label>
                                    </td>
                                    <td>
                                    <input
                                        required
                                        name={"PID"}
                                        value={PID || ""}
                                        id={"PID"}
                                        onChange={handlePID}
                                    />
                                    </td>
                                </tr>

                                <tr style={{height:"50px"}}>
                                    <td>
                                        <label htmlFor={"Phone"} className="block">
                                            Phone:
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            required
                                            name={"phone"}
                                            value={Phone || ""}
                                            id={"phone"}
                                            onChange={handlePhone}
                                        />
                                    </td>
                                </tr>

                                <tr style={{height:"50px"}}>
                                    <td>
                                        <label htmlFor={"email"} className="block">
                                            Email:
                                        </label>
                                    </td>
                                    <td>
                                    <input
                                        required
                                        name={"email"}
                                        value={Email || ""}
                                        id={"email"}
                                        onChange={handleEmail}
                                        />
                                    </td>
                                </tr>

                            </>
                        ) : (
                            <>

                                <tr style={{height:"50px"}}>
                                    <td>
                                        <label htmlFor={"Name"} className="block">
                                            Name:
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            required
                                            name={"Name"}
                                            value={Name || ""}
                                            id={"Name"}
                                            onChange={handleName}
                                        />
                                    </td>
                                </tr>

                                <tr style={{height:"50px"}}>
                                    <td>
                                        <label htmlFor={"Age"} className="block">
                                            Age:
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            required
                                            name={"Age"}
                                            value={Age || ""}
                                            id={"Age"}
                                            onChange={handleAge}
                                        />
                                    </td>
                                </tr>

                                <tr style={{height:"50px"}}>
                                    <td>
                                        <label htmlFor={"Gender"} className="block">
                                            Gender:
                                        </label>
                                    </td>
                                    <td>
                                        <select
                                            required
                                            value={Gender || ""}
                                            id={Gender}
                                            onChange={handleGender}
                                        >
                                            <option value={" "}> </option>
                                            <option value={"Male"}>Male</option>
                                            <option value={"Female"}>Female</option>
                                            <option value={"Other"}>Other</option>
                                        </select>
                                    </td>
                                </tr>

                                <tr style={{height:"50px"}}>
                                    <td>
                                        <label htmlFor={"Email"} className="block">
                                            Email:
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            required
                                            name={"Email"}
                                            value={Email || ""}
                                            id={"Email"}
                                            onChange={handleEmail}
                                        />
                                    </td>
                                </tr>

                                <tr style={{height:"50px"}}>
                                    <td>
                                        <label htmlFor={"Phone"} className="block">
                                            Phone:
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            required
                                            name={"phone"}
                                            value={Phone || ""}
                                            id={"phone"}
                                            onChange={handlePhone}
                                        />
                                    </td>
                                </tr>

                                <tr style={{height:"50px"}}>
                                    <td>
                                        <label htmlFor={"Gender"} className="block">
                                            How did you hear about Prana?
                                        </label>
                                    </td>
                                    <td>
                                        <select
                                            required
                                            value={Heard || ""}
                                            id={Heard}
                                            onChange={handleHeard}
                                        >
                                            <option value={" "}> </option>
                                            <option value={"Advertisement"}>Advertisement</option>
                                            <option value={"Corporate"}>Corporate</option>
                                            <option value={"Doctor"}>Doctor</option>
                                            <option value={"Friends / Relatives"}>Friends / Relatives</option>
                                            <option value={"Website"}>Website</option>
                                            <option value={"Other"}>Other</option>
                                        </select>
                                    </td>
                                </tr>

                            </>
                        )}

                        </tbody>
                    </table>
                    {!registered &&
                    <div style={{marginLeft:'40%'}}>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env['REACT_APP_CAPTCHA_KEY']}
                        //sitekey={process.env['REACT_APP_CAPTCHA_DEV_KEY']}
                        onChange={onChangeCaptcha}
                    />
                    </div>
                    }

                    <br/>
                    {Message}
                    <br/>
                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleForm}
                        className={classes.button}
                        id={"form-submit"} type="submit" value="Submit"
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Card.Body>
            </Card>
        </form>
    );



    const checkO = (
        <>
            <div className="product">

                {/*<img*/}
                {/*    src={props.imageURL}*/}
                {/*    alt="The cover of Event"*/}
                {/*    style={{width:'500px'}}*/}
                {/*/>*/}
                <div className="description">
                    <h3>Registration payment for {props.title}</h3>
                    <h5>Rs. {props.cost}</h5>
                </div>
            </div>
            <button id="checkout-button" role="link" onClick={handleClick}>
                Checkout
            </button>
        </>
    );

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = <Typography variant="caption">Optional</Typography>;
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} className={classes.button}>
                            Reset
                        </Button>
                    </div>
                ) : (
                    <div>
                        {getStepContent(activeStep)}
                        <div>
                            {activeStep === 3 &&
                                <>
                                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                    Back
                                </Button>
                                {isStepOptional(activeStep) && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSkip}
                                        className={classes.button}
                                    >
                                        Skip
                                    </Button>
                                )}

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                                </>
                            }

                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}