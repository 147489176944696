import React, { Component } from "react";
import PatientDataService from "../../services/patient.service";
import PatientSchemaKeys from "./patient-schema-keys";
import PatientSchema from "./patients-schema";
import { UserContext } from "../../providers/UserProvider";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SetAppointment from './set-appointment'
import AppointmentDataService from "../../services/appointments.service";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { PDFDocument } from 'pdf-lib';
import { saveAs } from 'file-saver';
import watermarkPrescription from './watermark-prescription.pdf';
import noWatermarkPrescription from './no-watermark-prescription.pdf';

const patientTemplate_strings = PatientSchema;

async function retDate(id) {
    // [START get_document]
    const doc = await AppointmentDataService.getDate(id).get();
    if (!doc.exists) {
        console.log('No such document!');
    } else {
        return doc.data()
    }
    // [END get_document]
}

export default class Patient extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleMedicationChange = this.handleMedicationChange.bind(this);
        this.updatePatient = this.updatePatient.bind(this);
        this.deletePatient = this.deletePatient.bind(this);
        this.renderPatientData = this.renderPatientData.bind(this);
        this.verifyUser = this.verifyUser.bind(this);
        this.checkPStage = this.checkPStage.bind(this);
        this.bubbleSort = this.bubbleSort.bind(this);
        this.returnForm = this.returnForm.bind(this);
        this.returnEditable = this.returnEditable.bind(this);
        this.addMedRow = this.addMedRow.bind(this);
        this.handleChangeInMed = this.handleChangeInMed.bind(this);
        this.prescriptionProcess = this.prescriptionProcess.bind(this);
        this.handleWM = this.handleWM.bind(this);


        this.state = {
            currentPatient: {
                "0-01-name": "",

            },
            currentPatientID: null,
            patientAptMap: [],
            message: "",
            value: 0,
            prscWM: false

        };
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     const { patient } = nextProps;
    //     if (prevState.currentPatientID !== patient[0]) {
    //         return {
    //             currentPatient: patient[1],
    //             currentPatientMeds : patient[1]['3.08-medications'] ? patient[1]['3.08-medications'] : [{"Tab/Cap/Injxn":"","Name":"","Dose":"","Frequency":"","Duration":"","Instructions":""}],
    //             message: "",
    //             currentPatientID: patient[0]
    //         };
    //     }
    //
    //     return null;
    // }

    handleTabChange = (event, newValue) => {
        this.setState({
            value: newValue,
            liveAppt: this.state.patientAptMap[newValue].id
        })
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.patient[0] !== prevProps.patient[0]) {
            this.setState({
                currentPatient: this.props.patient[1],
                currentPatientID: this.props.patient[0],
                patientAptMap: [],
                message: "",
                liveAppt: "",
                prscWM: false
            })
            let apps = this.props.patient[1]['1-02-appointments'];
            if (apps) {
                // eslint-disable-next-line
                apps.map((each) => {
                    retDate(each)
                        .then(liveData => {
                            console.log("LIVE S")
                            let date = liveData['appointmentDate'].toDate();
                            let fullDate = date.getDate().toString() + "/" + (date.getMonth() + 1).toString() + "/" + date.getFullYear().toString();
                            this.setState({
                                patientAptMap: [
                                    ...this.state.patientAptMap,
                                    {
                                        id: each,
                                        date: fullDate,
                                        doctor: liveData['appointmentDoctor'],
                                        time: liveData['appointmentTime'],
                                        cost: liveData['appointmentCost']
                                    }

                                ],
                                liveAppt: this.props.patient[1]['1-02-appointments'][0]
                            });
                        })
                        .catch((err) => {
                            // Handle error here (maybe fetch user?)
                            console.log(err)
                        });
                });
            }
        }



    }

    componentDidMount() {
        let patt = this.props.patient[1]
        patt["0-02-age"] = this.calculateAge(patt["0-03-DOB"])
        this.setState({
            currentPatient: patt,
            currentPatientID: this.props.patient[0],
            user: this.context
        });

        let apps = patt['1-02-appointments'];
        if (apps) {
            // eslint-disable-next-line
            apps.map((each) => {
                retDate(each)
                    .then(liveData => {
                        let date = liveData['appointmentDate'].toDate();
                        let fullDate = date.getDate().toString() + "/" + (date.getMonth() + 1).toString() + "/" + date.getFullYear().toString();
                        this.setState({
                            patientAptMap: [
                                ...this.state.patientAptMap,
                                {
                                    id: each,
                                    date: fullDate,
                                    doctor: liveData['appointmentDoctor'],
                                    time: liveData['appointmentTime'],
                                    cost: liveData['appointmentCost']
                                }

                            ],
                            liveAppt: this.props.patient[1]['1-02-appointments'][0]
                        });
                    })
                    .catch((err) => {
                        // Handle error here (maybe fetch user?)
                        console.log(err)
                    });
            });
        }
    }


    handleChange(event) {
        const val = event.target.value;
        const id = event.target.id;

        this.setState(function (prevState) {
            //console.log(id,val,prevState.currentPatient);
            return {
                currentPatient: {
                    ...prevState.currentPatient,
                    [id]: val,
                },
            };
        });

    }

    handleChangeInMed(e) {
        const val = e.target.value;
        const id = e.target.id;

        this.setState(function (prevState) {
            //console.log(id,val,prevState.currentPatient);
            return {
                currentPatient: {
                    ...this.state.currentPatient,
                    patientMedData: {
                        ...this.state.currentPatient.patientMedData,
                        [this.state.liveAppt]: {
                            ...prevState.currentPatient.patientMedData[this.state.liveAppt],
                            [id]: val
                        }
                    }
                },
            };
        });

        if (id === '2-07-Height' || id === '2-08-Weight') {
            let h = 0, w = 0;
            if (id === '2-07-Height') {
                h = parseFloat(val) / 100;
                w = parseFloat(this.state.currentPatient.patientMedData[this.state.liveAppt]['2-08-Weight']);
            }
            if (id === '2-08-Weight') {
                h = parseFloat(this.state.currentPatient.patientMedData[this.state.liveAppt]['2-07-Height']) / 100;
                w = parseFloat(val);
            }
            let bmi = (w / ((h * h))).toFixed(2);
            this.setState(function (prevState) {
                //console.log(id,val,prevState.currentPatient);
                return {
                    currentPatient: {
                        ...this.state.currentPatient,
                        patientMedData: {
                            ...this.state.currentPatient.patientMedData,
                            [this.state.liveAppt]: {
                                ...prevState.currentPatient.patientMedData[this.state.liveAppt],
                                // eslint-disable-next-line
                                ['2-09-BMI']: bmi
                            }
                        }
                    },
                };
            })
        }

    }

    handleMedicationChange(e) {
        const val = e.target.value;
        const id = e.target.id;
        let meds = [...this.state.currentPatient.patientMedData[this.state.liveAppt]["4-08-medications"]];
        //console.log(val,id.substring(0,1),id.substring(2),meds)
        //console.log("w",e.target.dataset.id)
        let medIndex = parseInt(id.substring(0, 1));
        let medKey = id.substring(2);
        meds[medIndex][medKey] = val;
        let currentPatientMeds = meds;
        // meds[e.target.dataset.id][id] = val;
        this.setState(function (prevState) {
            //console.log(id,val,prevState.currentPatient);
            return {
                currentPatient: {
                    ...this.state.currentPatient,
                    patientMedData: {
                        ...this.state.currentPatient.patientMedData,
                        [this.state.liveAppt]: {
                            ...prevState.currentPatient.patientMedData[this.state.liveAppt],
                            "4-08-medications": currentPatientMeds

                        }
                    }
                },
            };
        });
    }

    prescriptionProcess(){
        let patt = this.state.currentPatient
        patt["0-02-age"] = this.calculateAge(patt["0-03-DOB"])
        createPrescription(patt, this.state.liveAppt, this.state.prscWM, this.state.patientAptMap[this.state.value])
                    .then(msg => {
                        console.log(msg)
                    })

    }

    handleWM(event){
        const target = event.target;
        const value = target.checked;
        
        this.setState({
            prscWM: value
        });
    }

    calculateAge(dob) {
        // Split the input string to get day, month, and year
        let [day, month, year] = dob.split('/').map(Number);
        
        // Create a new date object for the birth date
        let birthDate = new Date(year, month - 1, day);
        
        // Get the current date
        let today = new Date();
        
        // Calculate age
        let age = today.getFullYear() - birthDate.getFullYear();
        let monthDiff = today.getMonth() - birthDate.getMonth();
        let dayDiff = today.getDate() - birthDate.getDate();
        
        // Adjust age if the birth date hasn't occurred yet this year
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
          age--;
        }
        
        return age;
    }

    updatePatient() {
        console.log("updating...");

        const data = {};
        const user = this.state.user;
        let currentdate = new Date();
        let datetime = currentdate.getDate() + "/"
            + (currentdate.getMonth() + 1) + "/"
            + currentdate.getFullYear() + " "
            + currentdate.getHours() + ":"
            + currentdate.getMinutes() + ":"
            + currentdate.getSeconds();
        // eslint-disable-next-line
        Object.keys(this.state.currentPatient).map((key) => {
            data[key] = this.state.currentPatient[key];
        });
        data["0-02-age"] = this.calculateAge(data["0-03-DOB"])
        if (user) {
            data["1-05-updatedDT"] = datetime + " by " + user['displayName'];
        }
        if (user['isDoctor']) {
            data["100-stage"] = "1"
        }
        else {
            data["100-stage"] = (parseInt(data["100-stage"]) + 1).toString();
        }

        PatientDataService.update(this.state.currentPatientID, data)
            .then(() => {
                this.setState({
                    message: "The patient was updated successfully!",
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }



    deletePatient() {
        PatientDataService.delete(this.state.currentPatientID)
            .then(() => {
                this.props.refreshList();
            })
            .catch((e) => {
                console.log(e);
            });
    }

    renderPatientData(folder) {
        let currPatientData = {};
        let totalPatientData = [];
        const { currentPatient } = this.state;
        const patientscheme = PatientSchema;
        const t = this;

        // eslint-disable-next-line
        Object.keys(currentPatient).map(function (key) {
            if (t.verifyUser(patientscheme[PatientSchemaKeys[key]].state, folder)) {
                currPatientData[patientscheme[PatientSchemaKeys[key]].id] = [patientscheme[PatientSchemaKeys[key]].text];
                totalPatientData.push([patientscheme[PatientSchemaKeys[key]], currentPatient[key], t.checkPStage(patientscheme[PatientSchemaKeys[key]]['stage']), patientscheme[PatientSchemaKeys[key]].id])
            }
        });
        // eslint-disable-next-line
        patientscheme.map(function (each) {
            //console.log(currStage)
            if (t.verifyUser(each.state, folder) && t.checkPStage(each.stage) && (!currPatientData.hasOwnProperty(each.id))) {
                totalPatientData.push([each, "", true, each.id])
            }
        });
        return this.bubbleSort(totalPatientData);
    }


    bubbleSort(arr) {
        const len = arr.length;
        for (let i = len - 1; i >= 0; i--) {
            for (let j = 1; j <= i; j++) {
                //let idNum = parseFloat(key.slice(0,4));
                let a = parseFloat(arr[j - 1][3].slice(0, 4).replace("-", "."));
                let b = parseFloat(arr[j][3].slice(0, 4).replace("-", "."));
                if (a > b) {
                    let temp = arr[j - 1];
                    arr[j - 1] = arr[j];
                    arr[j] = temp;
                }
            }
        }
        return arr;
    }

    checkPStage(curr) {
        const user = this.state.user;
        let userStage = "0";
        if (user) {
            const isAdmin = user['isAdmin'];
            const isDoctor = user['isDoctor'];
            const isReceptionist = user['isReceptionist'];
            const isNurse = user['isNurse'];

            if (isDoctor) {
                userStage = "3";
            }
            else if (isNurse) {
                userStage = "2";
            }
            else if (isReceptionist) {
                userStage = "1";
            }
            if (userStage === curr.toString() || isAdmin) {
                return true
            }
        }
        return false
    }

    verifyUser(access, specificFolder) {
        const user = this.state.user;
        if (user) {
            const isAdmin = user['isAdmin'];
            const isDoctor = user['isDoctor'];
            const isReceptionist = user['isReceptionist'];
            const isNurse = user['isNurse'];

            if (access === specificFolder) {
                if (access === "private" && isAdmin) {
                    return true
                } else if (access === "protected" && isDoctor) {
                    return true
                } else if (access === "reception" && isReceptionist) {
                    return true
                } else if (access === "vitals" && (isDoctor || isNurse)) {
                    return true
                } else if (access === "general" && (isDoctor || isNurse || isReceptionist)) {
                    return true
                }
            }
        }
        //console.log("USER AGAIN ",user['designation'])

        return false
    }

    returnEditable(item, index) {
        if (item[0]['type'] === 'text' || item[0]['type'] === 'email') {
            return (
                <textarea
                    key={index + 1750}
                    type="text"
                    required={item[0]['required']}
                    className={"patientInput"}
                    name={item[3]}
                    value={item[1] || ""}
                    id={item[3]}
                    onChange={this.handleChange}
                />
            )
        }
        else if (item[0]['type'] === 'select') {
            let optionItems = item[0].options.map((o, index2) => <option key={index + (3000 + (1000 * index2))} value={o}>{o}</option>);
            return (
                <select
                    key={index + 2750}
                    required={item[0]['required']}
                    value={item[1] || ""}
                    id={item[3]}
                    onChange={this.handleChange}
                >
                    {optionItems}
                </select>
            )
        }

    }

    returnForm(category) {
        const t = this;
        return (
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        {category[0]}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <table id={"form-table"}>
                            <tbody>
                                {
                                    this.renderPatientData(category[1]).map(function (each, index) {
                                        return (
                                            // <p key={index}>{genList[key][0]} : {genList[key][1]}</p>
                                            <tr key={index} style={{ height: "50px" }}>
                                                <td key={index + 1000}>
                                                    <label key={index + 1250} htmlFor={each[3]} className="block">
                                                        {each[0]['text']}
                                                    </label>
                                                </td>
                                                <td key={index + 1500}>
                                                    {each[2] ?

                                                        t.returnEditable(each, index)
                                                        :
                                                        <p id={each[3]}>
                                                            {each[1] || ""}
                                                        </p>
                                                    }
                                                </td>
                                            </tr>

                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }

    addMedRow(e) {
        e.preventDefault();
        let pat = this.state.currentPatient;
        let prevMedData = pat.patientMedData[this.state.liveAppt];
        prevMedData['4-08-medications'].push({ "Tab/Cap/Injxn": "", "Name": "", "Dose": "", "Frequency": "", "Instructions": "", "Duration": "", });
        pat.patientMedData[this.state.liveAppt] = prevMedData;
        this.setState({
            currentPatient: pat,
        });
    }


    retPatientData(ap, isDoctor) {
        let aptData = this.state.currentPatient['patientMedData'][ap.id];
        let t = this;

        let sortedVitalData = [];
        // eslint-disable-next-line
        Object.keys(aptData).map(function (key) {
            if (key[0] === "2") {
                let a = {
                    [key]: aptData[key]
                };
                sortedVitalData.push([a, "", "", key]);
            }
        });
        sortedVitalData = t.bubbleSort(sortedVitalData);


        let sortedMedData = [];
        // eslint-disable-next-line
        Object.keys(aptData).map(function (key) {
            if (key[0] === "3") {
                let b = {
                    [key]: aptData[key]
                };
                sortedMedData.push([b, "", "", key]);
            }
        });
        sortedMedData = t.bubbleSort(sortedMedData);
        console.log("this", sortedMedData)
        return (
            <Accordion>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            Patient Vitals
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <table id={"form-table"}>
                                <tbody>
                                    {
                                        sortedVitalData.map(function (each, index) {
                                            let key = each[3];
                                            let e = patientTemplate_strings[PatientSchemaKeys[key]];
                                            return (

                                                <tr key={index} style={{ height: "50px" }}>
                                                    <td key={index + 1000}>
                                                        <label key={index + 1250} htmlFor={key} className="block">
                                                            {e['text']}
                                                        </label>
                                                    </td>
                                                    <td key={index + 1500}>
                                                        <textarea
                                                            key={index + 1750}
                                                            className={"patientInput"}
                                                            name={key}
                                                            value={t.state.currentPatient.patientMedData[ap.id][key] || ""}
                                                            id={key}
                                                            onChange={t.handleChangeInMed}
                                                        />
                                                    </td>
                                                </tr>

                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                {isDoctor &&
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                Medical File
                        </Accordion.Toggle>
                        </Card.Header>


                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <table id={"form-table"}>
                                    <tbody>
                                        {
                                            sortedMedData.map(function (each, index) {
                                                let key = each[3];
                                                let e = patientTemplate_strings[PatientSchemaKeys[key]];
                                                let charlimit ={
                                                    "3-01-provDiag":665,
                                                    "3-02-presentSymp": 255,
                                                    "3-04-currentMeds":192,
                                                    "3-03-history":192,
                                                    "3-05-GPE":350,
                                                    "3-06-sysExamination":532,
                                                    "3-07-investigations":700,
                                                    "3-09-advice":700
                                                }
                                                let climit = charlimit.hasOwnProperty(each[3]) ? charlimit[each[3]] : 200
                                                return (
                                                    // <p key={index}>{genList[key][0]} : {genList[key][1]}</p>
                                                    <tr key={index} style={{ height: "50px" }}>
                                                        <td key={index + 3000}>
                                                            <label key={index + 3250} htmlFor={key} className="block">
                                                                {e['text']}
                                                            </label>
                                                        </td>
                                                        <td key={index + 3500}>
                                                            <textarea
                                                                cols="70"
                                                                rows="5"
                                                                maxlength={climit}
                                                                key={index + 3750}

                                                                name={key}
                                                                value={t.state.currentPatient.patientMedData[ap.id][key] || ""}
                                                                id={key}
                                                                onChange={t.handleChangeInMed}
                                                            />
                                                        </td>
                                                    </tr>

                                                )
                                            })
                                        }
                                        <tr style={{ height: "50px" }}>
                                            <td>
                                                <label htmlFor="0-16-allergies" className="block">
                                                    Allergies
                                        </label>
                                            </td>
                                            <td>
                                                <textarea
                                                    cols="70"
                                                    rows="5"
                                                    maxlength={255}
                                                    name="0-16-allergies"
                                                    value={t.state.currentPatient.patientMedData[ap.id]['0-16-allergies'] || ""}
                                                    id="0-16-allergies"
                                                    onChange={t.handleChangeInMed}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <br />
                                <label className="block">
                                    Medications
                            </label>
                                <table style={{ fontSize: '13px', width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Tab/Cap/ Injxn</th>
                                            <th>Name</th>
                                            <th>Dose</th>
                                            <th>Frequency</th>
                                            <th>Instructions</th>
                                            <th>Duration</th>
                                        </tr>
                                        {t.state.currentPatient.patientMedData[ap.id]['4-08-medications'].map((each, idx) => {
                                            return (
                                                <tr key={idx + 12750}>
                                                    <td key={idx + 13750}>
                                                        {idx + 1}
                                                    </td>

                                                    <td key={idx + 13750}>
                                                        <input
                                                            key={idx + 14750}
                                                            size={"5"}
                                                            type="text"
                                                            name={"Tab/Cap/Injxn"}
                                                            value={each["Tab/Cap/Injxn"] || ""}
                                                            id={idx + "-Tab/Cap/Injxn"}
                                                            onChange={t.handleMedicationChange}
                                                        />
                                                    </td>

                                                    <td key={idx + 13750}>
                                                        <input
                                                            key={idx + 14750}
                                                            type="text"
                                                            size={"15"}
                                                            name={"Name"}
                                                            value={each["Name"] || ""}
                                                            id={idx + "-Name"}
                                                            onChange={t.handleMedicationChange}
                                                        />
                                                    </td>

                                                    <td key={idx + 13750}>
                                                        <input
                                                            key={idx + 14750}
                                                            type="text"
                                                            name={"Dose"}
                                                            value={each["Dose"] || ""}
                                                            id={idx + "-Dose"}
                                                            onChange={t.handleMedicationChange}
                                                        />
                                                    </td>

                                                    <td key={idx + 13750}>
                                                        <input
                                                            key={idx + 14750}
                                                            type="text"
                                                            name={"Frequency"}
                                                            value={each["Frequency"] || ""}
                                                            id={idx + "-Frequency"}
                                                            onChange={t.handleMedicationChange}
                                                        />
                                                    </td>

                                                    <td key={idx + 13750}>
                                                        <input
                                                            key={idx + 14750}
                                                            type="text"
                                                            name={"Instructions"}
                                                            value={each["Instructions"] || ""}
                                                            id={idx + "-Instructions"}
                                                            onChange={t.handleMedicationChange}
                                                        />
                                                    </td>

                                                    <td key={idx + 13750}>
                                                        <input
                                                            key={idx + 14750}
                                                            type="text"
                                                            size={"15"}
                                                            name={"Duration"}
                                                            value={each["Duration"] || ""}
                                                            id={idx + "-Duration"}
                                                            onChange={t.handleMedicationChange}
                                                        />
                                                    </td>
                                                    {/*{*/}
                                                    {/*    Object.keys(each).map((key, index) =>{*/}
                                                    {/*        console.log("showing ",key,each[key])*/}
                                                    {/*        return(*/}
                                                    {/*            <td key={index+11750}>*/}
                                                    {/*                <input*/}
                                                    {/*                    key={index+1750}*/}
                                                    {/*                    type="text"*/}
                                                    {/*                    name={key}*/}
                                                    {/*                    value={each[key] || ""}*/}
                                                    {/*                    id={idx+'-'+key}*/}
                                                    {/*                    onChange={t.handleMedicationChange}*/}
                                                    {/*                />*/}
                                                    {/*            </td>*/}

                                                    {/*        )*/}
                                                    {/*    })*/}
                                                    {/*}*/}
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                                <button id={"submit-button"} style={{ float: 'left', marginLeft: '1%', marginRight: '1%' }} onClick={t.addMedRow}>Add Medication</button>
                                <br /><br /><br />

                                <table id={"form-table"}>
                                    <tbody>
                                        <tr style={{ height: "50px" }}>
                                            <td>
                                                <label htmlFor="4-10-followUp" className="block">
                                                    Next Follow Up
                                        </label>
                                            </td>
                                            <td>
                                                <textarea
                                                    cols="70"
                                                    rows="5"

                                                    name="4-10-followUp"
                                                    value={t.state.currentPatient.patientMedData[ap.id]['4-10-followUp'] || ""}
                                                    id="4-10-followUp"
                                                    onChange={t.handleChangeInMed}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                }
            </Accordion>
        )
    }
    render() {
        const { currentPatient, currentPatientID, patientAptMap } = this.state;
        let isAdmin = false;
        let isReceptionist = false;
        let isNurse = false;
        let isDoctor = false;


        if (this.state.user) {
            isAdmin = this.state.user['isAdmin'];
            isDoctor = this.state.user['isDoctor'];
            isReceptionist = this.state.user['isReceptionist'];
            isNurse = this.state.user['isNurse'];
        }

        return (
            <div>
                <h4>Patient</h4>
                {currentPatient ? (
                    <div className="edit-form">
                        <form>
                            <Accordion defaultActiveKey="0">
                                {this.returnForm(["General Information", "general"])}
                            </Accordion>

                            {(isReceptionist) &&
                                <Accordion>
                                    <SetAppointment
                                        patient={currentPatient} patientID={currentPatientID} patientAppointments={patientAptMap} live={patientAptMap[0]}
                                    />
                                </Accordion>

                            }

                            {(isDoctor && "3-11-COVIDSCREEN" in currentPatient) &&
                                <Accordion>
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                Covid Screening Form
                        </Accordion.Toggle>
                                        </Card.Header>


                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>

                                                <table id={"form-table"}>
                                                    <tbody>

                                                        <>
                                                            <tr>
                                                                <td>
                                                                    <label htmlFor={"symptoms"} className="block">
                                                                        Currently, are you experiencing any of the symptoms below?
                        </label>
                                                                </td>

                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Fever"
                                                                        checked={currentPatient["3-11-COVIDSCREEN"].symptoms["Fever"] || false}
                                                                    />
                        Fever
                        <br />
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Difficulty breathing"
                                                                        checked={currentPatient["3-11-COVIDSCREEN"].symptoms["Difficulty breathing"] || false}
                                                                    />
                        Difficulty breathing
                        <br />
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Cough"
                                                                        checked={currentPatient["3-11-COVIDSCREEN"].symptoms["Cough"] || false}
                                                                    />
                        Cough
                        <br />
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Running nose"
                                                                        checked={currentPatient["3-11-COVIDSCREEN"].symptoms["Running nose"] || false}
                                                                    />
                        Running nose
                        <br />
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Sore throat"
                                                                        checked={currentPatient["3-11-COVIDSCREEN"].symptoms["Sore throat"] || false}
                                                                    />
                        Sore throat
                        <br />
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Body aches"
                                                                        checked={currentPatient["3-11-COVIDSCREEN"].symptoms["Body aches"] || false}
                                                                    />
                        Body aches
                        <br />
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Severe weakness"
                                                                        checked={currentPatient["3-11-COVIDSCREEN"].symptoms["Severe weakness"] || false}
                                                                    />
                        Severe weakness
                        <br />
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Persistent pain and pressure in chest"
                                                                        checked={currentPatient["3-11-COVIDSCREEN"].symptoms["Persistent pain and pressure in chest"] || false}

                                                                    />
                        Persistent pain and pressure in chest
                        <br />
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <label htmlFor={"q2"} className="block">
                                                                        Did you suffer from Covid-19 in the past?
                        </label>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="radio"
                                                                        value="Yes"
                                                                        id="q2_y"
                                                                        checked={currentPatient["3-11-COVIDSCREEN"].q2_}

                                                                    />
                        Yes

                        <input
                                                                        type="radio"
                                                                        value="No"
                                                                        id="q2_n"
                                                                        checked={!currentPatient["3-11-COVIDSCREEN"].q2_}

                                                                    />
                        No
                    </td>
                                                            </tr>

                                                            {
                                                                currentPatient["3-11-COVIDSCREEN"].q2_ &&

                                                                <tr>
                                                                    <td>
                                                                        <label htmlFor={"q2b"} className="block">
                                                                            Please specify when:
                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type={"text"}
                                                                            required={currentPatient["3-11-COVIDSCREEN"].q2_}
                                                                            value={currentPatient["3-11-COVIDSCREEN"].q2b || ""}
                                                                            id={"q2b"}

                                                                        />
                                                                    </td>
                                                                </tr>
                                                            }

                                                            <tr>
                                                                <td>
                                                                    <label htmlFor={"q3a"} className="block">
                                                                        Did you travel with in the last 1 month?
                        </label>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="radio"
                                                                        value="Yes"
                                                                        id="q3ay"
                                                                        checked={currentPatient["3-11-COVIDSCREEN"].q3a}

                                                                    />
                        Yes

                        <input
                                                                        type="radio"
                                                                        value="No"
                                                                        id="q3an"
                                                                        checked={!currentPatient["3-11-COVIDSCREEN"].q3a}

                                                                    />
                        No
                    </td>
                                                            </tr>

                                                            {
                                                                currentPatient["3-11-COVIDSCREEN"].q3a &&

                                                                <tr>
                                                                    <td>
                                                                        <label htmlFor={"q3ab"} className="block">
                                                                            Please specify when and where:
                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type={"text"}
                                                                            required={currentPatient["3-11-COVIDSCREEN"].q3a}
                                                                            value={currentPatient["3-11-COVIDSCREEN"].q3ab || ""}
                                                                            id={"q3ab"}

                                                                        />
                                                                    </td>
                                                                </tr>
                                                            }

                                                            <tr>
                                                                <td>
                                                                    <label htmlFor={"q3b"} className="block">
                                                                        Did you have close contact with any confirmed case of Covid-19 in last 2 weeks
                        </label>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="radio"
                                                                        value="Yes"
                                                                        id="q3by"
                                                                        checked={currentPatient["3-11-COVIDSCREEN"].q3b}

                                                                    />
                        Yes

                        <input
                                                                        type="radio"
                                                                        value="No"
                                                                        id="q3bn"
                                                                        checked={!currentPatient["3-11-COVIDSCREEN"].q3b}

                                                                    />
                        No
                    </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <label htmlFor={"q4"} className="block">
                                                                        Did you get a Covid test recently?
                        </label>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="radio"
                                                                        value="Yes"
                                                                        id="q4_y"
                                                                        checked={currentPatient["3-11-COVIDSCREEN"].q4_}

                                                                    />
                        Yes

                        <input
                                                                        type="radio"
                                                                        value="No"
                                                                        id="q4_n"
                                                                        checked={!currentPatient["3-11-COVIDSCREEN"].q4_}

                                                                    />
                        No
                    </td>
                                                            </tr>

                                                            {
                                                                currentPatient["3-11-COVIDSCREEN"].q4_ &&

                                                                <tr>
                                                                    <td>
                                                                        <label htmlFor={"q4a"} className="block">
                                                                            Please specify what type (RT-PCR or Covid Antigen) and when:
                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type={"text"}
                                                                            required={currentPatient["3-11-COVIDSCREEN"].q4_}
                                                                            value={currentPatient["3-11-COVIDSCREEN"].q4a || ""}
                                                                            id={"q4a"}

                                                                        />
                                                                    </td>
                                                                </tr>
                                                            }

                                                            <tr>
                                                                <td>
                                                                    <label htmlFor={"q5"} className="block">
                                                                        Have you received the Covid vaccine?
                        </label>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="radio"
                                                                        value="Yes"
                                                                        id="q5_y"
                                                                        checked={currentPatient["3-11-COVIDSCREEN"].q5_}

                                                                    />
                        Yes

                        <input
                                                                        type="radio"
                                                                        value="No"
                                                                        id="q5_n"
                                                                        checked={!currentPatient["3-11-COVIDSCREEN"].q5_}

                                                                    />
                        No
                    </td>
                                                            </tr>

                                                            {
                                                                currentPatient["3-11-COVIDSCREEN"].q5_ &&

                                                                <tr>
                                                                    <td>
                                                                        <label htmlFor={"q5a"} className="block">
                                                                            Please specify when:
                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type={"text"}
                                                                            required={currentPatient["3-11-COVIDSCREEN"].q5_}
                                                                            value={currentPatient["3-11-COVIDSCREEN"].q5a || ""}
                                                                            id={"q5a"}

                                                                        />
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </>
                                                    </tbody>
                                                </table>

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            }

                            {(isNurse || isDoctor) &&
                                <>
                                    <AppBar position="static" color="default">
                                        <Tabs
                                            value={this.state.value}
                                            onChange={this.handleTabChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                        >

                                            {
                                                this.state.patientAptMap.map((appt, index) =>
                                                    <Tab key={index} label={appt.date} {...a11yProps({ index })} />
                                                )}

                                        </Tabs>
                                    </AppBar>
                                    {
                                        this.state.patientAptMap.map((appt, index) =>
                                            <TabPanel value={this.state.value} index={index}>
                                                {
                                                    this.retPatientData(appt, isDoctor)

                                                }
                                            </TabPanel>
                                        )}


                                </>
                            }





                            {(isAdmin) &&
                                <Accordion>
                                    {this.returnForm(["Private Information (Admin Only)", "private"])}
                                </Accordion>
                            }


                        </form>

                        {isAdmin &&
                            <button
                                id={"submit-button"}
                                onClick={this.deletePatient}
                            >
                                Delete
                        </button>
                        }


                        <button
                            type="submit"
                            id={"submit-button"}
                            onClick={this.updatePatient}
                        >
                            Update
                        </button>

                        {isDoctor &&
                        <>
                            
                                <button
                                    onClick={this.prescriptionProcess}
                                    id={"submit-button"}
                                >
                                    Create Prescription
                            </button>
                            Watermark  : 
                            <input
                              name="watermark"
                              type="checkbox"
                              checked={this.state.prscWM}
                              onChange={this.handleWM} />
                              </>
                           
                        }

                        <p>{this.state.message}</p>
                    </div>
                ) : (
                    <div>
                        <br />
                        <p>Please click on a Patient...</p>
                    </div>
                )}
            </div>
        );
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

async function createPrescription(patient, appointment, watermark, apptMap){
    const url = watermark ? watermarkPrescription : noWatermarkPrescription;
    
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const form = pdfDoc.getForm()

    //general data
    const name = form.getField('name');
    const age = form.getField('age')
    const phoneNumber = form.getField('phoneNumber');
    const email = form.getField('email');
    const sex = form.getField('sex');
    const pid = form.getField('pid');
    const date = form.getField('date');
    

    //medical data
    const provisionalDiagnosis = form.getField('provisionalDiagnosis');
    const presentingSymptoms = form.getField('presentingSymptoms');
    const allergies = form.getField('allergies');
    const pastHistory = form.getField('pastHistory');
    const currentMedications = form.getField('currentMedications');
    const vitals = form.getField('vitals');
    const gpe = form.getField('gpe');
    const systemicInvestigation = form.getField('systemicInvestigation');
    const investigations = form.getField('investigations');
    const medications = form.getField('medications');
    const advice = form.getField('advice');
    const nextFollow = form.getField('nextFollow');
    const doctorSign = form.getField('doctorSign');
    

    name.setText(patient['0-01-name']);
    age.setText(patient['0-02-age'].toString());
    phoneNumber.setText(patient['0-12-phone'].toString());
    email.setText(patient['0-11-email']);
    sex.setText(patient['0-04-gender']);
    pid.setText(patient['1-03-PID'].toString());
    //appointment date get
    
    date.setText(apptMap['date'].toString());
    

    let medData = patient['patientMedData'][appointment]

    medData['3-01-provDiag'].length>1  && provisionalDiagnosis.setText(medData['3-01-provDiag']);
    medData['3-02-presentSymp'].length>1 && presentingSymptoms.setText(medData['3-02-presentSymp']);
    '0-16-allergies' in medData && allergies.setText(medData['0-16-allergies']);
    medData['3-03-history'].length>1 && pastHistory.setText(medData['3-03-history']);
    medData['3-04-currentMeds'].length>1  && currentMedications.setText(medData['3-04-currentMeds']);

    vitals.setText([
        `Pulse: ${medData['2-01-pulse']} /min, Blood Pressure: ${medData['2-02-bp']} mmHg, Respiratory Rate: ${medData['2-03-rr']} /min, Temperature: ${medData['2-04-temperature']} F,`,
        `Height: ${medData['2-07-Height']} cm, Weight: ${medData['2-08-Weight']} kg, BMI: ${medData['2-09-BMI']} kg/m^2`,
        `O2 satn: ${medData['2-05-o2sat']}%, Blood Sugar: ${medData['2-06-Blood Sugar']} mg/dl`
    ].join('\n'))

    medData['3-05-GPE'].length>1  && gpe.setText(medData['3-05-GPE']);
    medData['3-06-sysExamination'].length>1  && systemicInvestigation.setText(medData['3-06-sysExamination']);
    medData['3-07-investigations'].length>1  && investigations.setText(medData['3-07-investigations']);

    //get medication format
    if (medData['4-08-medications'][0]['Tab/Cap/Injxn'].length > 1){
        let medicationData = medData['4-08-medications'].map((each, idx)=>{
            return(
            [
                `${idx+1}`,
                `${each['Tab/Cap/Injxn']}`,
                `${each['Name']}`,
                `${each['Dose']}`,
                `${each['Frequency']}`,
                `${each['Instructions']}`,
                `for ${each['Duration']}`
                
            ].join(' ')
            )
        })
    
        medications.setText(
            medicationData
        .join('\n'))
    }
    

   
    medData['3-09-advice'].length>1  && advice.setText(medData['3-09-advice']);
    medData['4-10-followUp'].length>1  && nextFollow.setText(medData['4-10-followUp']);

    apptMap['doctor'].length>1  && doctorSign.setText(apptMap['doctor']);
    
    form.flatten();
    const pdfBytes = await pdfDoc.save()
    const file = new Blob([pdfBytes]);
    await new Response(file).arrayBuffer();
    saveAs(file, 'newpdf.pdf');
    return "PDF Filled."
}