import React, { Component } from "react";
import PatientDataService from "../../services/patient.service";
import {UserContext} from "../../providers/UserProvider";
import BillForm from "./billform.component";
import InternalHeader from "../internalHeader";
import Footer from "../footer";

export default class CreateBill extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.refreshList = this.refreshList.bind(this);
        this.setActivePatient = this.setActivePatient.bind(this);
        this.onDataChange = this.onDataChange.bind(this);
        this.returnStage = this.returnStage.bind(this);

        this.state = {
            patients: {},
            currentPatient: null,
            currentIndex: -1,
            inputValue: ""
        };

        this.unsubscribe = undefined;
    }

    componentDidMount = async () => {
        const currUser = this.context;
        this.unsubscribe = PatientDataService.getAll().onSnapshot(this.onDataChange);

        this.setState({
            user: currUser,
            searchQuery: '0-01-name'
        });
    };

    componentWillUnmount() {
        this.unsubscribe();
    }

    onDataChange(items) {
        let patients = {};

        items.forEach((item) => {
            patients[item.id] = item.data()
        });

        this.setState({
            patients: patients,
        });
    }

    refreshList() {
        this.setState({
            currentPatient: null,
            currentIndex: -1,
        });
    }

    setActivePatient(patient, id,index) {
        this.setState({
            currentPatient: [id, patient],
            currentIndex: index,
        });
    }

    returnStage(user){
        if(user) {
            const isAdmin = user['isAdmin'];
            const isDoctor = user['isDoctor'];
            const isReceptionist = user['isReceptionist'];
            const isNurse = user['isNurse'];
            if (isAdmin) {
                return "4"
            }
            if (isDoctor) {
                return "3";
            } else if (isNurse) {
                return "2";
            } else if (isReceptionist) {
                return "1";
            }
            return "0"
        }
    }

    changeSearch = (event) =>{
        this.setState({
            inputValue: event.target.value
        })
    };

    changeSearchQuery= (event) =>{
        const val = event.target.value;
        //const id = event.target.id;

        this.setState({
            searchQuery: val
        })
    };

    filterPatients = (patients) =>{
        let newpatients = {};
        // eslint-disable-next-line
        Object.keys(patients).map((key, index) => {
            if(this.state.searchQuery === "0-01-name"){
                if (patients[key][this.state.searchQuery].toLowerCase().includes(this.state.inputValue.toLowerCase())) {
                    newpatients[key] = patients[key]
                }
            }
            else{
                if(this.state.searchQuery in patients[key]){
                    if (patients[key][this.state.searchQuery].includes(this.state.inputValue)) {
                        newpatients[key] = patients[key]
                    }
                }

            }
        });
        return newpatients
    };

    render() {
        const { patients, currentPatient, currentIndex } = this.state;
        return (
            <div>
                {this.context &&
                <InternalHeader user={this.context}/>
                }
                <div style={{textAlign: "center"}}>
                <div className="list row">
                    <div className="col-md-3">
                        <h4>Patients List</h4>
                        <input style={{width:"79%"}}
                               placeholder="Search for Patient"
                               value={this.state.inputValue}
                               onChange={this.changeSearch}
                        />
                        <select
                            value={this.state.searchQuery}
                            id={"searchQuery"}
                            onChange={this.changeSearchQuery}>
                            <option value={"0-01-name"}>Name</option>
                            <option value={"1-03-PID"}>PID</option>
                        </select>
                        <select size={"15"} style={{width:'94%',borderRadius:'0.25rem',textAlign:'center'}}>
                            {patients &&
                            Object.keys(this.filterPatients(patients)).map((p, index) => (
                                <option
                                    className={
                                        "list-group-item " +
                                        (index === currentIndex ? "active" : "")
                                    }
                                    onClick={() => this.setActivePatient(patients[p], p, index)}
                                    key={index}
                                >
                                    {patients[p]["0-01-name"]}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-9">
                        {currentPatient ? (
                            <BillForm
                                patient={currentPatient}
                                refreshList={this.refreshList}
                            />
                        ) : (
                            <BillForm
                                patient={["",""]}
                                refreshList={this.refreshList}
                            />
                        )}
                    </div>
                </div>
                </div>
                <Footer/>
            </div>
            );
    }

}