import React from "react";
import Header from "../header";
import Footer from "../footer";
import AddPatient from "../patientsdb/add-patient.component"

function Register() {

    return (
        <div>
            <Header/>
            <div>
                <div style={{padding: "5%", textAlign: "center"}}>
                    <AddPatient/>
                </div>
            </div>

            <Footer/>
        </div>
    )


}

export default Register
