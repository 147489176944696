import React, {Component} from "react";
import EventDataService from "../../services/event.service";
import Event from "./event.component";
import {UserContext} from "../../providers/UserProvider";
import InternalHeader from "../internalHeader";
import Footer from "../footer";
import CreateEvent from "./createEvent";

export default class EventsList extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.refreshList = this.refreshList.bind(this);
        this.setActiveEvent = this.setActiveEvent.bind(this);
        this.onDataChange = this.onDataChange.bind(this);

        this.state = {
            events: {},
            create:false,
            currentEvent: null,
            currentIndex: -1
        };

        this.unsubscribe = undefined;
    }

    componentDidMount = async () => {
        const currUser = this.context;
        this.unsubscribe = EventDataService.getAll().onSnapshot(this.onDataChange);

        this.setState({
            user: currUser,
        });
    };

    componentWillUnmount() {
        this.unsubscribe();
    }

    onDataChange(items) {
        let events = {};

        items.forEach((item) => {
            events[item.id] = item.data()
        });

        this.setState({
            events: events,
        });
    }

    refreshList() {
        this.setState({
            currentEvent: null,
            currentIndex: -1,
        });
    }

    setActiveEvent(event, id,index) {
        this.setState({
            currentEvent: [id, event],
            currentIndex: index,
            create:false
        });
    }

    createEvent = () => {
        let a = this.state.create;
        this.setState({
            create: !a
        })
    };

    render() {
        const { events, currentEvent, currentIndex } = this.state;
        if(this.context){
            return (
                <div>
                    <InternalHeader user={this.context}/>
                    <div style={{textAlign: "center"}}>
                        <div className="list row">
                            <div className="col-md-3">
                                <h4>Events List</h4>
                                <button
                                    id={"submit-button"}
                                    style={{width:'80%'}}
                                    onClick={this.createEvent}
                                >
                                    Create Event
                                </button>
                                <select size={"15"} style={{width:'94%',borderRadius:'0.25rem',textAlign:'center'}}>
                                    {events &&
                                    Object.keys(events).map((p, index) => (
                                        <option
                                            className={
                                                "list-group-item " +
                                                (index === currentIndex ? "active" : "")
                                            }
                                            onClick={() => this.setActiveEvent(events[p], p, index)}
                                            key={index}
                                        >
                                            {events[p]["Title"]}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-9">
                                {(currentEvent && !this.state.create) ? (
                                    <Event
                                        event={currentEvent}
                                        refreshList={this.refreshList}
                                    />
                                ) : (
                                    <CreateEvent/>
                                )}
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            );
        }
        else{
            return (<>.</>)
        }


    }

}