import React from 'react'
import Header from "../../header";
import Footer from "../../footer";

export default function ServiceTemplate(serviceContent){
    const content = serviceContent["serviceContent"];

    return (
        <div>
            <Header/>
            <h2 className="heroTitle" style={{padding: "5%",fontSize:'45px'}}>{content.title}</h2>
            <div className={"serviceRow"}>
                <div className={"col-md-6 col-sm-6"} style={{"margin":"auto"}}>
                    {/*<img src={content.imgurl}/>*/}
                </div>
            </div>
            <div className={"serviceRowB"}>
                <div className={"col-md-6"} style={{"margin":"auto"}}>

                        {content.para}

                </div>
            </div>
            <br/><br/>
            <Footer/>
        </div>
    )

}

