import React from 'react'
import Header from "../../header";
import Footer from "../../footer";

class PersonalisedHCP extends React.Component {
    render() {
        return (
            <div>
                <Header/>
                <h2 className="heroTitle" style={{padding: "5%"}}>Personalized Healthcare Programs</h2>
                <div className={"serviceRow"}>
                    <div className={"col-md-6 col-sm-6"} style={{"margin":"auto"}}>
                        <h5 className="subs sub2" style={{"textAlign":"justify"}}>
                            Prana’s integrative therapy focuses on the whole person and the unique characteristics of
                            the individual person to create customized healing for their physical, mental, emotional,
                            social, and spiritual wellbeing.
                            <br /><br />
                            We offer customized programs truly designed around our patients’ needs:
                        </h5>
                    </div>
                </div>
                <div className={"serviceRowB"}>
                    <div className="col-md-4" style={{paddingTop: "1%",margin: "auto"}}>
                        <ul className="list-group">
                            <li className="list-group-item faders a">
                                1.	Prana’s Integrative Lifestyle and Longevity Program
                            </li>
                            <li className="list-group-item faders b">
                                2.	Prana’s Integrative Diabetes Program
                            </li>
                            <li className="list-group-item faders c">
                                3.	Emotional wellbeing & Resiliency Program
                            </li>
                            <li className="list-group-item faders d">
                                4.	Weight reduction program
                            </li>
                            <li className="list-group-item faders e">
                                5.	Healthy Gut Program
                            </li>
                            <li className="list-group-item faders d">
                                6.	Prana Stress Reduction Program
                            </li>
                            <li className="list-group-item faders e">
                                7.	Prana Health & Fitness Program
                            </li>

                        </ul>
                    </div>
                </div>
                <br/><br/>


                <Footer/>
            </div>
        )
    }
}
export default PersonalisedHCP
