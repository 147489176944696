//ARRAY CONTAINING ALL FORM FIELDS FOR PATIENT DATABASE
//FORMAT -
//id: Variable Name to be used
//Text: Text to show on the registration form page
//Type: Text / Select / Email
// -> if Type: Select -> add Options
//required: Required to submit form or not
//stage:
//      0. Registration form
//      1: Reception (registered and now ready to appointed to nurse and doctor)
//      2. Nurse (Vitals test by Nurse)
//      3. Doctor
//      4. Case Summary to send to patient
//private - protected (d) - vitals (n/d) - general (r/d) -
function PatientSchemaArray(){
    return [
        //BEFORE YOU CHANGE IT, PLEASE CHANGE INDEX OF set-appointment.js line 67
        {"id":"0-01-name","text":"Name","type":"text","required":true,"stage":0,"state":"general"},
        {"id":"0-02-age","text":"Age","type":"text","required":true,"stage":0,"state":"general"},
        {"id":"0-03-DOB","text":"Date of Birth","type":"date","required":true,"stage":0,"state":"private"},
        {"id":"0-04-gender","text":"Gender","type":"select","options":[" ","Male","Female","Non-Binary"],"required":true,"stage":0,"state":"general"},
        {"id":"0-05-maritalStatus","text":"Marital Status","type":"select","options":[" ","Single","Married","Other"],"required":false,"stage":0,"state":"protected"},
        {"id":"0-06-address","text":"Address","type":"text","required":true,"stage":0,"state":"private"},
        {"id":"0-07-city","text":"City","type":"text","required":true,"stage":0,"state":"private"},
        {"id":"0-08-state","text":"State","type":"text","required":true,"stage":0,"state":"private"},
        {"id":"0-09-country","text":"Country","type":"text","required":true,"stage":0,"state":"private"},
        {"id":"0-10-pinCode","text":"Pin Code","type":"text","required":false,"stage":0,"state":"private"},
        {"id":"0-11-email","text":"Email","type":"email","required":false,"stage":0,"state":"private"},
        {"id":"0-12-phone","text":"Phone","type":"text","required":true,"stage":0,"state":"general"},
        {"id":"0-13-howHeard","text":"How did you hear about Prana?","type":"select","options":[" ","Advertisement","Corporate","Doctor","Friends / Relatives","Website","Other"],"required":false,"stage":0,"state":"private"},
        {"id":"0-14-bloodGroup","text":"Blood Group","type":"select","options":[" ","A","AB","B","O"],"required":false,"stage":0,"state":"vitals"},
        {"id":"0-15-rhFactor","text":"Rh Factor","type":"select","options":[" ","+","-"],"required":false,"stage":0,"state":"vitals"},
        {"id":"0-16-allergies","text":"Known allergies / Prior surgeries if any","type":"text","required":false,"stage":0,"state":"protected"},
        {"id":"0-17-paymentMode","text":"Mode of payment","type":"select","options":["Credit/Debit Card","Cash","Corporate / Insurance"],"required":true,"stage":0,"state":"general"},


        {"id":"1-01-POI","text":"Appointed to Doctor","type":"text","required":true,"stage":1,"state":"reception"},
        {"id":"1-02-appointments","text":"Appointments","type":"list","required":true,"stage":1,"state":"reception"},
        {"id":"1-03-PID","text":"Prana Patient ID","type":"text","required":true,"stage":1,"state":"general"},
        {"id":"1-04-registerDT","text":"Registered at","type":"text","required":true,"stage":1,"state":"general"},
        {"id":"1-05-updatedDT","text":"Last Updated","type":"text","required":true,"stage":1,"state":"general"},


        {"id":"2-01-pulse","text":"Pulse (/min)","type":"text","units":"/minute","required":false,"stage":2,"state":"vitals"},
        {"id":"2-02-bp","text":"Blood Pressure (mmHg)","type":"text","units":"mmHg","required":false,"stage":2,"state":"vitals"},
        {"id":"2-03-rr","text":"Respiratory Rate (/min)","type":"text","units":"/minute","required":false,"stage":2,"state":"vitals"},
        {"id":"2-04-temperature","text":"Temperature (F)","type":"text","units":"degrees Fahrenheit","required":false,"stage":2,"state":"vitals"},
        {"id":"2-05-o2sat","text":"O2 Saturation (%)","type":"text","units":"%","required":false,"stage":2,"state":"vitals"},
        {"id":"2-06-Blood Sugar","text":"Blood Sugar (mg/dl)","type":"text","units":"mg/dl","required":false,"stage":2,"state":"vitals"},
        {"id":"2-07-Height","text":"Height","type":"text","units":"mg/dl","required":false,"stage":2,"state":"vitals"},
        {"id":"2-08-Weight","text":"Weight","type":"text","units":"mg/dl","required":false,"stage":2,"state":"vitals"},
        {"id":"2-09-BMI","text":"BMI","type":"text","units":"mg/dl","required":false,"stage":2,"state":"vitals"},

        {"id":"3-01-provDiag","text":"Provisional Diagnosis","type":"text","required":false,"stage":3,"state":"protected"},
        {"id":"3-02-presentSymp","text":"Presenting symptoms","type":"text","required":false,"stage":3,"state":"protected"},
        {"id":"3-03-history","text":"Past History","type":"text","required":false,"stage":3,"state":"protected"},
        {"id":"3-04-currentMeds","text":"Current Medications","type":"text","required":false,"stage":3,"state":"protected"},
        {"id":"3-05-GPE","text":"GPE","type":"text","required":false,"stage":3,"state":"protected"},
        {"id":"3-06-sysExamination","text":"Systemic examination","type":"text","required":false,"stage":3,"state":"protected"},
        {"id":"3-07-investigations","text":"Investigations","type":"text","required":false,"stage":3,"state":"protected"},
        {"id":"4-08-medications","text":"Medications","type":"list","required":false,"stage":3,"state":"protected-list"},
        {"id":"3-09-advice","text":"Advice","type":"text","required":false,"stage":3,"state":"protected"},
        {"id":"4-10-followUp","text":"Next follow up visit","type":"text","required":false,"stage":3,"state":"protected"},
        {"id":"patientMedData",
            "data":[
                {"id":"2-01-pulse","text":"Pulse (/min)","type":"text","units":"/minute","required":false,"stage":2,"state":"vitals"},
                {"id":"2-02-bp","text":"Blood Pressure (mmHg)","type":"text","units":"mmHg","required":false,"stage":2,"state":"vitals"},
                {"id":"2-03-rr","text":"Respiratory Rate (/min)","type":"text","units":"/minute","required":false,"stage":2,"state":"vitals"},
                {"id":"2-04-temperature","text":"Temperature (F)","type":"text","units":"degrees Fahrenheit","required":false,"stage":2,"state":"vitals"},
                {"id":"2-05-o2sat","text":"O2 Saturation (%)","type":"text","units":"%","required":false,"stage":2,"state":"vitals"},
                {"id":"2-06-Blood Sugar","text":"Blood Sugar (mg/dl)","type":"text","units":"mg/dl","required":false,"stage":2,"state":"vitals"},
                {"id":"2-07-Height","text":"Height","type":"text","units":"mg/dl","required":false,"stage":2,"state":"vitals"},
                {"id":"2-08-Weight","text":"Weight","type":"text","units":"mg/dl","required":false,"stage":2,"state":"vitals"},
                {"id":"2-09-BMI","text":"BMI","type":"text","units":"mg/dl","required":false,"stage":2,"state":"vitals"},

                {"id":"3-01-provDiag","text":"Provisional Diagnosis","type":"text","required":false,"stage":3,"state":"protected"},
                {"id":"3-02-presentSymp","text":"Presenting symptoms","type":"text","required":false,"stage":3,"state":"protected"},
                {"id":"3-03-history","text":"Past History","type":"text","required":false,"stage":3,"state":"protected"},
                {"id":"3-04-currentMeds","text":"Current Medications","type":"text","required":false,"stage":3,"state":"protected"},
                {"id":"3-05-GPE","text":"GPE","type":"text","required":false,"stage":3,"state":"protected"},
                {"id":"3-06-sysExamination","text":"Systemic examination","type":"text","required":false,"stage":3,"state":"protected"},
                {"id":"3-07-investigations","text":"Investigations","type":"text","required":false,"stage":3,"state":"protected"},
                {"id":"4-08-medications","text":"Medications","type":"list","required":false,"stage":3,"state":"protected-list"},
                {"id":"3-09-advice","text":"Advice","type":"text","required":false,"stage":3,"state":"protected"},
                {"id":"4-10-followUp","text":"Next follow up visit","type":"text","required":false,"stage":3,"state":"protected"},
            ]},

        {"id":"3-08-medications","text":"Medications","type":"list","required":false,"stage":3,"state":"protected-list"},
        {"id":"3-10-followUp","text":"Next follow up visit","type":"text","required":false,"stage":3,"state":"protected"},
        {"id":"3-11-COVIDSCREEN","text":"Covid Screening Form","type":"dict","required":false,"stage":3,"state":"protected"},
        {"id":"2-06-Weight","text":"Weight","type":"text","units":"mg/dl","required":false,"stage":2,"state":"vitals"},
        {"id":"100-stage","text":"Stage","type":"text","required":true,"stage":100,"state":"private"},
    ];
}

const PatientSchema = PatientSchemaArray();

export default PatientSchema