import React from 'react'
import ServiceTemplate from "./serviceTemplate";

const HTMLcont = (
    <>
        <h5 className="subs" style={{"textAlign":"justify"}}>
            The connection between neurological processes (neuro-), language (linguistic) and
            behavioural patterns is learned through experience (programming), and that these
            can be changed to achieve specific goals in life.  NLP can treat problems such as
            phobias, depression, tic disorders, psychosomatic illnesses, near-sightedness,
            allergy, common cold, and learning disorders.
            <br/><br/>
            NLP provides practical ways in which you can change the way that you think, view
            past events, and approach your life. Neuro-Linguistic Programming shows you how
            to take control of your mind, and therefore your life.
        </h5>

    </>
);

const content =
    {
        title: "Neurolinguistic Programming",
        imgurl: "#",
        para: HTMLcont
    };


class Neuolinguistic extends React.Component {
    render() {
        return (
            <ServiceTemplate serviceContent={content}/>
        )
    }
}

export default Neuolinguistic