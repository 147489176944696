import firestore from "../firebase";
// eslint-disable-next-line
import React from "react";
import CryptoJS from "crypto-js";

const db = firestore.collection("/mail");

class MailDataService {

    sendNewAppointment(email, PID, name, apptdata) {
        console.log("here",email,PID,name,apptdata);
        let a  = {
            type: "APPT_22364",
            to: email,
            message: {
                subject: "Your Appointment with Prana\n",
                text: "Dear " + name + ",\n" +
                    "\n" +
                    "Thank you for registering an appointment Prana.\n" +
                    "\n" +
                    `Your appointment with ${apptdata.doctor} will start at ${apptdata.time}, ${apptdata.date}`+
                    "\nYour personal Prana ID (PID) is " + PID + ".\n" +
                    "Please keep this mail safe and use this PID as reference for future appointments or event registrations.\n" +
                    "\n" +
                    "Thank you,\nTeam Prana",
                html: '<img src="https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/mail%2F1%20(1).png?alt=media&token=5642cc28-9f25-4051-a258-9e12bfb046e5"/>' +
                    '            <br/>' +
                    '            <p style="font-family:serif;color: #6b7a8f;font-size: 18px;margin-left:15px;" >' +
                    'Dear ' + name + ',<br/><br/>Thank you for registering an appointment Prana.<br/><br/>' +
                `Your appointment with ${apptdata.doctor} will start at ${apptdata.time}, ${apptdata.date}`+
                    '                <br/><br/><bold style="font-weight: 600;">Your personal Prana ID (PID) is ' + PID + '</bold>' +
                    '                <br/><br/>' +
                    '                <br/>' +
                    '                Please keep this mail safe and use this PID as reference<br/>for future appointments or event registrations.' +
                    '                registrations.<br/><br/>' +
                    '                Thank you,<br/>Team Prana'+
                    '            </p>' +
                    '            <img' +
                    '                src="https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/mail%2F2%20(1).png?alt=media&token=d9a48cd6-3cb6-4f46-936e-6c76e7e5734a"/>' +
                    '        </>'
            }
        }
        db.add(a)
            .then(() => console.log("Queued email for delivery!"));
        console.log(a)

    }
    sendNewRegEmail(email, PID, name) {
        db.add({
            type: "NEW_REG_22364",
            to: email,
            message: {
                subject: "Your Prana Registration ID\n",
                text: "Dear "+name+",\n" +
                    "\n" +
                    "Thank you for registering with Prana.\n" +
                    "\n" +
                    "Your personal Prana ID (PID) is "+PID+".\n" +
                    "Please keep this mail safe and use this PID as reference for future appointments or event registrations.\n" +
                    "\n" +
                    "Thank you,\nTeam Prana",
                html: '<img src="https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/mail%2F1%20(1).png?alt=media&token=5642cc28-9f25-4051-a258-9e12bfb046e5"/>' +
                    '            <br/>' +
                    '            <p style="font-family:serif;color: #6b7a8f;font-size: 18px;margin-left:15px;" >' +
                    'Dear '+ name +',<br/><br/>Thank you for registering with Prana.<br/><br/>' +
                    '                <bold style="font-weight: 600;">Your personal Prana ID (PID) is '+ PID +'</bold>' +
                    '                <br/><br/>' +
                    '                <br/>' +
                    '                Please keep this mail safe and use this PID as reference<br/>for future appointments or event registrations.' +
                    '                registrations.<br/><br/>' +
                    '                Thank you,<br/>Team Prana'+
                    '            </p>' +
                    '            <img' +
                    '                src="https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/mail%2F2%20(1).png?alt=media&token=d9a48cd6-3cb6-4f46-936e-6c76e7e5734a"/>' +
                    '        </>'
            },
        })
            .then(() => console.log("Queued email for delivery!"));

    }

    formatTime(unix_timestamp){

// Create a new JavaScript Date object based on the timestamp
// multiplied by 1000 so that the argument is in milliseconds, not seconds.

        let date = new Date(unix_timestamp * 1000);

        let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        //TODO WHAT THE HELL FIX THIS BUG!!!!
        let year = date.getFullYear() - 1969;
        let month = months[date.getMonth()];
        let dateN = date.getDate();
// Hours part from the timestamp
        let hours = date.getHours();
// Minutes part from the timestamp
        let minutes = "0" + date.getMinutes();
// Seconds part from the timestamp

// Will display time in 10:30:23 format
        return hours + ':' + minutes.substr(-2) + ', ' + dateN + ' ' + month + ' ' + year + ' ' ;

    }

    sendEventReg(email, data, PID, name){
        const title = data.Title;
        // eslint-disable-next-line

        const start = this.formatTime(data['Starts At']);
        const zoom = data['Zoom Link'];
        let zoomText = "";
        if(zoom.length>1){
            zoomText = "The Zoom Link for the event is "+zoom;
        }
        // eslint-disable-next-line
        const img = data['imageURL'];

        db.add({
            type: "EVENT_REG_22364",
            to: email,
            message: {
                subject: "Thank you for registering for "+title,
                text: "Dear "+name+" (PID: "+PID+"),\n" +
                    "\n" +
                    "Thank you for registering for "+title+"\n" +
                    "\n" +
                    "Your personal Prana ID (PID) is "+PID+".\n" +
                    "Please keep this mail safe as confirmation of your registration and use your PID for reference in future appointments or event registrations.\n" +
                    "\n" +
                    "Thank you,\nTeam Prana",
                html: '<img src="https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/mail%2F1%20(1).png?alt=media&token=5642cc28-9f25-4051-a258-9e12bfb046e5"/>' +
                    '            <br/>' +
                    '            <p style="font-family:serif;color: #6b7a8f;font-size: 18px;margin-left:15px;" >' +
                    'Dear '+ name +'(PID: '+ PID +'),<br/><br/>Thank you for registering for '+ title +'<br/><br/>' +
                    'The Event will start at '+ start +'<br/><br/>' +
                    '                <bold style="font-weight: 600;">'+ zoomText +'</bold>' +
                    '                <br/><br/>' +
                    '                <br/>' +
                    '                Please keep this mail safe as confirmation of your registration <br/>and use your PID for reference in future appointments or event registrations.' +
                    '                registrations.<br/><br/>' +
                    '                Thank you,<br/>Team Prana'+
                    '            </p>' +
                    '            <img' +
                    '                src="https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/mail%2F2%20(1).png?alt=media&token=d9a48cd6-3cb6-4f46-936e-6c76e7e5734a"/>' +
                    '        </>'
            },
        })
        .then(() => console.log("Queued email for delivery!"));
    }

    sendBillToPatient(name, PID, email, purpose, cost){
        let DOMAIN = "https://pranaintegrative.com/payment";
        let ciphertext = CryptoJS.AES.encrypt((`purpose=${encodeURI(purpose)}&email=${email}&name=${encodeURI(name)}&cost=${cost}`).toString(), process.env['REACT_APP_CRYPTO_KEY']).toString();
        let link = `${DOMAIN}?${ciphertext}`;
        db.add({
            type: "BILL_22364",
            to: email,
            message: {
                subject: "Payment Link for Prana",
                text: "Dear "+name+" (PID: "+PID+"),\n" +
                    "\n" +
                    "Please find attached your payment link:\n"+link+"\n" +
                    "\n" +
                    "Your personal Prana ID (PID) is "+PID+".\n" +
                    "Please keep this mail safe and use your PID for reference in future appointments or event registrations.\n" +
                    "\n" +
                    "Thank you,\nTeam Prana",
                html: '<img src="https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/mail%2F1%20(1).png?alt=media&token=5642cc28-9f25-4051-a258-9e12bfb046e5"/>' +
                    '            <br/>' +
                    '            <p style="font-family:serif;color: #6b7a8f;font-size: 18px;margin-left:15px;" >' +
                    'Dear '+ name +'(PID: '+ PID +'),<br/><br/>Please find attached your payment link:<br/><br/>' +
                    '<a href='+ link + '>Click Here to make your payment</a><br/>' +
                    '                Please keep this mail safe and use your PID for reference in future appointments or event registrations.' +
                    '                <br/><br/>' +
                    '                Thank you,<br/>Team Prana'+
                    '            </p>' +
                    '            <img' +
                    '                src="https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/mail%2F2%20(1).png?alt=media&token=d9a48cd6-3cb6-4f46-936e-6c76e7e5734a"/>' +
                    '        </>'
            },
        })
            .then(() => console.log("Queued email for delivery!"));
    }


}

export default new MailDataService();