import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import Accordion from 'react-bootstrap/Accordion'
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker'
import Button from 'react-bootstrap/Button'
import AppointmentDataService from "../../services/appointments.service";
import PatientDataService from "../../services/patient.service";
import MailDataService from "../../services/mail.service";
import PatientSchema from "./patients-schema"
import firestore from "../../firebase";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import {TeamInfo} from "../../providers/TeamProvider";
import Divider from '@mui/material/Divider';

const firedb = firestore;

const patientTemplate_strings = PatientSchema;



const DOCTORS = TeamInfo;

const AptTypes = [
    {"name": "Doctor's Appointment"},
    {"name": "Nursing Services"},
    {"name": "Yoga Programs"},
    {"name": "Prana's Integrative Lifestyle and Longevity Program"},
    {"name": "Prana's Integrative Diabetes Program"},
    {"name": "Emotional wellbeing & Resiliency Program"},
    {"name": "Weight reduction program"},
    {"name": "Healthy Gut Program"},
    {"name": "Prana Stress Reduction Program"},
    {"name": "Prana Health & Fitness Program"},
];

export default class SetAppointment extends Component {
    constructor(props){
        super(props);
        this.state = {
            appointmentDate: new Date(),
            appointmentTime: '10:00',
            appointmentDoctor: "",
            appointmentCost: "",
            appointmentDuration: 15,
            appointmentMessage: "",
            appointmentType:"",
            currentPatientAppointments: this.props.patientAppointments,
            LiveApt: this.props.live,
            AptIndex: 0
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleDocChange = this.handleDocChange.bind(this);
        this.handleTypChange = this.handleTypChange.bind(this);
        this.handleAptChange = this.handleAptChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.sendBill = this.sendBill.bind(this);
    }

    componentDidMount(){
        this.setState({
            appointmentDate: new Date(),
            appointmentTime: '10:00',
            appointmentDoctor: "",
            appointmentDuration: 15,
            appointmentCost: "",
            appointmentMessage: "",
            appointmentType:"",
            currentPatient: this.props.patient,
            currentPatientID: this.props.patientID,

        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.patientID !== prevProps.patientID) {
            this.setState({
                appointmentDate: new Date(),
                appointmentTime: '10:00',
                appointmentDuration: 15,
                appointmentCost: "",
                appointmentType:"",
                appointmentMessage: "",
                currentPatient: this.props.patient,
                currentPatientID: this.props.patientID,
                currentPatientAppointments: this.props.patient,
                LiveApt: this.props.live
            })
        }
    }

    returnBlankMed(){
        let blank = {};
        // eslint-disable-next-line
        patientTemplate_strings[41]['data'].map((each) =>{
            blank[each.id] = " "
        });
        blank['4-08-medications'] = [{"Tab/Cap/Injxn":"","Name":"","Dose":"","Frequency":"","Instructions":"","Duration":"",}];
        return blank
    }

    changePatient(ID){
        let currentAppointments = this.state.currentPatient['1-02-appointments'];
        let patientState = parseInt(this.state.currentPatient['100-stage']);
        currentAppointments.push(ID);
        let patientMedData = this.state.currentPatient['patientMedData'];
        patientMedData[ID] = this.returnBlankMed();
        let patientData = {
            "1-02-appointments": currentAppointments,
            "1-01-POI": this.state.appointmentDoctor,
            "100-stage": (patientState+1).toString(),
            patientMedData
        };

        PatientDataService.add(this.state.currentPatientID, patientData)
            .then(() => {
                alert("Appointment Set Successfully")
                this.setState({
                    appointmentMessage: "Appointment Set Successfully",
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    setAppointment = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if(this.state.appointmentCost === ""){
            alert("Please enter cost")
        }
        if(this.state.appointmentDoctor === ""){
            alert("Please enter doctor")
        }else{
        let unixtime = + new Date();
        let appID = "";
        let hh = parseInt(this.state.appointmentTime.split(":")[0]);
        let mm = parseInt(this.state.appointmentTime.split(":")[1]);
        let apd = this.state.appointmentDate;
        let startDate = new Date(apd.setHours( hh,mm,0 ));
        let endDate = new Date(apd.getTime() + this.state.appointmentDuration*60000);
        //Write to Appointments, create one
        let data ={
            "createdAt": unixtime,
            "appointmentDate": this.state.appointmentDate,
            "appointmentTime": this.state.appointmentTime,
            "appointmentDoctor": this.state.appointmentDoctor,
            "appointmentCost": this.state.appointmentCost,
            "appointmentType": this.state.appointmentType,
            "appointmentDuration": this.state.appointmentDuration,
            "startDate":startDate,
            "endDate":endDate,
            "patientName": this.props.patient['0-01-name'],
            "patientID": firedb.doc('patients/' + this.state.currentPatientID)
        };

        let t = this;
        AppointmentDataService.create(data)
            .then(function(docRef) {
                appID = docRef.id;
                t.changePatient(appID)
            })
            .catch((e) => {
                console.log(e);
            });


        }
        //Add in current patient data
        //  - add to appointments []
        //  - add empty med data with app_id


    };

    onTimeChange = appointmentTime => this.setState({ appointmentTime })


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        

        this.setState({
            [name]: value
        });
    }

    handleAptChange(event) {
        const val = event.target.value;

        this.setState({
            LiveApt: this.props.patientAppointments[val]['date'],
            aptIndex: val
            });

    }

    handleSelectChange(event) {
        this.setState({[event.target.id]: event.target.value});
    }

    handleDocChange(e,newValue){
        if(newValue){
            this.setState({appointmentDoctor: newValue.name});
        }

    }

    handleTypChange(e,newValue){
        if(newValue){
            this.setState({appointmentType: newValue.name});
        }

    }

    sendEmail(){
       //
        let a =this.props.patientAppointments[this.state.aptIndex];
        if(!this.state.liveAppt){
            a = this.props.live
        }
        MailDataService.sendNewAppointment(this.state.currentPatient['0-11-email'],this.state.currentPatient['1-03-PID'],this.state.currentPatient['0-01-name'],a)
        this.setState({
            appointmentMessage: "Appointment Email sent to patient"
        })
    }

    sendBill(){
        //name, PID, email, purpose, cost
        let a =this.props.patientAppointments[this.state.aptIndex];
        if(!this.state.liveAppt){
            a = this.props.live
        }
        MailDataService.sendBillToPatient(this.state.currentPatient['0-01-name'],this.state.currentPatient['1-03-PID'],this.state.currentPatient['0-11-email'],"Doctor's Appointment",a.cost)
        this.setState({
            appointmentMessage: "Bill Email sent to patient"
        })
    }

    render(){
        let optionItems = [];
        if(this.props.patientAppointments){
            optionItems = this.props.patientAppointments.map((o,index2) => <option key={1000*index2} value={index2}>{o.date}</option>);
        }
        return(
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Set Appointment
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>

                        <table id={"form-table"} style={{    borderCollapse: 'separate',
                            borderSpacing: '20px 30px'}}>
                            <tbody>
                                <tr>
                                    <td>
                                        <label htmlFor="appointmentDate" className="block">
                                            Date:
                                        </label>
                                    </td>
                                    <td>
                                        <DatePicker
                                            selected={this.state.appointmentDate}
                                            peekNextMonth
                                            showMonthDropdown
                                            dropdownMode="select"
                                            onChange={date => {
                                                this.setState({
                                                    appointmentDate: date,
                                                });
                                            }}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label htmlFor="appointmentDate" className="block">
                                            Time:
                                        </label>
                                    </td>
                                    <td>
                                        <TimePicker
                                            onChange={this.onTimeChange}
                                            value={this.state.appointmentTime}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label htmlFor="appointmentDate" className="block">
                                            Duration:
                                        </label>
                                    </td>
                                    <td>
                                    <input
                                            type= "text"
                                            name="appointmentDuration"
                                            required
                                            value={this.state.appointmentDuration || 15}
                                            id="appointmentDuration"
                                            onChange={this.handleInputChange}
                                        /> Minutes
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label htmlFor="appointmentDoctor" className="block">
                                            Appointment Type:
                                        </label>
                                    </td>
                                    <td>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            required
                                            options={AptTypes}
                                            getOptionLabel={(option) => option.name}
                                            style={{ width: 300 }}
                                            onChange={this.handleTypChange}
                                            renderInput={(params) => <TextField {...params} label="Appointment Type" variant="outlined" />}
                                        />

                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label htmlFor="appointmentDoctor" className="block">
                                            Doctor:
                                        </label>
                                    </td>
                                    <td>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={DOCTORS}
                                            getOptionLabel={(option) => option.name}
                                            style={{ width: 300 }}
                                            onChange={this.handleDocChange}
                                            renderInput={(params) => <TextField {...params} label="Doctor" variant="outlined" />}
                                        />
                                        {/*<select*/}
                                        {/*    value={this.state.appointmentDoctor || ""}*/}
                                        {/*    id="appointmentDoctor"*/}
                                        {/*    onChange={this.handleSelectChange}*/}
                                        {/*>*/}
                                        {/*    {opts}*/}
                                        {/*</select>*/}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label htmlFor="appointmentCost" className="block">
                                            Cost:
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            type= "text"
                                            name="appointmentCost"
                                            required
                                            value={this.state.appointmentCost || ""}
                                            id="appointmentCost"
                                            onChange={this.handleInputChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button id={"form-submit"} onClick={this.setAppointment} style={{height: "4.5rem",
                            width: "8rem"}}>
                            Create Appointment
                        </button>
                        <Divider/>
                        <br/><br/>
                        {optionItems.length >=1 &&
                        <>
                        <select value={this.state.aptIndex} id={"LiveApt"}  onChange={this.handleAptChange}>
                            {optionItems}
                        </select>
                            <br/>

                        <div id={"former-sub"} onClick={this.sendEmail}>
                            Send Email for this appointment
                        </div>
                        <div id={"former-sub"} onClick={this.sendBill}>
                            Send Bill for this appointment
                        </div>
                        </>
                        }


                        {this.state.appointmentMessage !== null && (
                            <div>
                                {this.state.appointmentMessage}
                            </div>
                        )}

                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }
}