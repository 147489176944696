import React from 'react'
import ServiceTemplate from "./serviceTemplate";

const HTMLcont = (
    <>
        <h5 className="subs" style={{"textAlign":"justify"}}>
            Prana in collaboration with Dr. Dangs Lab offers complete range of blood testing and diagnostics. Dr. Dang’s Lab is a state-of-the-art laboratory performing the complete spectrum of blood tests, NABL accredited since 2001, using world class equipment and technology and has a triple check policy. It has received the FICO healthcare excellence award in patient experience. Quality diagnostic services are ensured for all Prana customers.</h5>

    </>
);

const content =
    {
        title: "Blood Sampling & Diagnostics",
        imgurl: "#",
        para: HTMLcont
    };


class Philebotomy extends React.Component {
    render() {
        return (
            <ServiceTemplate serviceContent={content}/>
        )
    }
}

export default Philebotomy