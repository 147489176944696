import React, { useContext } from "react";
import InternalHeader from "../internalHeader"
import Footer from "../footer";
import Dashboard from "./Dashboard";
import { UserContext } from "../../providers/UserProvider";

function InternalHome() {
    const user = useContext(UserContext);

    if (user) {
        return (
            <div>
                <InternalHeader user={user}/>
                <div>
                    <div style={{padding: "1% 3% 1% 3%"}}>
                        <Dashboard />
                    </div>
                </div>

                <Footer/>
            </div>
        )
    }return(<></>)

    }

export default InternalHome
