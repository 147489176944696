import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage"

const firebaseConfig = {
    apiKey: process.env['REACT_APP_FB_API_KEY'],

    authDomain: "prana-his-database.firebaseapp.com",
    databaseURL: "https://prana-his-database.firebaseio.com",
    projectId: "prana-his-database",
    storageBucket: "gs://prana-his-database.appspot.com/",
    messagingSenderId: "308721721854",
    appId: "1:308721721854:web:3d6360d82da51a834e20af"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

export const generateUserDocument = async (user, additionalData) => {
    if (!user) return;
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();

    if (!snapshot.exists) {
        const { email, displayName } = user;
        try {
            await userRef.set({
                displayName,
                email,
                ...additionalData
            });
        } catch (error) {
            console.error("Error creating user document", error);
        }
    }
    return getUserDocument(user.uid);
};

export const createUserDocument = async (user, additionalData) => {
    if (!user) return;
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();

    if (!snapshot.exists) {
        const { email, displayName } = user;
        try {
            await userRef.set({
                displayName,
                email,
                ...additionalData
            });
        } catch (error) {
            console.error("Error creating user document", error);
        }
    }
};

const getUserDocument = async uid => {
    if (!uid) return null;
    try {
        const userDocument = await firestore.doc(`users/${uid}`).get();

        return {
            uid,
            ...userDocument.data()
        };
    } catch (error) {
        console.error("Error fetching user", error);
    }
};

export const updateUserSchedule = async (user,cur_sched,perm_sched) => {
    if (!user) return;
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();

    if (snapshot.exists) {
        console.log("tru")
        try {
            await userRef.update({
                perm_sched,
                cur_sched
            });
            return "User Schedule Updated!"
        } catch (error) {
            console.error("Error creating user document", error);
        }
    }
};

export const getPatientDocument = async pid => {
    if (!pid) return null;
    try {
        const userDocument = await firestore.doc(`patients/${pid}`).get();

        return {
            id: pid,
            ...userDocument.data()
        };
    } catch (error) {
        console.error("Error fetching Patient", error);
    }
};

export default firebase.firestore();