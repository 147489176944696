import React, {Component} from "react";
import EventDataService from "../../services/event.service";
import DateTimePicker from 'react-datetime-picker'
import {UserContext} from "../../providers/UserProvider";
import Card from 'react-bootstrap/Card'
import firebase from "firebase/compat/app";
import {storage} from "../../firebase";

import Checkbox from '@mui/material/Checkbox';
import StickyHeadTable from "./eventUserTable";



export default class Event extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.updateEvent = this.updateEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);


        this.state = {
            currentEvent: {
                "Title": "",
                "Description":"",
                "Cost":"",
                "Zoom Link":"",
                "Starts At":new Date(),
                "Ends At":new Date(),
                "ImageURL":null,
                "Completed": false,
                "Show": false,
                "Registered":[],
            },
            currentEventID: null,
            message: "",
            value:0,
            image: "",
            progress: 0,
        };
    }



    componentDidUpdate(prevProps, prevState) {
        if (this.props.event[0] !== prevProps.event[0]) {
            this.setState({
                currentEvent: this.props.event[1],
                currentEventID: this.props.event[0],
                start: this.props.event[1]['Starts At'].toDate(),
                end: this.props.event[1]['Ends At'].toDate(),
                message: "",
            });
        }
    }

    componentDidMount(){
        this.setState({
            currentEvent: this.props.event[1],
            currentEventID: this.props.event[0],
            start: this.props.event[1]['Starts At'].toDate(),
            end: this.props.event[1]['Ends At'].toDate(),
            user: this.context
        });
    }


    handleChange(event) {
        const val = event.target.value;
        const id = event.target.id;
        this.setState(function (prevState) {
            //console.log(id,val,prevState.currentEvent
            //);
            return {
                currentEvent: {
                    ...prevState.currentEvent,
                    [id]: val,
                },
            };
        });

    }


    updateEvent() {
        console.log("updating...");

        const data = {
            'Title': this.state.currentEvent.Title,
            'Description': this.state.currentEvent.Description,
            "Starts At":this.state.start,
            "Ends At": this.state.end,
            "Cost": this.state.currentEvent.Cost,
            "Zoom Link": this.state.currentEvent['Zoom Link'],
            "Completed": this.state.currentEvent.Completed,
            "Show": this.state.currentEvent.Show,
            "Registered": this.state.currentEvent.Registered
        };
        if(this.state.currentEvent.imageURL){
            data["imageURL"]=this.state.currentEvent.imageURL;
        }

        EventDataService.update(this.state.currentEventID, data)
            .then(() => {
                this.setState({
                    message: "The event was updated successfully!",
                });
            })
            .catch((e) => {
                console.log(e);
            });

    }

    deleteEvent() {
        EventDataService.delete(this.state.currentEventID)
            .then(() => {
                this.props.refreshList();
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleCheckChange = (event) => {
        let name = event.target.id;
        let c = event.target.checked;
        this.setState(function (prevState) {

            return {
                currentEvent: {
                    ...prevState.currentEvent,
                    [name]: c,
                },
            };
        });
    };

    handleStartDateChange = (event) => {
        this.setState( {
            start: event
        });
    };

    handleEndDateChange = (event) => {
        this.setState( {
            end: event
        });
    };

    handleImgChange = e => {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            this.setState(() => ({ image }));
        }
    };

    handleUpload = (e) => {
        e.preventDefault();
        const { image } = this.state;
        const uploadTask = firebase.storage().ref(`eventsImages/${image.name}`).put(image);
        uploadTask.on(
            "state_changed",
            snapshot => {
                // progress function ...
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                this.setState({ progress });
            },
            error => {
                // Error function ...
                console.log(error);
            },
            () => {
                // complete function ...
                storage
                    .ref("eventsImages")
                    .child(image.name)
                    .getDownloadURL()
                    .then(imageURL => {
                        this.setState((prevState)=>{
                            return{
                                currentEvent: {
                                    ...prevState.currentEvent,
                                    imageURL: imageURL,
                                },
                            }
                        });
                    });
            }
        );

    };

    render() {
        const { currentEvent } = this.state;

        return (
            <div>
                <h4>Event</h4>
                {currentEvent ? (
                    <div className="edit-form">
                        <form>
                            <Card>
                                <Card.Header>
                                    Event Details
                                </Card.Header>

                                    <Card.Body>
                                        <table id={"form-table"}>
                                            <tbody>
                                            <tr style={{height:"50px"}}>
                                                <td>
                                                    <label htmlFor={"Title"} className="block">
                                                        Title:
                                                    </label>
                                                </td>
                                                <td>
                                                    <textarea
                                                        required
                                                        className={"patientInput"}
                                                        name={"Title"}
                                                        value={this.state.currentEvent.Title || ""}
                                                        id={"Title"}
                                                        onChange={this.handleChange}
                                                    />
                                                </td>
                                            </tr>

                                            <tr style={{height:"50px"}}>
                                                <td>
                                                    <label htmlFor={"Description"} className="block">
                                                        Description:
                                                    </label>
                                                </td>
                                                <td>
                                                    <textarea
                                                        required
                                                        rows={"7"}
                                                        className={"patientInput"}
                                                        name={"Description"}
                                                        value={this.state.currentEvent.Description || ""}
                                                        id={"Description"}
                                                        style={{height:'auto'}}
                                                        onChange={this.handleChange}
                                                    />
                                                </td>
                                            </tr>

                                            <tr style={{height:"50px"}}>
                                                <td>
                                                    <label htmlFor={"start"} className="block">
                                                        Starts at:
                                                    </label>
                                                </td>
                                                <td>
                                                    <DateTimePicker
                                                        onChange={this.handleStartDateChange}
                                                        name={"start"}

                                                        value={this.state.start || ""}
                                                        id={"start"}
                                                    />
                                                </td>
                                            </tr>

                                            <tr style={{height:"50px"}}>
                                                <td>
                                                    <label htmlFor={"end"} className="block">
                                                        Ends at:
                                                    </label>
                                                </td>
                                                <td>
                                                    <DateTimePicker
                                                        onChange={this.handleEndDateChange}
                                                        name={"end"}
                                                        value={this.state.end || ""}
                                                        id={"end"}
                                                    />
                                                </td>
                                            </tr>

                                            <tr style={{height:"50px"}}>
                                                <td>
                                                    <label htmlFor={"Cost"} className="block">
                                                        Cost:
                                                    </label>
                                                </td>
                                                <td>
                                                    <textarea
                                                        required
                                                        className={"patientInput"}
                                                        name={"Cost"}
                                                        value={this.state.currentEvent.Cost || ""}
                                                        id={"Cost"}
                                                        onChange={this.handleChange}
                                                    />
                                                </td>
                                            </tr>

                                            <tr style={{height:"50px"}}>
                                                <td>
                                                    <label htmlFor={"Zoom Link"} className="block">
                                                        Zoom Link:
                                                    </label>
                                                </td>
                                                <td>
                                                    <textarea
                                                        required
                                                        className={"patientInput"}
                                                        name={"Zoom Link"}
                                                        value={this.state.currentEvent['Zoom Link'] || ""}
                                                        id={"Zoom Link"}
                                                        onChange={this.handleChange}
                                                    />
                                                </td>
                                            </tr>

                                            <tr style={{height:"50px"}}>
                                                <td>
                                                    <label htmlFor={"image"} className="block">
                                                        Image:
                                                    </label>
                                                </td>
                                                <td>
                                                    <div className="file-field input-field">
                                                        <div className="btn">
                                                            <span>
                                                                {!this.state.currentEvent.imageURL &&
                                                                    <>Upload File</>
                                                                }
                                                                {this.state.currentEvent.imageURL &&
                                                                <>Replace File</>
                                                                }

                                                            </span>
                                                            <input type="file" accept="image/*" onChange={this.handleImgChange} />
                                                        </div>
                                                        {/*<div className="file-path-wrapper">*/}
                                                        {/*    <input className="file-path validate" type="text" />*/}
                                                        {/*</div>*/}
                                                    </div>
                                                    <button
                                                        onClick={this.handleUpload}
                                                        className="waves-effect waves-light btn"
                                                    >
                                                        Upload
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Progress: {this.state.progress}</td>
                                            </tr>
                                            <tr style={{height:"50px"}}>
                                                <td>
                                                    <label htmlFor={"Completed"} className="block">
                                                        Completed: 
                                                    </label>
                                                </td>
                                                <td>
                                                    <Checkbox
                                                        checked={this.state.currentEvent.Completed}
                                                        onChange={this.handleCheckChange}
                                                        id={"Completed"}
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    />
                                                </td>

                                                <td>
                                                    <label htmlFor={"Show"} className="block">
                                                        Show:
                                                    </label>
                                                </td>
                                                <td>
                                                    <Checkbox
                                                        checked={this.state.currentEvent.Show}
                                                        onChange={this.handleCheckChange}
                                                        id={"Show"}
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                {this.state.currentEvent.imageURL && <img alt={'Event'} src={this.state.currentEvent.imageURL} style={{width: '250px'}}/>}
                                            </tr>
                                            </tbody>
                                        </table>
                                        <br/><br/>
                                        <h3>Registered Users:</h3>
                                        <p>{this.state.currentEvent.Registered.length} Users Registered</p>
                                        <br/>
                                        {this.state.currentEvent.Registered.length > 0 &&
                                        <StickyHeadTable rows={this.state.currentEvent.Registered}/>
                                        }


                                    </Card.Body>

                            </Card>



                        </form>



                        <button
                            id={"submit-button"}
                            onClick={this.deleteEvent}
                        >
                            Delete
                        </button>



                        <button
                            type="submit"
                            id={"submit-button"}
                            onClick={this.updateEvent}
                        >
                            Update
                        </button>

                        <button
                            id={"submit-button"}
                            onClick={() => {navigator.clipboard.writeText("https://pranaintegrative.com/eventRegister/" + this.state.currentEventID)}}
                        >
                            Copy Registration Link
                        </button>





                        <p>{this.state.message}</p>
                    </div>
                ) : (
                    <div>
                        <br />
                        <p>Please click on a Event ...</p>
                    </div>
                )}
            </div>
        );
    }
}
