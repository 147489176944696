import React, { Component } from "react";
import PatientDataService from "../../../services/patient.service";
import AppointmentDataService from "../../../services/appointments.service";

import {UserContext} from "../../../providers/UserProvider";
import Chart from "react-google-charts"


//-->when mounted, set state of fromDate to 1st of current month
//      get all patients,appointments after date
//      make graphs

//--
export default class AnalyticsDashboard extends Component {
    static contextType = UserContext;


    constructor(props) {
        super(props);
        let dateNow = setToMonth();
        this.onDataChangePatients = this.onDataChangePatients.bind(this);
        this.onDataChangeAppointments = this.onDataChangeAppointments.bind(this);
        this.getCashInflow = this.getCashInflow.bind(this);
        this.getGenderDistribution = this.getGenderDistribution.bind(this);
        this.getAgeDistribution = this.getAgeDistribution.bind(this);
        this.getHeardDistribution = this.getHeardDistribution.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        //this.refreshList = this.refreshList.bind(this);


        this.state = {
            fromD: dateNow/1000|0,
            dateSet: 'thisMonth'
        };
    }

    componentDidMount = async () => {

        PatientDataService.getAllAfter(this.state.fromD).onSnapshot(this.onDataChangePatients);
        AppointmentDataService.getAllAfter(this.state.fromD).onSnapshot(this.onDataChangeAppointments);
    };


    handleDateChange(e){
        this.setState({dateSet: e.target.value});
        let t = 0;
        switch (e.target.value) {
            case 'thisMonth': t = setToMonth();
                break;
            case 'thisWeek': t= setToWeek();
                break;
            case 'thisYear': t= setToYear();
                break;
            case 'allTime': t = 0;
                break;
            default: t= 0;

        }
        this.setState({fromD: t/1000|0});

        PatientDataService.getAllAfter(t/1000|0).onSnapshot(this.onDataChangePatients);
        AppointmentDataService.getAllAfter(t/1000|0).onSnapshot(this.onDataChangeAppointments);
    }

    onDataChangePatients(items){
        let patients = {};

        items.forEach((item) => {
            patients[item.id] = item.data()
        });

        this.setState({
            patients: patients,
            patientSize: Object.keys(patients).length
        });
    }

    onDataChangeAppointments(items){
        let appointments = {};

        items.forEach((item) => {
            appointments[item.id] = item.data()
        });

        this.setState({
            appointments: appointments,
            appointmentSize: Object.keys(appointments).length
        });
    }

    getCashInflow(){
        let cash = 0;
        const apMap = this.state.appointments;
        if(apMap){
            // eslint-disable-next-line
            Object.keys(apMap).map((key)=>{
                let str = apMap[key].appointmentCost === "" ? "0": apMap[key].appointmentCost
                
                let patt1 = /(\d+)/g;
                let result = str.match(patt1);
                if(isNaN(parseInt(result))){
                    console.log(str)
                }
                cash += parseInt(result);
            });
        }
        return cash
    }

    getGenderDistribution(){
        let m = 0;
        let f = 0;
        let o = 0;
        const patMap = this.state.patients;
        if(patMap){
            // eslint-disable-next-line
            Object.keys(patMap).map((key)=>{
                switch (patMap[key]['0-04-gender']) {
                    case 'Male': m++;
                        break;

                    case 'Female': f++;
                        break;

                    case 'Other': o++;
                        break;
                    default: o++;
                }
            });
        }
        return [
            ['Gender', 'Number'],
            ['Male', m],
            ['Female',f],
            ['Other',o]
        ]
    }

    getHeardDistribution(){
        let a = 0, c=0, d=0, f=0, w=0, o=0, u=0;

        const patMap = this.state.patients;
        if(patMap){
            // eslint-disable-next-line
            Object.keys(patMap).map((key)=>{
                switch (patMap[key]['0-13-howHeard']) {
                    case 'Advertisement': a++;
                        break;

                    case 'Corporate': c++;
                        break;

                    case 'Doctor': d++;
                        break;

                    case 'Friends / Relatives': f++;
                        break;

                    case 'Website': w++;
                        break;

                    case 'Other': o++;
                        break;

                    default: u++
                }
            });
        }
        return [
            ['Patient Source', 'Amount'],
            ['Advertisement',a],
            ['Corporate',c],
            ['Doctor',d],
            ['Friends / Relatives',f],
            ['Website',w],
            ['Other',o],
            ['Unknown',u]
        ]
    }

    getAgeDistribution(){
        let ret = {};
        const patMap = this.state.patients;
        if(patMap){
            // eslint-disable-next-line
            Object.keys(patMap).map((key)=>{
                let age = patMap[key]['0-02-age'].substring(0,2);

                if(age in ret){
                    ret[age]++
                }
                else{
                    ret[age] = 1
                }
            });
        }

        let finalList = [
            ['Age','# of patients']
        ];
        // eslint-disable-next-line
        Object.keys(ret).map((k)=>{
            finalList.push([k,ret[k]])
        });
        return finalList
    }

    analyticBox(heading, val){
        return(
            <div className={'col-md-4'} style={{height:'250px',marginBottom:'125px'}}>
                <div className={'analyticBox'}>
                    <h4 style={{padding:'10px'}}>{heading}</h4>
                    <h1 style={{paddingTop:'30px'}}>{val}</h1>
                </div>
            </div>
        )
    }

    render() {

        if(this.context.isAdmin){
            return (
                <>
                    <h1>ANALYTICS</h1>

                    <select value={this.state.dateSet} onChange={this.handleDateChange}>
                        <option value="thisMonth">This Month</option>
                        <option value="thisWeek">Past 7 days</option>
                        <option value="thisYear">This Year</option>
                        <option value="allTime">All Time</option>
                    </select>

                    <br/><br/><br/>
                <div className="row">
                    {this.analyticBox('Total Appointments:', this.state.appointmentSize)}

                    {this.analyticBox('Total Patient Registrations:', this.state.patientSize)}

                    {this.analyticBox('Cash inflow:', 'Rs. '+this.getCashInflow())}

                    <div className={'col-md-4'}>
                        <Chart
                            width={'500px'}
                            height={'300px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={this.getGenderDistribution()}
                            options={{
                                title: 'Patient Gender Distribution',
                            }}
                            rootProps={{ 'data-testid': '1' }}
                        />
                    </div>

                    <div className={'col-md-4'}>
                        <Chart
                            width={'500px'}
                            height={'300px'}
                            chartType="Bar"
                            loader={<div>Loading Chart</div>}
                            data={this.getAgeDistribution()}
                            options={{
                                // Material design options
                                chart: {
                                    title: 'Age Distribution'
                                },
                            }}
                            // For tests
                            rootProps={{ 'data-testid': '2' }}
                        />
                    </div>

                    <div className={'col-md-4'}>
                        <Chart
                            width={'500px'}
                            height={'300px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={this.getHeardDistribution()}
                            options={{
                                title: 'Patient Source Distribution',
                            }}
                            rootProps={{ 'data-testid': '1' }}
                        />
                    </div>
                </div>
                    </>
            );
        }
        return (
            <></>
        )
    }

}

function setToMonth(){
    let dateNow = new Date();
    dateNow.setDate(1);
    dateNow.setHours(0,0,0);
    dateNow.setMilliseconds(0);
    return dateNow
}

function setToWeek(){
    let dateNow = new Date();
    dateNow.setDate(dateNow.getDate() - 7);
    dateNow.setHours(0,0,0);
    dateNow.setMilliseconds(0);
    return dateNow
}

function setToYear(){
    let dateNow = new Date();
    dateNow.setDate(1);
    dateNow.setMonth(1);
    dateNow.setHours(0,0,0);
    dateNow.setMilliseconds(0);
    return dateNow
}