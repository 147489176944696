import React, {Component} from "react";
import {UserContext} from "../../providers/UserProvider";
import Card from 'react-bootstrap/Card'
import MailDataService from "../../services/mail.service"

export default class BillForm extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.sendBill = this.sendBill.bind(this);


        this.state = {
            currentPatient: {
                "0-01-name": "",

            },
            currentPatientID: null,
            purpose:"",
            cost:"",
            Name:"",
            PID:"",
            Email:"",
            patientAptMap: [],
            message: "",
            value:0,

        };
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props.patient[0] !== prevProps.patient[0]) {
            this.setState({
                currentPatient: this.props.patient[1],
                currentPatientID: this.props.patient[0],
                Name: this.props.patient[1]['0-01-name'],
                Email: this.props.patient[1]['0-11-email'],
                PID: this.props.patient[1]['1-03-PID'],
                message: "",
            });
            }

    }

    componentDidMount(){
        this.setState({
            currentPatient: this.props.patient[1],
            currentPatientID: this.props.patient[0],
            Name: this.props.patient[1]['0-01-name'],
            Email: this.props.patient[1]['0-11-email'],
            PID: this.props.patient[1]['1-03-PID'],
            user: this.context
        });
    }


    handleChange(event) {
        const val = event.target.value;
        const id = event.target.id;

        this.setState(function (prevState) {
            //console.log(id,val,prevState.currentPatient);
            return {
                [id]: val,
            };
        });

    }

    sendBill(){
        MailDataService.sendBillToPatient(this.state.Name,this.state.PID,this.state.Email,this.state.purpose,this.state.cost);
        this.setState({
            message: "Email Sent"
        })
    }

    render() {
        let isReceptionist = false;

        if(this.context){
            isReceptionist = this.context['isReceptionist'];
        }

        return (
            <div>
                <h4>Create Bill</h4>

                    <div className="edit-form">
                        <form>

                            { isReceptionist &&
                            <Card>
                                <Card.Header>
                                    Enter Bill Details
                                </Card.Header>

                                <Card.Body>
                                    <table id={"form-table"}>
                                        <tbody>
                                                <tr style={{height:"50px"}}>
                                                    <td>
                                                        <label htmlFor={"Name"} className="block">
                                                            Patient Name:
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input
                                                            required
                                                            name={"Name"}
                                                            value={this.state.Name || ""}
                                                            id={"Name"}
                                                            onChange={this.handleChange}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr style={{height:"50px"}}>
                                                    <td>
                                                        <label htmlFor={"PID"} className="block">
                                                            Prana ID (PID)
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input
                                                            required
                                                            name={"PID"}
                                                            value={this.state.PID || ""}
                                                            id={"PID"}
                                                            onChange={this.handleChange}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr style={{height:"50px"}}>
                                                    <td>
                                                        <label htmlFor={"email"} className="block">
                                                            Email:
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input
                                                            required
                                                            name={"email"}
                                                            value={this.state.Email || ""}
                                                            id={"Email"}
                                                            onChange={this.handleChange}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr style={{height:"50px"}}>
                                                    <td>
                                                        <label htmlFor={"purpose"} className="block">
                                                            Purpose:
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input
                                                            required
                                                            name={"purpose"}
                                                            value={this.state.purpose || ""}
                                                            id={"purpose"}
                                                            onChange={this.handleChange}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr style={{height:"50px"}}>
                                                    <td>
                                                        <label htmlFor={"cost"} className="block">
                                                            Cost:
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input
                                                            required
                                                            name={"cost"}
                                                            value={this.state.cost || ""}
                                                            id={"cost"}
                                                            onChange={this.handleChange}
                                                        />
                                                    </td>
                                                </tr>
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                            }


                        </form>

                        {isReceptionist &&
                        <button
                            id={"submit-button"}
                            onClick={this.sendBill}
                        >
                            Send Bill to Patient
                        </button>
                        }

                        <p>{this.state.message}</p>
                    </div>
                {/*) : (*/}
                {/*    <div>*/}
                {/*        <br />*/}
                {/*        <p>Please click on a Patient...</p>*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
        );
    }
}

