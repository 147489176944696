import React from 'react'
import ServiceTemplate from "./serviceTemplate";

const HTMLcont = (
    <>
    <h5 className="subs" style={{"textAlign":"justify"}}>
        Physicians at Prana specialize in providing state of the art care for patients with all acute and chronic medical issues.
        <br/><br/>
        We provide treatment for common complaints like, fever, cough, shortness of breath, chest discomfort / pain, upset stomach, diarrhoea, nausea, vomiting, pain abdomen, fatigue, headache, joint pains, insomnia, fainting & syncope.
        <br/><br/>
        With a special focus on Lifestyle Medicine all long standing chronic diseases like Diabetes, Hypertension, Heart disease, etc are managed comprehensively using multidisciplinary modalities.
        <br/><br/>
        <bold style={{fontWeight:600}}>Diseases and conditions managed at Prana:</bold>
    </h5>
        <ul className="list-group">
            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Prevention and Treatment of Covid-19
            </li>
            <li className="list-group-item faders b"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Viral Fever, Cold and Flu, Dengue, Malaria, Typhoid fever, Scrub typhus, Urinary tract infection, and Fever of unknown origin
            </li>
            <li className="list-group-item faders c"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Diabetes, High blood pressure, heart disease, high cholesterol, bronchitis, and emphysema.
            </li>
            <li className="list-group-item faders d"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Peptic ulcers, Irritable bowel syndrome, and Inflammatory bowel disease; Liver diseases like Hepatitis and Cirrhosis
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Chronic pain syndrome, Arthritis and Arthralgia, Allergies and Asthma
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Overweight and Obesity
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Vaccinations
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Thyroid disorders
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Headache
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Autoimmune diseases
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Infectious Diseases
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Geriatric healthcare
            </li>
        </ul>
        <br/><br/>
        <h5 className="subs" style={{"textAlign":"justify",fontWeight:600}}>
            All complex medical problems are resolved backed by latest global innovation and research.
        </h5>
    </>
);

const content =
    {
        title: "Internal Medicine",
        imgurl: "#",
        para: HTMLcont
    };


class InternalMedicine extends React.Component {
    render() {
        return (
            <ServiceTemplate serviceContent={content}/>
        )
    }
}

export default InternalMedicine