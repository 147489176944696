import React from 'react'
import ServiceTemplate from "./serviceTemplate";

const HTMLcont = (
    <>
        <h5 className="subs" style={{"textAlign":"justify"}}>
            Each one of us is unique as each of us responds differently to the same food. Under
            the guidance of our internationally acclaimed expert nutritionists we offer
            personalized nutrition plans. We address a wide range of nutrition related health
            problems including Obesity, Diabetes, Cardio-vascular disease, food allergies,
            digestive and immune system disorders.
            <br/><br/>
            At Prana, we give great importance to good diet and nutrition habits which lead to
            an improvement in overall health. Healthy diets can reduce the risk for chronic
            diseases, such as heart disease, diabetes, obesity, high blood pressure, high
            cholesterol, and osteoporosis. Our certified nutritional consultants work to
            understand unique needs of each individual and create a personalized plan to help
            achieve lifestyle and health goals.
            <br/><br/>
            In a personalized nutrition plan, we attempt to identify any possible food triggers and
            take into consideration both environmental and psychosomatic factors. Specific
            blood tests are used to identify gut health and food intolerance, vitamin, and mineral
            deficiencies.
            <br/><br/>
            <bold style={{fontWeight:600}}>Nutrition plans and health issues managed at Prana:</bold>
        </h5>
        <ul className="list-group">
            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Diabetes
            </li>
            <li className="list-group-item faders b"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Cardiovascular disease
            </li>
            <li className="list-group-item faders c"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Immunity
            </li>
            <li className="list-group-item faders d"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Allergies
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Celiac disease
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                High Cholesterol
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Overweight & Obesity
            </li>
        </ul>

    </>
);

const content =
    {
        title: "Personalised Nutrition",
        imgurl: "#",
        para: HTMLcont
    };


class Nutrition extends React.Component {
    render() {
        return (
            <ServiceTemplate serviceContent={content}/>
        )
    }
}

export default Nutrition