import React from 'react';
import './App.css';
import Header from "./components/header";
import HeroImage from "./components/herobanner";
import Footer from "./components/footer";
//import { loadReCaptcha } from 'react-recaptcha-google'
import UserProvider from "./providers/UserProvider";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles'


const theme = createTheme();

const useStyles = makeStyles((theme) => {
  root: {
    // some CSS that accesses the theme
  }
});

function App() {
  return (

    <ThemeProvider theme={theme}>
          <UserProvider>
            <Header />
            <HeroImage/>
            <Footer/>
          </UserProvider>
          </ThemeProvider>

  );
}

export default App;
