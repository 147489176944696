import ScheduleSelector from 'react-schedule-selector'
import React, { Component } from "react";
import Button from '@mui/material/Button';
import { updateUserSchedule } from "../../../firebase";

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


export default class ScheduleTable extends Component {
  
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.saveSchedule = this.saveSchedule.bind(this);
        this.process_schd = this.process_schd.bind(this)
        this.dateformat = this.dateformat.bind(this)
        this.updateSave = this.updateSave.bind(this)
        this.state = { 
            schedule : this.process_schd(this.props.user),
            curr_sched : this.props.user.cur_sched,
            perm_sched :this.props.user.perm_sched,
            new_perm_sched:{'Mon':[],'Tue':[],'Wed':[],'Thu':[],'Fri':[],'Sat':[],'Sun':[]},
            error:"",
            saveNew: true
        }
    }

    process_schd(usr){
        let sch = []
        const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const d = new Date();
        for (let i = 0; i < 7; i++) {
            
            let day = weekday[d.getDay()] + " " + months[d.getMonth()] + " " + this.dateformat(d.getDate())
            if(day in usr.cur_sched){
                console.log(usr.cur_sched[day])
                
                usr.cur_sched[day].forEach((data) => {
                    let dstr= day+" "+d.getFullYear()+" "+data
                    let dobj = new Date(dstr)
                    sch.push(dobj)
                });
                console.log(sch)
            }
            else{
                //console.log(usr.perm_sched[weekday[d.getDay()]])
                usr.perm_sched[weekday[d.getDay()]].forEach((data) => {
                    let dstr= day+" "+d.getFullYear()+" "+data
                    let dobj = new Date(dstr)
                    sch.push(dobj)
                });
            }
            d.setDate(d.getDate() + 1)
        }
        //console.log(sch)
        return sch
    }

    dateformat(d){
        if (d<10){
            return "0"+d
        }else{
            return d.toString()
        }
    }

    updateSave(e,c){
        this.setState({saveNew:c})
    }
  handleChange(newSchedule){
    this.setState({ schedule: newSchedule })
    let cs = {}
    let nps = {'Mon':[],'Tue':[],'Wed':[],'Thu':[],'Fri':[],'Sat':[],'Sun':[]}
    newSchedule.forEach(datetime_str =>{
        datetime_str= datetime_str.toString()
        const dt_split = datetime_str.split(" ");
        const daydate = dt_split.slice(0, 3).join(" ")
        const time = dt_split.slice(4).join(" ")
        if (daydate in cs) {
            cs[daydate].push(time) 
        }else
        {cs[daydate] = [time]}
        if (dt_split[0] in nps) {
            nps[dt_split[0]].push(time)
        }else
        {nps[dt_split[0]] = [time]}
        
    })
    this.setState({curr_sched:cs, new_perm_sched:nps})
  }

  saveSchedule(){
    try{
        let p_sched = this.state.saveNew ? this.state.new_perm_sched : this.state.perm_sched 
        const msg = updateUserSchedule(this.props.user, this.state.curr_sched,p_sched);
        this.setState({
            error: "User Schedule Updated!"
        });
    }
    catch(error){
        this.setState({
            error: "Error Signing up with email and password"
        });
    }
    
  }

  render() {
    return (
        <div className="scheduleSelector">
        <ScheduleSelector
        selection={this.state.schedule}
        numDays={7}
        minTime={8}
        maxTime={21}
        hourlyChunks={2}
        dateFormat="ddd D/M"
        timeFormat="hh:mm a"
        onChange={this.handleChange}
      />
      <br/><br/>
      <FormControlLabel
          checked={this.state.saveNew}
          onChange={this.updateSave}
          control={<Checkbox color="primary" />}
          label="Save as Permenent Weekly Schedule"
          labelPlacement="end"
        />
        <br/>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={this.saveSchedule}>
        Save
      </Button>
      <br/>
      <p>{this.state.error}</p>
      </div>
    )
  }
}