import React, { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import Footer from "../../footer";
import InternalHeader from "../../internalHeader";
import ScheduleTable from "./scheduleTable";


const Schedule = () => {
    const user = useContext(UserContext);
    let isDoctor_bool = false;
    if (user){
        const {isDoctor} = user;
        isDoctor_bool = isDoctor;
    }

    return (
        <div>
            {/*<InternalHeader user={user}/>*/}
            {user &&
            <InternalHeader user={user}/>
            }
            <div style={{paddingBottom: "5%", textAlign: "center"}}>
                {isDoctor_bool &&
                    <>
                    <h1>View and Edit Weekly Schedule</h1>
                    <br/>
                    <ScheduleTable user={user}/>
                    </>
                }
            </div>
            <Footer/>
        </div>

    )
};

export default Schedule;