export const TeamInfo = [
    {
        "name": "Dr. Ashutosh Shukla, MD, FACP",
        "designation": "Co-Founder & Medical Director",
    },
    {
        "name": "Dr. Ashok Vasishta, M.D., ABIM",
        "designation": "Clinical Assistant Professor, Central Michigan University",
    },
    {
        "name": "Dr. Rahul Nagpal, M.D.",
        "designation": "Senior Consultant, Paediatrics",
    },
    { "name":"Dr. Vipul Nanda, M.S., MRCS, MCh",
        "designation":"Senior Plastic Surgeon",
    },
    { "name":"Dr. Paritosh Gupta, M.S. DNB, MNAMAS, FMAS, FAIS, FALS, Exec MBA (IIM)",
        "designation":"General, Min Access Bariatric Surgery",
    },
    { "name":"Dr. Anjali Kumar, M.D., FICMCH, FMAS",
        "designation":"Director, Obstetrics & Gynaecology",
    },
    { "name":"Dr. Geeta Baruah Nath, MBBS; DGO",
        "designation":"Chief, Obstetrics & Gynaecology",
    },
    { "name":"Dr. Anupam Bhargava, MDH",
        "designation":"Dental Surgeon",
    },
    { "name":"Dr. Kumar Ranjan Maji, M.D.",
        "designation":"Senior Consultant, Mental Health & Behavioural Science",
    },
    { "name":"Dr. Mankul Goel, M.D.",
        "designation":"Senior Consultant, Dermatology & Integrative Medicine",
    },
    { "name":"Dr. Pooja Aggarwal, M.D.",
        "designation":"Senior Consultant, Dermatology",
    },
    { "name":"Ms. Ishi Khosla",
        "designation":"Clinical Nutritionist",
    },
    { "name":"Ms. Ashima Shukla, PhD",
        "designation":"Mind Body Medicine Expert & Integrative Psychotherapist",
    },
    { "name":"Ms. Mini Shastri",
        "designation":"Yoga Expert",
    },
    { "name":"Siri Guru Prakash Kaur Khalsa",
        "designation":"MCC ICF Coach, NLP Trainer",
    },
    { "name":"Ms. Inderbir Sandhu, PhD",
        "designation":"Psychologist & Gifted Child expert",
    },
    { "name":"Ms. Tanya Relan",
        "designation":"Speech Pathologist / Therapist",
    },
    { "name":"Ms Komal Malik",
        "designation":"Clinical Nutritionist",
    },
    { "name":"Dr. Suryamani,",
        "designation":"Physiotherapist",
    },
    { "name":"Dr. Shri Laxmi Shingatgeri, BAMS",
        "designation":"Ayurveda Physician",
    },
    { "name":"Dr. Bindu Ahlawat, BAMS, M.D.",
        "designation":"Ayurveda Physician",
    },
    { "name":"Ms Renu Sachdeva",
        "designation":"Psychotherapist & Life skills Trainer",
    },
    { "name":"Ms. Ritu Mudgal, M.Phil",
        "designation":"Clinical Psychologist",
    },
    { "name":"Ms. Sukhmani Pal",
        "designation":"Clinical Psychologist",
    },
    { "name":"Ms. Raphaela Garvey, Dip. PC; Dip Hyp CS",
        "designation":"Psychotherapist & Counselor",
    },
    { "name":"Ms. Alexandra Reelick",
        "designation":"Tai Chi Qigong Healing Teacher",
    },
    { "name":"Ms. Arti Kumar",
        "designation":"Sujok Master therapist ",
    },
    { "name":"Dr. Preeti Joshi",
        "designation":"Naturopathy & Yoga Teacher",
    },
    { "name":"Ms. Kanika Manchanda",
        "designation":"Yoga Teacher",
    },
    { "name":"Ms. Jivana Nataly",
        "designation":"Yoga Teacher",
    },
    { "name":"Mr. Krishna Pandey,",
        "designation":"Yoga Teacher",
    },
    { "name":"Ms Preeti Kodesia,",
        "designation":"Art therapist",
    },
    { "name":"Ms. Ranjini Chaudhuri,",
        "designation":"Creative Movement / Dance therapist",
    },
    { "name":"Ms Surbhi Arora,",
        "designation":"Calligraphy",
    },
    { "name":"Vaishnavacharya Jai Jai Sri Abhishek Goswami",
        "designation":"Spiritual healer",
    },
    { "name":"Mr. Sashi Nair",
        "designation":"Energy healer",
    },
    { "name":"Ms Lavanya Sundram",
        "designation":"Spiritual & Music healer",
    },
    {
        "name":"Dr. Reeta Saha",
        "designation":"Consultant, Paediatrics"
    },
    {
        "name":"Dr. D.M. Manoj",
        "designation":"Consultant, Physiotherapy"
    },
    {
        "name":"Dr. Gunjan Sabherwal",
        "designation":"Consultant, Gynaecology & Obstetrics"
    }


];