import PatientSchema from "./patients-schema";

function updateKeys(){
    let KeyList = {};
    for (let index = 0; index < PatientSchema.length; ++index) {
        KeyList[PatientSchema[index]["id"]] = index;
    }
    return KeyList
}

const PatientSchemaKeys = updateKeys();

export default PatientSchemaKeys