import React from 'react'
import Header from "../header";
import Footer from "../footer";
import TeamCard from "../teamCard"
import TeamCardNoBio from "../teamCardNoBio";
function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('../img/team/', false, /\.(png|jpe?g|svg)$/));

const FounderInfo = [
    {
        "name": "Dr. Ashima Shukla",
        "designation": "Founder and CEO",
        "photolink":"https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/team%2FDr%20Ashima%20Shukla.jpg?alt=media&token=40b7a291-e54a-4bc3-ac1c-d34d52e06f0b",
        "bio": "Ashima Shukla, an integrative psychotherapist and a pioneer of mind-body medicine, is the \
        founder of Prana Center for Integrative Medicine. Ashima has over 25 years of experience \
        leading her private practice and launching new global holistic health and wellness programs to \
        achieve optimal health and mental well-being for her clients. \
        Ashima’s expertise includes stress and anxiety management, women’s health issues, lifestyle \
        diseases, marital and adolescent counseling, and training national athletes on peak \
        performance and resiliency. The first Indian to be trained in Mind Body Medicine at the Harvard \
        Medical School, Ashima is a positive psychology specialist who customizes her wellness \
        coaching with a range of practices including cognitive restructuring, relaxation techniques, and \
        mindfulness. Ashima is also a certified Bio-resonance Analysis of Health (BAH) practitioner and \
        Neuro-Linguistic Programming practitioner from the Bandler Institute of NLP, California. Most \
        recently, Ashima practiced as a Senior Consultant, Department of Psychology &; Holistic \
        Medicine at Artemis Hospitals, Delhi NCR. \n \
        Ashima seeks her mindfulness in music, forest bathing, and among the flowers. A mother of \
        two, she hopes to use her expertise to drive a movement of value based parenting and holistic \
        lifestyle practices for optimal health and happiness in India and beyond."
    },
    {
        "name": "Dr. Ashutosh Shukla",
        "designation": "Senior Consultant Physician & Medical Director",
        "photolink": images[1],
        "bio": `Dr. Ashutosh Shukla is the Medical Advisor to Max Healthcare &amp; Senior Director Internal
        Medicine at Max Hospital, Gurgaon. Dr. Shukla is also the Co-Founder &amp; Medical Director of
        Prana Integrative Medicine Institute, India’s first Integrative healthcare centre.
        Prior to his current role he worked for fifteen years as the Director &amp; Head of Medicine at
        Artemis Hospital and served as the Chairman of the Post-graduate DNB teaching program. A
        Fellow of the American College of Physicians, Dr. Shukla did his MBBS and MD (Medicine)
        from Delhi University - Maulana Azad Medical College, Delhi. Dr. Shukla holds a post graduate
        degree in Healthcare Management from Harvard Medical School. He also trained at Stanford
        Medical School and San Francisco General Hospital. He has multiple awards to his credit
        including the Leadership award at Genpact and Chairman’s award at Max healthcare for
        outstanding performance.`
    }
];

const AdvisorInfo= [
    {
        "name": "Pramod Bhasin",
        "designation": "Founder Genpact Ltd & Chairman, Clix Capital Services",
        "photolink": "https://i.imgur.com/7aqXlZR.jpg"
    },
    {
        "name": "Bharat Kaushal",
        "designation": "Managing Director, Hitachi India",
        "photolink": "https://i.imgur.com/xaQj1w1.jpg?1"
    }
];

const SciAdvisorInfo = [
    {
        "name":"Dr. Darshan Mehta",
        "designation":"Medical Director, Harvard Medical School",
        "photolink": "https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/team%2FDarshanMehta.jpeg?alt=media&token=d7c4c31b-92e6-4e21-b4cc-1892bbc4aeb6",
    },
    {
        "name":"Dr. Navin Dang",
        "designation":"Founder - Dr. Dangs Lab",
        "photolink": images[3],
    },
    {
        "name":"Dr. Ashok Khurana",
        "designation":"Director, Genitourinary and Vascular Ultrasound - The Ultrasound Lab",
        "photolink": images[4],
    },
    {
        "name":"Dr. Praveen Chandra",
        "designation":"Chairman of Interventional Cardiology, Medanta ",
        "photolink": images[5],
    },
    {
        "name": "Dr. Leena Pradhan",
        "designation": "Life Sciences Leader, Rochester, Minnesota",
        "photolink": "https://i.imgur.com/4OmeyBw.png"
    }
];

export const TeamInfo = [
    {
        "name": "Dr. Ashutosh Shukla, MD, FACP",
        "designation": "Senior Consultant Physician",
        "photolink": images[1],
    },
    {
        "name": "Dr. Ashok Vasishta, M.D., ABIM",
        "designation": "Clinical Assistant Professor, Central Michigan University",
        "photolink": images[6],
    },
    { "name":"Ms. Ishi Khosla",
        "designation":"Clinical Nutritionist",
        "photolink":"https://i.imgur.com/1xJHxSe.jpg",
    },

    { "name":"Dr. Vipul Nanda, M.S., MRCS, MCh",
        "designation":"Senior Plastic Surgeon",
        "photolink":images[8],
    },
    
    { "name":"Dr. Geeta Baruah Nath, MBBS; DGO",
        "designation":"Chief, Obstetrics & Gynaecology",
        "photolink":images[11],
    },
    { "name":"Dr. Nidhi Jain, MBBS, MS",
        "designation":"Senior Consultant, Dermatology",
        "photolink":"https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/team%2FDr%20Nidhi%20Jain.jpg?alt=media&token=dcfdf291-31ba-4d1b-9f59-ae5bd73df943",
    },
    { "name":"Dr. Pooja Aggarwal, M.D.",
        "designation":"Senior Consultant, Dermatology",
        "photolink":images[15],
    },
    { "name":"Dr. Vikram Baruah Kaushik, M.S., DNB (Urology), FRCS (England), FACS (USA)",
        "designation":"Senior Consultant Urology",
        "photolink":"https://i.imgur.com/8hzKzko.jpg",
    },
    { "name":"Dr. Anupam Bhargava, MDH",
        "designation":"Dental Surgeon",
        "photolink":images[13],
    },
    
    { "name":"Dr. Atul Prasad",
        "designation":"Director Neurology",
        "photolink":"https://i.imgur.com/rLH20NG.jpg",
    },

    { "name":"Dr. Shubdha Bhanot",
        "designation":"Clinical Nutritionist",
        "photolink":"https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/team%2FDr%20Shubhda%20Bhanot.jpg?alt=media&token=6561e162-c097-43ee-967f-6fd417998192",
    },
    { "name":"Dr. Ashima Shukla",
        "designation":"Mind Body Medicine Expert & Integrative Health Coach",
        "photolink":images[0],
    },
    { "name":"Ms. Inderbir Sandhu",
        "designation":"Psychologist & Gifted Child expert",
        "photolink":"https://i.imgur.com/uMYx123.jpg",
    },
    { "name":"Ms. Renu Sachdeva",
        "designation":"Psychotherapist & Life skills Trainer",
        "photolink":images[22],
    },
    { "name":"Ms. Raphaela Garvey",
        "designation":"Psychotherapist & Counselor",
        "photolink":images[23],
    },
    { "name":"Ms. Tanya Sharma",
        "designation":"Psychologist",
        "photolink":"https://i.imgur.com/u3WsWti.jpg",
    },
    { "name":"Ms. Sukriti S Dhawan",
        "designation":"",
        "photolink":"https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/team%2FDr%20Sukriti%20Dhawan.jpg?alt=media&token=ec4ddb41-9405-4c3a-bc5f-c997a66315ce",
    },
    { "name":"Ms. Sonal Patel",
        "designation":"Life Coach",
        "photolink":"https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/team%2FMs%20Sonal%20Patel.png?alt=media&token=c9df5427-cb23-45ad-9b84-a20e29293014",
    },
    
    { "name":"Siri Guru Prakash Kaur Khalsa",
        "designation":"MCC ICF Coach, NLP Trainer",
        "photolink":images[16],
    },
    { "name":"Ms. Mini Shastri",
        "designation":"Yoga Expert",
        "photolink":images[37],
    },
    { "name":"Mr. Krishna Pandey",
        "designation":"Yoga Teacher",
        "photolink":images[31],
    },
    { "name":"Dr. Aparna K",
        "designation":"Ayurveda Physician",
        "photolink":"https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/team%2FPicture%20of%20Dr.%20Aparna%20K.jpg?alt=media&token=a5a8bd06-45c8-4817-a00a-3222a95b102e",
    },
    { "name":"Dr. Abhishek Joshi",
        "designation":"Ayurveda Physician",
        "photolink":"https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/team%2FPicture%20of%20Dr%20Abhishek%20Joshi.jpg?alt=media&token=92170987-0643-4878-b2d6-8d353102404f",
    },
    { "name":"Dr. Shri Laxmi Shingatgeri",
        "designation":"Ayurveda Physician",
        "photolink":images[36],
    },
    { "name":"Dr. Preeti Joshi",
        "designation":"Naturopathy, Reiki & Yoga specialist",
        "photolink":"https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/team%2FDr.%20Preeti%20Joshi.jpg?alt=media&token=e9692219-bc8b-4f8b-ae3c-fa7b44f1b6c6",
    },
    { "name":"Dr. Suryamani",
        "designation":"Physiotherapist",
        "photolink":images[19],
    },
    { "name":"Ms. Tanya Relan",
        "designation":"Speech Pathologist / Therapist",
        "photolink":images[17],
    },
    { "name":"Ms. Alka Raghbeer",
        "designation":"Bach Flower therapy specialist",
        "photolink":"https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/team%2FMs%20Alka%20Raghubir.jpg?alt=media&token=4f7fd40a-aff1-494d-bc58-26513c7cb243",
    },
    
    // { "name":"Dr. Bindu Ahlawat, BAMS, M.D.",
    //     "designation":"Ayurveda Physician",
    //     "photolink":images[18],
    // },
    
    // { "name":"Ms. Ritu Mudgal, M.Phil",
    //     "designation":"Clinical Psychologist",
    //     "photolink":images[20],
    // },

    { "name":"Vaishnavacharya Jai Jai Sri Abhishek Goswami",
        "designation":"Spiritual healer",
        "photolink":images[32],
    },
    { "name":"Mr. Sashi Nair",
        "designation":"Energy healer",
        "photolink":images[33],
    },
    { "name":"Ms Lavanya Sundram",
        "designation":"Spiritual & Music healer",
        "photolink":images[34],
    },

];



class Team extends React.Component {
    render() {
        return (
            <div>
                <Header/>
                {/*<h2 className="heroTitle" style={{padding: "5%"}}>The Team</h2>*/}
                <div style={{padding:"5% 5%"}}>
                    <h2 className="heroTitle" style={{fontSize:"50px",padding:"2%"}}>Our Founders</h2>
                    <div className="row team-row" style={{marginBottom:"55px"}} >
                        {FounderInfo.map((each) =>
                            <div className="card-div col-md-6">
                                <TeamCard member={each}/>
                            </div>
                        )}
                    </div>
                </div>
                <div style={{padding:"0px 5%"}}>
                    <h2 className="heroTitle" style={{fontSize:"40px",padding:"2%"}}>Advisors</h2>
                    <div className="row team-row" style={{marginBottom:"75px"}} >
                        {AdvisorInfo.map((each) =>
                            <div className="card-div col-md-4">
                                <TeamCardNoBio member={each}/>
                            </div>
                        )}
                    </div>
                </div>
                <div style={{padding:"0px 5%"}}>
                    <h2 className="heroTitle" style={{fontSize:"40px",padding:"2%"}}>Scientific Advisors</h2>
                    <div className="row team-row" style={{marginBottom:"75px", justifyContent: "center", rowGap: "35px"}} >
                        {SciAdvisorInfo.map((each) =>
                            <div className="card-div col-md-4">
                                <TeamCardNoBio member={each}/>
                            </div>
                        )}
                    </div>
                </div>
                <div style={{padding:"0px 5%"}}>
                    <h2 className="heroTitle" style={{fontSize:"40px",padding:"2%"}}>Our Team</h2>
                    <div className="row team-row" style={{marginBottom:"75px"}} >
                        {TeamInfo.map((each) =>
                            <div className="card-div col-md-3" style={{marginBottom:"5%"}}>
                                <TeamCardNoBio member={each}/>
                            </div>
                        )}
                    </div>
                </div>

                <Footer/>
            </div>
        )
    }
}
export default Team
