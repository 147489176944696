import React from 'react';
import Header from "../header";
import Footer from "../footer";

export default function Center (){

        // const picLink1 = "https://i.imgur.com/hTJfR0i.jpg";
        // const picLink2 = "https://i.imgur.com/qQzsS8c.jpg";
        // const picLink3 = "https://i.imgur.com/lrQ4v4t.jpg";
        const picLink4 = "https://i.imgur.com/yLW61BX.jpg";
        const picLink5 = "https://i.imgur.com/Y7nDYjL.jpg";

        return (
            <div>
                <Header/>

                <div>
                    <div className="row" style={{padding: "5%"}}>
                        <div className="col-md-4">
                            <h1 className="heroTitle">Specialities</h1>

                            <br/><br/>
                            <ul className="list-group list-services cntr-list">
                                <li className="list-group-item faders "><i className="fa fa-angle-right fa-fw"
                                                                           aria-hidden="true"></i>Allopathy: Internal
                                    Medicine, Pathology
                                </li>
                                <li className="list-group-item faders "><i className="fa fa-angle-right fa-fw"
                                                                           aria-hidden="true"></i>Nutrition
                                </li>
                                <li className="list-group-item faders "><i className="fa fa-angle-right fa-fw"
                                                                           aria-hidden="true"></i>Mind Body Medicine
                                </li>
                                <li className="list-group-item faders "><i className="fa fa-angle-right fa-fw"
                                                                           aria-hidden="true"></i>Positive Psychology
                                </li>
                                <li className="list-group-item faders "><i className="fa fa-angle-right fa-fw"
                                                                           aria-hidden="true"></i>Yoga
                                </li>
                                <li className="list-group-item faders "><i className="fa fa-angle-right fa-fw"
                                                                           aria-hidden="true"></i>Ayurveda
                                </li>
                                <li className="list-group-item faders "><i className="fa fa-angle-right fa-fw"
                                                                           aria-hidden="true"></i>Lifestyle Medicine
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-8">
                            <div className="row gallery">
                                <div className="col-sm-6 col-md-4">
                                    <img src={"https://i.imgur.com/sA6qlxt.jpg"} alt="Bridge" />
                                    <img src={"https://i.imgur.com/aZ61VCC.jpg"} alt="Bridge" />
                                    {/*<img src={"https://i.imgur.com/GzH88O7.jpg"} alt="Bridge" />*/}
                                </div>

                                <div className="col-sm-6 col-md-4">
                                    <img src={picLink4} alt="Bridge" />
                                </div>

                                <div className="col-sm-12 col-md-4">
                                    <img src={"https://i.imgur.com/lrQ4v4t.jpg"} alt="Bridge" />


                                    <img src={picLink5} alt="Bridge" style={{marginTop:'8%'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer/>
            </div>
        )

}

