import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import Contact from './components/pages/contact'
import * as serviceWorker from './serviceWorker';
import Center from "./components/pages/center";
import Team from "./components/pages/team";
import Login from "./components/pages/login"
import UserProvider from "./providers/UserProvider";
import AdminCreateUser from "./components/pages/internal/adminCreateUser"
import Register from "./components/pages/register";
import Appointments from "./components/pages/internal/appointments";
import Services from "./components/pages/servicesPage/services";
import PersonalisedHCP from "./components/pages/servicesPage/personilisedHCP";
import InternalMedicine from "./components/pages/servicesPage/internalMedicine"
import MindBodyMedicine from "./components/pages/servicesPage/MindBodyMedicine";
import Counselling from "./components/pages/servicesPage/counselling";
import Nutrition from "./components/pages/servicesPage/nutrition";
import Ayurveda from "./components/pages/servicesPage/ayurveda";
import Physiotherapy from "./components/pages/servicesPage/physiotherapy";
import Neuolinguistic from "./components/pages/servicesPage/neurolinguistic";
import Philebotomy from "./components/pages/servicesPage/philebotomy";
import DevAddPatient from "./components/patientsdb/devregister";
import Analytics from "./components/pages/internal/analytics";
import EventsList from "./components/eventsdb/eventsList";
import EventRegister from "./components/eventsdb/eventsRegister";
import EventReturn from "./components/eventsdb/eventReturn";
import CreateBill from "./components/patientsdb/create-bill";
import BillPage from "./components/eventsdb/billPage";
import Databaser from "./components/database";
import ResetPassword from "./components/pages/auth_email";
import Partners from './components/pages/partners';
import Schedule from './components/pages/internal/doctorSchedule';
import InternalHome from './components/pages/internalHome';


const routing = (
    <UserProvider>
    <Router>
        <div>
            <Route exact path="/" component={App} />
            <Route path="/contact" component={Contact} />
            <Route path="/center" component={Center} />
            <Route path="/team" component={Team} />
            <Route path="/Partners" component={Partners} />
            <Route path="/Services" component={Services} />
            <Route path="/PersonalisedHCP" component={PersonalisedHCP} />
            <Route path="/InternalMedicine" component={InternalMedicine} />
            <Route path="/MindBodyMedicine" component={MindBodyMedicine} />
            <Route path="/Counselling" component={Counselling} />
            <Route path="/Nutrition" component={Nutrition} />
            <Route path="/Ayurveda" component={Ayurveda} />
            <Route path="/Physiotherapy" component={Physiotherapy} />
            <Route path="/NLP" component={Neuolinguistic} />
            <Route path="/Philebotomy" component={Philebotomy} />
            {/*INTERNAL*/}
            <Route path="/login" component={Login} />
            <Route path="/dashboard" component={InternalHome} />
            <Route path="/adminCreateUser" component={AdminCreateUser} />
            <Route path="/register" component={Register} />
            <Route path="/appointments" component={Appointments} />
            <Route path="/analytics" component={Analytics} />
            <Route path="/events" component={EventsList} />
            <Route path="/eventRegister/:EID" component={EventRegister} />
            <Route path="/checkout" component={EventReturn} />
            <Route path="/bill" component={CreateBill} />
            <Route path="/payment" component={BillPage} />
            <Route path="/schedule" component={Schedule} />

            <Route name="DEV" path="/devPortalAdd" component={DevAddPatient} />
            <Route name="dbdev" path="/dbdev" component={Databaser} />
            <Route name="resetPassword" path="/auth/action" component={ResetPassword} />




        </div>
    </Router>
    </UserProvider>
)
ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
