import React, { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import Clock from 'react-live-clock';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Calendar from "./internal/calendar";
const ProfilePage = () => {
const user = useContext(UserContext);
const {email, displayName, designation, isAdmin, isDoctor, isReceptionist} = user;
    return (
        <div>
                <div id="dashboard-header">
                <Grid container spacing={3} direction="row" justifyContent="space-between">
                    <Grid item xs={8}>
                        <Clock format={'dddd, MMMM Mo, YYYY, hh:mm:ss A'} ticking={true} timezone={'Asia/Kolkata'} />
                        <p style={{fontSize:"34px"}}>Welcome, {displayName}</p>
                        {isAdmin &&
                            <p>Admin Privileges</p>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        {(isAdmin || isReceptionist) &&
                        //     <Button variant="contained" id={"dashboard-button"} color="primary">
                        //     New Appointment
                        //   </Button>
                        <></>
                        }
                        {(isDoctor) &&
                            <Button variant="contained" component={Link} to="/schedule" id={"dashboard-button"} color="primary">
                            Change Schedule
                          </Button>
                        }
                    </Grid>
                </Grid>
                    
                    
                </div>

                <Calendar displayName={displayName} isAdmin={isAdmin} isDoctor={isDoctor} isReceptionist={isReceptionist}/>

        </div>
    )
};

export default ProfilePage;