import React, {useState} from "react";
import Header from "../header";
import Footer from "../footer";
import { useHistory } from 'react-router-dom';


// import firebase from "firebase/app";
// import "firebase/auth";
import { auth } from "../../firebase";

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [error, setError] = useState("");
    let history = useHistory()


    const query = new URLSearchParams(window.location.search);

    const actionCode = query.get("oobCode");

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;

        if(name === 'userPassword'){
            setPassword(value);
        }
        else if (name === 'userPassword2'){
            setPassword2(value)
        }
    };

    const loginWithNew = (event, password, password2) => {
        event.preventDefault();
        auth.verifyPasswordResetCode(actionCode).then((email) => {
            let accountEmail = email;

            if(password === password2){
                // Save the new password.
                auth.confirmPasswordReset(actionCode, password2).then((resp) => {
                    auth.signInWithEmailAndPassword(accountEmail, password2).then(()=>{
                        history.push("/login");
                    }).catch(error => {
                        setError("Error: Please try again.");
                        console.error("Error signing in with password and email", error);
                    });
                }).catch((error) => {
                    setError('Your reset password code has expired, please try the request again')
                    // Error occurred during confirmation. The code might have expired or the
                    // password is too weak.
                });
            }


        }).catch((error) => {
            setError('Your reset password code has expired, please try the request again')
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
        });
    };

    return (
        <div>
            <Header/>
            <div>
                <div style={{padding: "5%", textAlign: "center"}}>
                    <div >
                        <h1 className={"heroTitle"} >Reset Password</h1>
                        <div><br/>

                            <br/>
                            <form className="">
                                <br/><br/>
                                <table id={"form-table"}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <label htmlFor="userPassword" className="block">
                                                Enter New Password:
                                            </label>
                                        </td>
                                        <td>
                                            <input
                                                type="password"

                                                name="userPassword"
                                                value = {password}

                                                id="userPassword"
                                                onChange = {(event) => onChangeHandler(event)}
                                            />
                                        </td>
                                    </tr>
                                    <br/><br/>

                                    <>
                                        <tr>
                                            <td>
                                                <label htmlFor="userPassword2" className="block">
                                                    Enter New Password Again:
                                                </label>
                                            </td>
                                            <td>
                                                <input
                                                    type="password"

                                                    name="userPassword2"
                                                    value = {password2}

                                                    id="userPassword2"
                                                    onChange = {(event) => onChangeHandler(event)}
                                                />
                                            </td>
                                        </tr>
                                        <br/><br/>
                                    </>


                                    </tbody>
                                </table>
                                {error !== "" && <div>{error}</div>}

                                <button  id="submit-button" onClick = {(event) => {loginWithNew(event, password, password2)}}>
                                    Submit
                                </button>


                            </form>


                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )


}

export default ResetPassword



