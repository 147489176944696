import React, { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import CreateUser from "./createUser"
import Footer from "../../footer";
import InternalHeader from "../../internalHeader";

const AdminCreateUser = () => {
    const user = useContext(UserContext);
    let isAdmin_bool = false;
    if (user){
        const {isAdmin} = user;
        isAdmin_bool = isAdmin;
    }

    return (
        <div>
            {/*<InternalHeader user={user}/>*/}
            {user &&
                <InternalHeader user={user}/>
            }
            <div style={{paddingBottom: "5%", textAlign: "center"}}>
            {isAdmin_bool &&
            <CreateUser/>
            }
            </div>
        <Footer/>
        </div>

    )
};

export default AdminCreateUser;