import ScheduleSelector from 'react-schedule-selector'
import React, { Component } from "react";
import Button from '@mui/material/Button';
import AppointmentDataService from "../../../services/appointments.service";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled, alpha } from '@mui/material/styles';
import { green, lightBlue } from '@mui/material/colors';
import { ViewState, GroupingState, IntegratedGrouping, } from '@devexpress/dx-react-scheduler';
import LinearProgress from '@mui/material/LinearProgress';
import {
    Scheduler,
    DayView,
    WeekView,
    MonthView,
    Appointments,
    Toolbar,
    ViewSwitcher,
    DateNavigator,
    TodayButton,
    AppointmentTooltip,
    AppointmentForm,
    Resources,
    GroupingPanel
} from '@devexpress/dx-react-scheduler-material-ui';
import Paper from '@mui/material/Paper';

const schedulerData = [
    { startDate: "2022-09-03T10:00", endDate: "2022-09-03T10:30", title: 'Meeting' },
    { startDate: "2022-09-04T09:45", endDate: "2022-09-04T11:45", title: 'Meeting' },
];

function processDates(d) {
    let dd = String(d.getDate()).padStart(2, '0');
    let mm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    let hh = String(d.getHours()).padStart(2, '0');
    let min = String(d.getMinutes()).padStart(2, '0');
    let yyyy = d.getFullYear();
    let y = yyyy + '-' + mm + '-' + dd + 'T' + hh + ":" + min;
    return y
}

const PREFIX = 'calendar';

const classes = {
    todayCell: `${PREFIX}-todayCell`,
    weekendCell: `${PREFIX}-weekendCell`,
    today: `${PREFIX}-today`,
    weekend: `${PREFIX}-weekend`,
};

const getUserDocument = async doc => {
    if (!doc) return null;
    try {
        const userDocument = await doc.get();

        return userDocument.data();
    } catch (error) {
        console.error("Error fetching user", error);
    }
};

const StyledWeekViewTimeTableCell = styled(WeekView.TimeTableCell)(({ theme }) => ({
    [`&.${classes.todayCell}`]: {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.14),
        },
        '&:focus': {
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
    },
    [`&.${classes.weekendCell}`]: {
        backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
        '&:hover': {
            backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
        },
        '&:focus': {
            backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
        },
    },
}));

const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)(({ theme }) => ({
    [`&.${classes.today}`]: {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
    },
    [`&.${classes.weekend}`]: {
        backgroundColor: alpha(theme.palette.action.disabledBackground, 0.06),
    },
}));

const TimeTableCell = (props) => {
    const { startDate } = props;
    const date = new Date(startDate);

    if (date.getDate() === new Date().getDate()) {
        return <StyledWeekViewTimeTableCell {...props} className={classes.todayCell} />;
    } if (date.getDay() === 0 || date.getDay() === 6) {
        return <StyledWeekViewTimeTableCell {...props} className={classes.weekendCell} />;
    } return <StyledWeekViewTimeTableCell {...props} />;
};

const DayScaleCell = (props) => {
    const { startDate, today } = props;

    if (today) {
        return <StyledWeekViewDayScaleCell {...props} className={classes.today} />;
    } if (startDate.getDay() === 0 || startDate.getDay() === 6) {
        return <StyledWeekViewDayScaleCell {...props} className={classes.weekend} />;
    } return <StyledWeekViewDayScaleCell {...props} />;
};

const StyledFormControlLabel = styled(FormControlLabel)(({
    theme: { spacing, palette, typography },
}) => ({
    [`&.${classes.formControlLabel}`]: {
        padding: spacing(2),
        paddingLeft: spacing(10),
    },
    [`&.${classes.text}`]: {
        ...typography.caption,
        color: palette.text.secondary,
        fontWeight: 'bold',
        fontSize: '1rem',
    },
}));


const isWeekOrMonthView = viewName => viewName === 'Week' || viewName === 'Month';


const GroupOrderSwitcher = (({ isGroupByDate, onChange }) => (
    <StyledFormControlLabel
        control={
            <Checkbox checked={isGroupByDate} onChange={onChange} color="primary" />
        }
        label="Group by Date First"
        className={classes.formControlLabel}
        classes={{ label: classes.text }}
    />
));


const doctorData = [
    { text: 'All Doctors', id: 99, color: lightBlue },
    // { text: 'Dr. Ashima Shukla', id: 2, color: green },
];

export default class Calendar extends Component {

    constructor(props) {
        super(props);
        this.onDataChangeAppointments = this.onDataChangeAppointments.bind(this)
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        let y = yyyy + '-' + mm + '-' + dd;
        this.state = {
            appointments: [

            ],
            appointmentSize: 0,
            today_d: y,
            resources: [{
                fieldName: 'doctorID',
                title: 'Doctor',
                instances: doctorData,
            }],
            patient2Ids: {},
            doctors: {},
            loading: true,
            grouping: [{
                resourceName: 'doctorID',
            }],
            groupByDate: undefined,
            isGroupByDate: false,
        }

        this.onGroupOrderChange = () => {
            const { isGroupByDate } = this.state;
            this.setState({
                isGroupByDate: !isGroupByDate,
                groupByDate: isGroupByDate ? undefined : isWeekOrMonthView,
            });
        };
    }
    componentDidMount = async () => {
        if (this.props.isReceptionist || this.props.isAdmin) {
            AppointmentDataService.getThisWeek().onSnapshot(this.onDataChangeAppointments);
        }
        else if (this.props.isDoctor) {
            AppointmentDataService.getThisWeekDoc(this.props.displayName).onSnapshot(this.onDataChangeAppointments);
        }

    };

    onDataChangeAppointments(items) {
        this.setState({ loading: true, appointments: [] })
        let appointments = [];
        let i = 0
        items.forEach((item) => {
            let data = item.data()
            let a = ""
            getUserDocument(data['patientID']).then(dd => {
                let pp = data['patientID'].path
                let n = "_"
                if(dd){
                     n = dd['0-01-name'];
                }else{
                    console.log(data)
                }
                this.setState({
                    patient2Ids: {
                        [pp]:n ,
                        ...this.state.patient2Ids
                    }
                })

                data['id'] = item.id + String(i)
                i = i + 1
                //console.log(item.id)
                if (data['appointmentDoctor'] in this.state.doctors) {
                    data['doctorID'] = this.state.doctors[data['appointmentDoctor']]
                } else {
                    let k =  Object.keys(this.state.doctors).length + 1;
                    let ist = this.state.resources[0].instances
                    //console.log(ist)
                    this.setState({
                        doctors: {
                            [data['appointmentDoctor']]: Object.keys(this.state.doctors).length + 1,
                            ...this.state.doctors
                        },
                        resources: [{
                            fieldName: 'doctorID',
                            title: 'Doctor',
                            instances: [{ text: data['appointmentDoctor'], id: Object.keys(this.state.doctors).length + 1, color: lightBlue },
                            ...ist],
                        },
                            //...this.state.resources
                        ]
                    })
                    data['doctorID'] = k;
                }

                data['title'] = n
                if ('startDate' in data) {
                    data['startDate'] = data['startDate'].toDate()
                    data['endDate'] = data['endDate'].toDate()
                    appointments.push(data)
                    this.setState({
                        appointments: [data,
                            ...this.state.appointments]
                    })
                } else {
                    let dt = data['appointmentDate'].toDate()
                    let tt = data['appointmentTime']
                    let tt_hh = tt.split(":")[0]
                    let tt_mm = tt.split(":")[1]

                    let startDate = new Date(dt.setHours(tt_hh, tt_mm, 0));
                    let endDate = new Date(dt.getTime() + 15 * 60000);
                    data['startDate'] = startDate
                    data['endDate'] = endDate
                    this.setState({
                        appointments: [data,
                            ...this.state.appointments]
                    })
                    appointments.push(data)
                }
            })
                .catch((err) => {
                    console.log(err)
                });

            if (this.state.resources[0].instances.length > 0) {
                if (this.state.resources[0].instances[0]['text'] === "All Doctors") {
                    let t = this.state.resources[0].instances
                    console.log("prev", this.state.resources[0])
                    t.shift()
                    console.log("next", t)
                    // let rs = [{
                    //     fieldName: 'doctorID',
                    //     title: 'Doctor',
                    //     instances: t,
                    //   }]
                    // this.setState({resources:rs})
                }

            }

        });



        this.setState({
            // appointments: appointments,
            // appointmentSize: Object.keys(appointments).length,
            loading: false
        });
    }

    render() {
        const {
            data, resources, grouping, groupByDate, isGroupByDate,
        } = this.state;
        return (
            <>
                <GroupOrderSwitcher isGroupByDate={isGroupByDate} onChange={this.onGroupOrderChange} />
                <Paper>
                    <Scheduler data={this.state.appointments} height={960}>
                        <ViewState
                            defaultCurrentDate={this.state.today_d}
                            defaultCurrentViewName="Day"
                        />
                        <GroupingState
                            grouping={grouping}
                            groupByDate={groupByDate}
                        />
                        <DayView
                            startDayHour={8}
                            endDayHour={24}
                        />
                        <WeekView startDayHour={8} endDayHour={24} timeTableCellComponent={TimeTableCell}
                            dayScaleCellComponent={DayScaleCell} />
                        <MonthView />
                        <Toolbar />
                        <DateNavigator />
                        <TodayButton />
                        <ViewSwitcher />
                        <Appointments />
                        <Resources
                            data={resources}
                            mainResourceName="doctorID"
                        />
                        <IntegratedGrouping />
                        <AppointmentTooltip
                            showCloseButton
                            showOpenButton
                        />
                        <AppointmentForm
                            readOnly
                        />
                        <GroupingPanel />
                    </Scheduler>
                </Paper>
            </>
        )
    }
}