import firestore from "../firebase";

const db = firestore.collection("/appointments");

class AppointmentDataService {

    getAll() {
        return db.orderBy('createdAt',"desc");
    }

    getDate(appt){
        return db.doc(appt);
    }

    getAllAfter(date){
        let d = new Date(date*1000);
        let de = "'" + d.getFullYear().toString() + "-" + (d.getMonth()+1).toString() + "-" + d.getDate().toString() + "'";
        let end = new Date(de);
        return db.where('appointmentDate','>=',end)
    }

    getThisWeek(){
        let d = new Date();
        d.setDate(d.getDate() - 30)
        let de = "'" + d.getFullYear().toString() + "-" + (d.getMonth()+1).toString() + "-" + d.getDate().toString() + "'";
        let start = new Date(de);
        let e = d.setDate(d.getDate() + 60)
        let ed = "'" + d.getFullYear().toString() + "-" + (d.getMonth()+1).toString() + "-" + d.getDate().toString() + "'";
        let end = new Date(ed);
        
        return db.where('appointmentDate','>=',start).where('appointmentDate','<=',end)
    }

    getThisWeekDoc(doc){
        let d = new Date();
        d.setDate(d.getDate() - 30)
        let de = "'" + d.getFullYear().toString() + "-" + (d.getMonth()+1).toString() + "-" + d.getDate().toString() + "'";
        let start = new Date(de);
        let e = d.setDate(d.getDate() + 60)
        let ed = "'" + d.getFullYear().toString() + "-" + (d.getMonth()+1).toString() + "-" + d.getDate().toString() + "'";
        let end = new Date(ed);
        return db.where('appointmentDate','>=',start).where('appointmentDate','<=',end).where('appointmentDoctor','==',doc)
    }

    create(appt) {
        return db.add(appt);
    }

    update(id, value) {
        return db.doc(id).set(value);
    }

    delete(id) {
        return db.doc(id).delete();
    }
}

export default new AppointmentDataService();