import React from 'react'
import ServiceTemplate from "./serviceTemplate";

const HTMLcont = (
    <>
        <h5 className="subs" style={{"textAlign":"justify"}}>
            Our Mind Body Medicine expert, Dr. Ashima Shukla at Prana provides customized
            tools to regain a sense of control and well-being, develop coping strategies to better
            manage stress and self-care through evidence-based techniques. Mind Body
            Medicine at Prana helps to effectively reduce stress related illness, promote health
            and quality of life.
            <br/><br/>
            Mind Body Medicine refers to the interactions between the brain, mind, body, and
            behaviour, and the powerful ways in which emotional, social, spiritual, and
            behavioural issues can directly affect our health. Mind Body Medicine respects and
            enhances each person's capacity for self-knowledge and self-care.
            <br/><br/>
            Each human has a basic set of genes (DNA) that is unique to them that they inherit
            from their parents, however, lifestyle, environment, disease condition, stress levels
            etc. determine which of these genes are turned on or turned off in that person.
            Moreover, a given disease condition, or a lifestyle habit may have different effect on
            the genes in different individuals. This change in gene expression without a change
            of DNA sequence is known as Epigenetics. Human genes can turn on the bad genes
            and turn off the good genes especially in chronic conditions such as diabetes,
            cardiovascular, arthritis conditions etc. Mind Body Medicine strategies at Prana help
            by correcting this gene expression resulting in reversal of chronic diseases.
            <br/><br/>
            Our expert creates personalized programs to enhance emotional wellbeing, reduce
            stress, achieve optimal health, and peak performance in personal & professional life.
            <br/><br/>
            <bold style={{fontWeight:600}}>Lifestyle diseases and health issues managed at Prana:</bold>
        </h5>
        <ul className="list-group">
            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Chronic Stress
            </li>
            <li className="list-group-item faders b"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Anxiety
            </li>
            <li className="list-group-item faders c"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                High Blood Pressure
            </li>
            <li className="list-group-item faders d"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Diabetes
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Infertility
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Asthma
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Allergies
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Persistent Pain
            </li>
            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                Migraine
            </li>
        </ul>

    </>
);

const content =
    {
        title: "Mind Body Medicine",
        imgurl: "#",
        para: HTMLcont
    };


class MindBodyMedicine extends React.Component {
    render() {
        return (
            <ServiceTemplate serviceContent={content}/>
        )
    }
}

export default MindBodyMedicine