import React from 'react'
import ServiceTemplate from "./serviceTemplate";

const HTMLcont = (
    <>
        <h5 className="subs" style={{"textAlign":"justify"}}>
            Fitness training and active rehabilitation by a qualified Physiotherapist is done with a
            modern approach. Our team will develop a comprehensive exercise program
            designed to increase your range of movement, improve posture and body
            mechanics, increase flexibility, mobility, and muscular tone.
            <br/><br/>
            We also offer Active Rehabilitation programs which include 1-on-1 sessions with a
            Physiotherapist. The goal of Active Rehabilitation is to help you return to normal
            function with pain-free movement. This program will provide you with the knowledge
            you need to continue exercises independently and get back to active living.
            <br/><br/>
            Conditions managed at Prana include Chronic pain, Neck pain, Low back ache and
            Sports Injuries.
            <br/><br/>
            <bold style={{fontWeight:600}}>Services & therapies offered at Prana:</bold>
        </h5>
        <ul className="list-group">
            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Fitness
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Rehabilitation
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Pilates
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Craniosacral therapy
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Cupping
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Osteopathy
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Chiropractic
            </li>
        </ul>

    </>
);

const content =
    {
        title: "Physiotherapy and Fitness",
        imgurl: "#",
        para: HTMLcont
    };


class Physiotherapy extends React.Component {
    render() {
        return (
            <ServiceTemplate serviceContent={content}/>
        )
    }
}

export default Physiotherapy