import React, { useState, useEffect } from "react";
import Header from "../header";
import Footer from "../footer";
import { loadStripe } from '@stripe/stripe-js';
import CryptoJS from "crypto-js"
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const stripePromise = loadStripe(process.env['REACT_APP_STRIPE_CLIENT_KEY']);

export default function BillPage() {
    const [checked, setChecked] = useState(false);
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Purpose, setPurpose] = useState("");
    const [Cost, setCost] = useState("");
    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        if(query.get("success")){
            setChecked(true);
        }else{
            let a = decodeURIComponent(query.toString());
            let bytes  = CryptoJS.AES.decrypt(a, (process.env['REACT_APP_CRYPTO_KEY']).toString());
            let originalText = bytes.toString(CryptoJS.enc.Utf8);
            let newQuery = new URLSearchParams(originalText);
            setName(newQuery.get("name"));
            setEmail(newQuery.get("email"));
            setPurpose(newQuery.get("purpose"));
            setCost(newQuery.get("cost"));
        }


    }, []);

    const handleClick = async (event) => {
        const stripe = await stripePromise;
        const response = await fetch("https://api.pranaintegrative.com/create-checkout-session-2", {
            method: "POST",
            mode: 'cors',
            headers: {
                //'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',

            },
            body: new URLSearchParams({
                'email':Email,
                'name': Name,
                'cost':Cost,
                'imageU':"https://i.imgur.com/lJDhVeS.png",
                'eventName':Purpose
            }),
        });
        const session = await response.json();
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        }
    };


    return (
        <div>
            <Header/>
            <div style={{padding: "5%", textAlign: "center"}}>
                <h1>
                    <section>
                        {!checked ?
                            <><div className="product">

                                <div className="description">
                                    <h3>{Purpose}</h3>
                                    <h4>Bill for {Name}</h4>
                                    <h5>Rs. {Cost}</h5>
                                </div>
                            </div>
                                <button id="checkout-button" role="link" onClick={handleClick}>
                                    Checkout
                                </button></>
                            :
                            <p>Purchase Successful. Thank you!</p>}

                    </section>
                </h1>
            </div>

            <Footer/>
        </div>
    );
}