import React from 'react';
import '../App.css'
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Footer() {
    return (

        <section id="footer">
            <div className="container">
                <div className="row text-center text-xs-center text-sm-left text-md-left">
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <h5>Prana</h5>
                        <ul className="list-unstyled quick-links">
                            <li><a href="/">Home</a></li>
                            <li><a href="/#abtPrana">Who We Are</a></li>
                            <li><a href="/team">Team</a></li>
                            <li><a href="/#whyPrana">Why Prana</a></li>
                            <li><a href="/Services">Practices At Prana</a></li>
                            <li><a href={"/PersonalisedHCP"}>Personalised Health Care Programs</a></li>
                            <li><a href="/center">The Center</a></li>
                            <li><a href="/contact">Visit Us</a></li>
                            <li><a href="/Login">Login</a></li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <h5>Connect</h5>
                        <ul className="list-unstyled quick-links">
                            <li className="list-inline-item"><a style={{display: "table-cell"}} rel="noreferrer" href="https://www.facebook.com/PranaCentreforIntegrativeMedicine/">
                                <i className="fa fa-facebook"></i></a></li>

                            <li className="list-inline-item"><a style={{display: "table-cell"}} rel="noreferrer" href={"https://www.instagram.com/prana.integrative/"}><i className="fa fa-instagram"></i></a></li>

                            <li className="list-inline-item"><a style={{display: "table-cell"}} rel="noreferrer" href="mailto:contact@pranaintegrative.com"><i className="fa fa-envelope"></i></a></li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <h5>Stay in Touch</h5>
                        <ul className="list-unstyled quick-links">

                            <label htmlFor="inp" className="inp">
                                <input type="text" id="inp" placeholder="&nbsp;"/>
                                    <span className="label">Email</span>
                                    <span className="border"></span>
                                    <button id="email-submit">Submit</button>
                            </label>

                        </ul>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">

                        <p className="h6 copy" style={{color:'#6B7A8F'}}>{'\u00A9'} Prana - The Integrative Medicine Institute 2024</p>
                    </div>
                </div>


            </div>
        </section>
    );
}
