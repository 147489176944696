import React, { Component } from "react";
//import PatientDataService from "../../services/patient.service";
import PatientSchema from "./patients-schema"
import DatePicker from 'react-datepicker'
import ReCAPTCHA from "react-google-recaptcha";
import "react-datepicker/dist/react-datepicker.css";
import firestore from "../../firebase";

const patindex = firestore.collection("/public").doc('patIndex');

async function getDocument() {
    // [START get_document]
    const doc = await patindex.get();
    if (!doc.exists) {
        console.log('No such document!');
    } else {
        console.log('Document data:', doc.data());
        return doc.data()['size']
    }
    // [END get_document]
}

const STAGE = 0;
const recaptchaRef = React.createRef();
const patientTemplate_strings = PatientSchema.filter(checkStage);

function checkStage(s){
    return s["stage"] <= STAGE;
}


export default class AddPatient extends Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.savePatient = this.savePatient.bind(this);
        this.returnFormList = this.returnFormList.bind(this);
        this.newPatient = this.newPatient.bind(this);

        // eslint-disable-next-line
        patientTemplate_strings.map((each) => {
            if(each.type === "text"){
                this.setState({
                    [each.id]: ""
                });
            }
            else if(each.type === "select"){
                this.setState({
                    [each.id]: each.options[1]
                });

            }


        });
        this.state = {
            agreement: false,
            submitted: false,
            captcha: false,
            pickedDate: new Date(),
            "100-stage": 0,
            "0-04-gender": " ",
            "0-05-maritalStatus": " ",
            "0-13-howHeard": " ",
            "0-14-bloodGroup": "A",
            "0-15-rhFactor": "+",
            "0-17-paymentMode": "Credit/Debit Card"
        }

    }

    UNSAFE_componentWillMount() {
        getDocument()
            .then(index => {
                this.setState({ index });
            })
            .catch((err) => {
                // Handle error here (maybe fetch user?)
            })
    }

    returnFormList() {
        let FormItems;
        let fullFORM = [];
        // eslint-disable-next-line
        patientTemplate_strings.map((d, index) =>
        {
            if(d.type === "text" || d.type === "email"){
                FormItems = (<tr key={index}>
                    <td key={index+1000}>
                        <label key={index+1250} htmlFor={d.id} className="block">
                            {d.text}
                        </label>
                    </td>
                    <td key={index+1500}>
                        <input
                            key={index+1750}
                            type= {d.type}
                            name={d.id}
                            required = {d.required}

                            value={this.state[d.id] || ""}
                            id={d.id}
                            onChange={this.handleInputChange}
                        /></td>
                </tr>);
                fullFORM.push(FormItems);
            }
            else if(d.type === "select"){
                let optionItems = d.options.map((o,index2) => <option key={index+(3000+(1000*index2))} value={o}>{o}</option>);
                FormItems = (<tr key={index}>
                    <td key={index+2000}>
                        <label key={index+2250} htmlFor={d.id} className="block">
                            {d.text}
                        </label>
                    </td>
                    <td key={index+2500}>
                        <select
                            key={index+2750}
                            required = {d.required}
                            value={this.state[d.id] || ""}
                            id={d.id}
                            onChange={this.handleChange}
                        >
                            {optionItems}
                        </select></td>
                </tr>);

                fullFORM.push(FormItems);
            }
            else if(d.type === "date"){
                FormItems = (<tr key={index}>
                    <td key={index+1000}>
                        <label key={index+1250} htmlFor={d.id} className="block">
                            {d.text}
                        </label>
                    </td>
                    <td key={index+1500}>
                        <DatePicker
                            closeOnScroll={true}
                            selected={this.state.pickedDate}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                                const formattedDate = date.getDate().toString() + "/" + date.getMonth().toString() + "/" + + date.getFullYear().toString();
                                this.setState({
                                    pickedDate: date,
                                    [d.id]: formattedDate,
                                });
                            }}
                        />
                    </td>
                </tr>);
                fullFORM.push(FormItems);
            }
        });

        return fullFORM
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChange(event) {
        this.setState({[event.target.id]: event.target.value});
    }

    onChangeCaptcha = value => {
        console.log('val ',value)
        if (value === null){
            this.setState({ captcha: false })
        }
        else {
            console.log("captcha verified.");
            this.setState({ captcha: true })
        }
    };

    savePatient = (event) => {
        event.preventDefault();
        let idx = this.state.index;
        //const recaptchaValue = recaptchaRef.current.getValue();
        let data = {};
        let currentdate = new Date();
        let unixtime = + new Date();
        let datetime = currentdate.getDate() + "/"
            + (currentdate.getMonth()+1)  + "/"
            + currentdate.getFullYear() + " "
            + currentdate.getHours() + ":"
            + currentdate.getMinutes() + ":"
            + currentdate.getSeconds();

        let PID = "P-" + currentdate.getMonth().toString() + Math.floor(Math.random() * 100).toString() + idx.toString();
        console.log('PID ',PID);
        // eslint-disable-next-line
        patientTemplate_strings.map((each) =>{
            if(this.state[each.id]){
                data[each.id] = this.state[each.id]
            }
        });
        data["1-04-registerDT"] = unixtime;
        data["1-05-updatedDT"] = "Registered at " + datetime;
        data["100-stage"] = "1";
        data["1-03-PID"] = PID;
        data["1-01-POI"] = "Reception";

        if(this.state.captcha) {
            // PatientDataService.create(data)
            //     .then(() => {
            //         console.log("Created new item successfully!");
            //         this.setState({
            //             submitted: true,
            //         });
            //     })
            //     .catch((e) => {
            //         console.log(e);
            //     });
            console.log("SUBMITTING ",data)
        }else {
            this.setState({
                error: "Sorry, the captcha wasn't verified. Please try again."
            })
        }

        patindex.update({
            size: idx+1
        })

    };

    newPatient() {
        // eslint-disable-next-line
        patientTemplate_strings.map((each) => {
            this.setState({
                [each.id]: ""
            });
        });
        this.setState({
            submitted: false,
            agreement: false,
            captcha: false
        });
    }

    render() {
        return (
            <div style={{padding: "5%", textAlign: "center"}}>
                <h1>New Patient Registration Form</h1>
                <br/><br/>
                {this.state.submitted ? (
                    <div>
                        <h4>Form submitted successfully.</h4>
                        <button style={{width:"13rem",height:"3.5rem"}} id={"form-submit"} onClick={this.newPatient}>
                            Add More
                        </button>

                    </div>
                ) : (

                    <div>

                        <form onSubmit={this.savePatient}>

                            <table id={"form-table"}>
                                <tbody>
                                {this.returnFormList()}
                                </tbody>
                            </table>

                            <br/>
                            <h3>GENERAL CONSENT FOR TREATMENT</h3>
                            <br/>
                            <div className={"col-md-6"} style={{marginLeft:'auto',marginRight:'auto'}}>
                                <p>By clicking submit, I hereby consent to the authorize Prana, its physicians and medical personnel to administer and perform medical examination, investigations, medical treatment, procedures, vaccinations and immunizations during the course of patient care.
                                    <br/><br/>
                                    I also consent to the use of medical information for research or insurance purpose. I also consent to Prana contacting me / my family if needed, regarding appointments and follow-up care.
                                    <br/><br/>
                                    Kindly show your Photo ID (Pan card/ Aadhar Card/ Driving License) issued by authorities when requested.
                                    <br/><br/>
                                </p>
                                <div className={"capt"} >
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        // sitekey="6LfJgt4ZAAAAAGAoDLoW1blEUsaSoqrIW0ETO-2A"
                                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                        onChange={this.onChangeCaptcha}
                                    />
                                </div>
                                <div className={"subm"} >
                                <input id={"form-submit"} type="submit" value="Submit" />
                                {this.state.error !== null && (
                                    <div style={{color: '#f15454'}}>
                                        {this.state.error}
                                    </div>
                                )}
                                </div>
                            </div>
                        </form>


                    </div>

                )}
            </div>
        );
    }
}