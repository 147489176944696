import React, {useState} from "react";
import { auth } from "../../firebase";

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [forgot, setForgot] = useState(false);
    // eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    const signInWithEmailAndPasswordHandler = (event,email, password) => {
        if(email && password && validEmailRegex.test(email)){
            event.preventDefault();
            auth.signInWithEmailAndPassword(email, password).catch(error => {
                setError("Error: Please try again.");
                console.error("Error signing in with password and email", error);
            });
        }

    };

    const sendForgotPassEmail = (event,email) => {
        if(email && validEmailRegex.test(email)){
            event.preventDefault();
            setError("Reset Password email sent.");
            return auth.sendPasswordResetEmail(email)
        }

    };

    const forgotPasswordHandler = (event) => {
        event.preventDefault();
        setForgot(true)
    };

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;

        if(name === 'userEmail') {
            setEmail(value);
        }
        else if(name === 'userPassword'){
            setPassword(value);
        }
    };

    return (
        <div >
            <h1 className={"heroTitle"} >Sign In</h1>
            <div><br/>
                {error !== null && <div>{error}</div>}
                <br/>
                <form className="">
                    <br/><br/>
                    {forgot &&
                        <p>Please enter your email and you shall receive a link to reset your password</p>
                    }
                    <table id={"form-table"}>
                        <tbody>
                        <tr>
                            <td>
                                <label htmlFor="userEmail" className="block">
                                    Email:
                                </label>
                            </td>
                            <td>
                                <input
                                    type="email"

                                    name="userEmail"
                                    value = {email}

                                    id="userEmail"
                                    onChange = {(event) => onChangeHandler(event)}
                                />
                            </td>
                        </tr>
                        <br/><br/>
                        {!forgot &&
                            <>
                                <tr>
                                    <td>
                                        <label htmlFor="userPassword" className="block">
                                            Password:
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            type="password"

                                            name="userPassword"
                                            value = {password}

                                            id="userPassword"
                                            onChange = {(event) => onChangeHandler(event)}
                                        />
                                    </td>
                                </tr>
                                <br/><br/>
                            </>
                        }

                        </tbody>
                    </table>
                    {!forgot &&
                        <>
                            <button  id="form-submit" onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
                                Sign in
                            </button>

                            <button  id="submit-button" onClick = {(event) => {forgotPasswordHandler(event)}}>
                                Forgot Password
                            </button>
                        </>
                    }

                    {forgot &&
                    <button  id="submit-button" onClick = {(event) => {sendForgotPassEmail(event, email)}}>
                        Submit
                    </button>
                    }

                </form>


            </div>
        </div>
    );
};
export default SignIn;