import React from "react";
import '../App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {auth} from "../firebase";

export default function InternalHeader(user) {
    const logo_url = 'https://i.imgur.com/lJDhVeS.png';
    const {isAdmin, isReceptionist, isDoctor} = user["user"];
    return (

        <header role="banner" style={{"height":"10rem"}}>
            <Navbar bg="light" variant="light">
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src={logo_url}

                        height="100"
                        className="d-inline-block align-top"
                    />{' '}

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" style={{marginLeft:100, marginTop:-45}}>
                    <Nav className="mr-auto">
                        {/*<Nav.Link href="/patientList">Patients</Nav.Link>*/}
                        <Nav.Link href="/dashboard">Dashboard</Nav.Link>
                        <Nav.Link href="/appointments">Patients</Nav.Link>
                        {isReceptionist &&
                        <Nav.Link href="/register">Register Patient</Nav.Link>
                        }
                        {isAdmin &&
                        <>
                            {/*<Nav.Link href="/employees">Employees</Nav.Link>*/}
                            <Nav.Link href="/adminCreateUser">Create User</Nav.Link>
                            <Nav.Link href="/events">Events</Nav.Link>
                            <Nav.Link href="/analytics">Analytics</Nav.Link>
                        </>
                        }
                        <Nav.Link href="/bill">Create Bill</Nav.Link>
                        
                        {isReceptionist &&
                             <Nav.Link href="/schedule">Schedule</Nav.Link>
                        }
                        <Nav.Link href="/" onClick = {() => {auth.signOut();}}>Sign Out</Nav.Link>
                        {/*<Nav.Link href="#link">Book an Appointment</Nav.Link>*/}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
}
