import React from 'react'
import Header from "../header";
import Footer from "../footer";
class Contact extends React.Component {
    render() {
        return (
            <div>
            <Header/>

            <div>
                <div className="row" style={{padding: "5%"}}>
                    <div className="col-md-6" style={{paddingTop: "5%"}}>
                        <h1 className="heroTitle">Find Us</h1>

                        <br/>
                            <p className="subs">
                                <a href="tel:+911244881144">+91-124-4881144</a><br/>
                                {"\t"}<a href="tel:+918800555531">+91-8800555531</a><br/>{"\t"}<a href="tel:+918800555532">+91-8800555532</a>
                                <br/>
                                <a href="mailto:contact@pranaintegrative.com">contact@pranaintegrative.com</a><br/><br/>
                                Timings: Monday to Saturday 10 am to 8 pm <br/><br/>
                                Prana Center for Integrative Medicine<br/>
                                No. 1, Club Vita, <br/>
                                Park Drive, DLF 5, Sector 54<br/>
                                Gurugram, Haryana 122009, India
                                <br/><br/>
                                <a href="https://www.facebook.com/PranaCentreForIntegrativeMedicine">Facebook</a> || <a href="https://www.instagram.com/prana.integrative">Instagram</a>
                            </p>
                    </div>

                    <div className="col-md-6">
                        <iframe className="iframeMOb" title="Prana Google Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.2938738997523!2d77.09130561514222!3d28.470695182481837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19b6395efab9%3A0x57e461ad81323b50!2sPrana%20-%20Centre%20for%20Integrative%20Medicine!5e0!3m2!1sen!2sus!4v1568959725779!5m2!1sen!2sus" width={600} height={450} frameBorder={0} style={{border: 0}} allowFullScreen />
                    </div>
                </div>
            </div>

            <Footer/>
            </div>
        )
    }
}
export default Contact
