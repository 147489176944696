import React from 'react'
import Header from "../../header";
import Footer from "../../footer";

class Services extends React.Component {
    render() {
        return (
            <div>
                <Header/>
                <h2 className="heroTitle" style={{padding: "5%"}}>Practices at Prana</h2>
                <div className={"serviceRow"}>
                    <div className={"col-md-6 col-sm-6"} style={{"margin":"auto"}}>
                    <h5 className="subs sub2" style={{"textAlign":"justify"}}>
                        At Prana, we take care of the complete health spectrum from common illnesses, to chronic diseases and emotional health concerns.
                        <br /><br />
                        Our goal is to help our patients and customers reach and maintain their optimal state of health. With a holistic approach, we guide them towards the right screening tests, evaluations, consultations, and treatment programs.  Prana offers long term preventive solutions for renewed energy and wellbeing. Prana’s integrative medicine focuses on the whole person and the unique characteristics of the individual person to create customized healing for their physical, mental, emotional, social and spiritual health.
                    </h5>
                    </div>
                </div>
                <div className={"serviceRowB"} style={{marginBottom:"0"}}>
                    <div className="col-md-4" style={{paddingTop: "3%",margin: "auto"}}>
                        <h3 style={{color:'#00b7bd',fontWeight:"bold"}}>Highest Standards of Infection Control &amp; Prevention Practices:</h3>
                        <br/>
                        <ul className="list-group" style={{fontWeight:"600"}}>
                            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                Screening at entrance for symptoms and exposure including temperature check
                            </li>
                            <li className="list-group-item faders b"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                FDA approved advanced air purification & ventilation
                            </li>
                            <li className="list-group-item faders c"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                Treated Fresh Air Unit
                            </li>
                            <li className="list-group-item faders d"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                Personal protection by all staff
                            </li>
                            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                Face mask mandatory for visitors.
                            </li>
                            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                Strict sanitization of all areas and surfaces
                            </li>
                            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                Maintaining Social Distancing
                            </li>
                            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                Electronic health records
                            </li>
                        </ul>
                    </div>
                </div>
                <br/><br/>
                    <div className={"serviceRowB"}>
                        <div className={"col-md-3 service-block"} style={{marginLeft:"auto",marginRight:"2%"}}>
                            <h3 style={{color:'#00b7bd',fontWeight:"bold"}}>OPD Consultations</h3>
                            <br/>
                            <ul className="list-group">
                                <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Internal Medicine
                                </li>
                                <li className="list-group-item faders b"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Mind Body Medicine
                                </li>
                                <li className="list-group-item faders c"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Paediatrics
                                </li>
                                <li className="list-group-item faders d"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Psychiatry
                                </li>
                                <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Psychology & Counselling
                                </li>
                                <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Gynaecology & Obstetrics
                                </li>
                                <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Surgeon
                                </li>
                                <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Dermatology
                                </li>
                                <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Nutritionist (Dietician)
                                </li>
                                <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Physiotherapy
                                </li>
                                <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Ayurveda
                                </li>
                                <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Homeopathy
                                </li>

                            </ul>
                            <br/>
                            <hr/>
                            <h3 style={{color:'#00b7bd',fontWeight:"bold"}}>Investigations and Diagnostics</h3>
                            <br/>
                            <ul className="list-group" >
                                <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Blood tests
                                </li>
                                <li className="list-group-item faders b"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Ambulatory BP monitoring, ECG, Biothesiometry, Body Composition and BMI
                                </li>
                                <li className="list-group-item faders c"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Nursing care: Vaccinations, Dressings, First Aid Services, Injections and IV fluids
                                </li>
                            </ul>
                            <br/>
                            <hr/>
                            <h3 style={{color:'#00b7bd',fontWeight:"bold"}}>Personalized Healthcare Programs</h3>
                            <br/>
                            <ul className="list-group " >
                                <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Prevention
                                </li>
                                <li className="list-group-item faders b"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Treatment
                                </li>
                                <li className="list-group-item faders c"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Management
                                </li>
                            </ul>
                            <br/>
                        </div>
                        <div className={"col-md-3 service-block"} style={{marginRight:"auto"}}>
                            <h3 style={{color:'#00b7bd',fontWeight:"bold"}}>Therapies & Other Services</h3>
                            <br/>
                            <ul className="list-group">
                                <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Psychotherapy
                                </li>
                                <li className="list-group-item faders b"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Cognitive behaviour therapy
                                </li>
                                <li className="list-group-item faders c"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Speech therapy
                                </li>
                                <li className="list-group-item faders d"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Biofeedback
                                </li>
                                <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Meditation & Mindfulness
                                </li>
                                <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Naturopathy
                                </li>
                                <li className="list-group-item faders b"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Hypnotherapy
                                </li>
                                <li className="list-group-item faders c"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Transactional analysis
                                </li>
                                <li className="list-group-item faders d"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Art therapy
                                </li>
                                <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Dance therapy
                                </li>
                                <li className="list-group-item faders d"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Sound therapy
                                </li>
                                <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Metaphor therapy
                                </li>
                                <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Sujok therapy
                                </li>
                                <li className="list-group-item faders b"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Ozone therapy
                                </li>
                                <li className="list-group-item faders c"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Energy healing
                                </li>
                                <li className="list-group-item faders d"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Bach flower therapy
                                </li>
                                <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                    Nature therapy
                                </li>
                            </ul>
                            <br/>
                            <hr/>
                            <h3 style={{color:'#00b7bd',fontWeight:"bold"}}>Other Services</h3>
                            <br/>
                            <ul className="list-group" >
                                <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Psychological assessment & testing
                                </li>
                                <li className="list-group-item faders b"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Pilates
                                </li>
                                <li className="list-group-item faders c"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Body talk
                                </li>
                                <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Acupuncture
                                </li>
                                <li className="list-group-item faders b"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Neurolinguistic programming
                                </li>
                                <li className="list-group-item faders c"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                    Osteopathy & Chiropractic
                                </li>
                            </ul>
                            <br/>
                        </div>
                    </div>

                <Footer/>
            </div>
        )
    }
}
export default Services
