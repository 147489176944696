import React, { Component } from "react";
import Header from "../header";
import Footer from "../footer";
import firestore from "../../firebase";
import RegistrationStepper from "./regStepper";
import MetaTags from 'react-meta-tags';

export default class EventRegister extends Component {
    constructor(props) {
        super(props);
        this.getDocument = this.getDocument.bind(this);
        this.state = {
            loaded: false
        }
    }


    getDocument = async(event) =>{
        // [START get_document]
        const doc = await event.get();
        if (!doc.exists) {
            console.log('No such document!');
        } else {
            return doc.data()
        }
        // [END get_document]
    };

    UNSAFE_componentWillMount() {
        const event = firestore.collection("/events").doc(this.props.match.params.EID);

        this.getDocument(event)
            .then(EventDetails => {
                this.setState({ EventDetails, loaded: true, eid: this.props.match.params.EID });
            })
            .catch((err) => {
                // Handle error here (maybe fetch user?)
            })
    }

    returnDate(unix_timestamp){

// Create a new JavaScript Date object based on the timestamp
// multiplied by 1000 so that the argument is in milliseconds, not seconds.

        let date = new Date(unix_timestamp * 1000);

        let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        //TODO WHAT THE HELL FIX THIS BUG!!!!
        let year = date.getFullYear() - 1969;
        let month = months[date.getMonth()];
        let dateN = date.getDate();
// Seconds part from the timestamp

// Will display time in 10:30:23 format
        return dateN + ' ' + month + ' ' + year + ' ' ;

    }

    returnTime(unix_timestamp){

// Create a new JavaScript Date object based on the timestamp
// multiplied by 1000 so that the argument is in milliseconds, not seconds.

        let date = new Date(unix_timestamp * 1000);
// Hours part from the timestamp
        let hours = date.getHours();
// Minutes part from the timestamp
        let minutes = "0" + date.getMinutes();
// Seconds part from the timestamp

// Will display time in 10:30:23 format
        return hours + ':' + minutes.substr(-2);

    }





    render() {
        if(this.state.loaded){
            return (
                <div>
                    <MetaTags>
                        <meta property="og:image" content={this.state.EventDetails.imageURL}/>
                    </MetaTags>
                    <div className="thumbnail">
                        <img src={this.state.EventDetails.imageURL} alt=""/>
                    </div>
                    <Header/>
                    <div style={{padding: "5%", textAlign: "center"}}>
                        <h1>Register for {this.state.EventDetails.Title}</h1>
                        <p>{this.state.EventDetails.Description}</p>
                        <br/>
                        <h5>{this.returnDate(this.state.EventDetails['Starts At'])} - {this.returnDate(this.state.EventDetails['Ends At'])}
                        <br/>
                            {this.returnTime(this.state.EventDetails['Starts At'])} - {this.returnTime(this.state.EventDetails['Ends At'])}
                        </h5>
                        <br/>
                        <img
                            src={this.state.EventDetails.imageURL}
                            alt="The cover of Event"
                            className={"eventImg"}
                        />
                        <RegistrationStepper title={this.state.EventDetails.Title}
                        cost={this.state.EventDetails.Cost} eid={this.state.eid} imageURL={this.state.EventDetails.imageURL}/>
                    </div>

                    <Footer/>
                </div>
            );
        }else{
            return (
                <div>
                    <Header/>
                    <div style={{padding: "5%", textAlign: "center"}}>
                        <h1> </h1>
                    </div>

                    <Footer/>
                </div>
            );
        }

    }
}

