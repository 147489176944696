import React from 'react'
import Header from "../header";
import Footer from "../footer";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const partnerList = [
    {
        name: "Dr. Dangs Lab",
        logo: "https://i.imgur.com/BfLAxM6.jpg"
    },
    {
        name: "Twin Health",
        logo: "https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/partners%2FTwin%20Health%20Heart%20Logo%20Stacked%20Spot%20PNG.png?alt=media&token=0ca94fa2-add9-4013-b752-4793f08a4a3d"
    },
    {
        name: "Ayurveda Expert",
        logo: "https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/partners%2FAE%20LOGO.jpeg?alt=media&token=9d36816a-6239-4c32-96bc-10502bfb27da"
    },
    {
        name: "Om Yogshala",
        logo: "https://i.imgur.com/JizQHjC.jpg"
    },
    {
        name: "Olive Tree",
        logo: "https://i.imgur.com/4HxQ9XU.jpg"
    }
]
export default function Partners(){


    return (
        <div>
            <Header/>
            <h2 className="heroTitle" style={{padding: "5%",fontSize:'45px'}}>Our Partners</h2>
            <div className={"serviceRow"}>
                <div className={"col-md-6 col-sm-6"} style={{"margin":"auto"}}>
                    {/*<img src={content.imgurl}/>*/}
                </div>
            </div>
            <div style={{textAlign:'center'}}>
            {partnerList.map((each) =>
                            <div className={"col-md-12"} style={{marginBottom:'5%'}}>
                                    <LazyLoadImage src={each['logo']} style={{width:"25%"}} alt={each['name']+" logo"}/>
                                    <br/>
                                    <h4>{each['name']}</h4>

                            </div>
                        )}
                
            </div>
            <br/><br/>
            <Footer/>
        </div>
    )

}