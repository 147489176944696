import React, {useState} from 'react';
import '../App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


export default function Header() {
    const logo_url = 'https://i.imgur.com/lJDhVeS.png';

    const [show, setShow] = useState(false);
    const showDropdown = (e)=>{
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }

    return (

        <header role="banner">
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="/"><img src={logo_url} className="logo" alt={"Prana Integrative Medicine Institute logo"}
                                                            id="logo"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto" style={{justifyContent:"center"}}>
                        
                        <Link activeClass="active" className="nav-link" to="aboutPrana" spy={true} smooth={true} duration={250} >About Prana</Link>
                            
                       
                        <Nav.Link href="/team">Team</Nav.Link>
                        <Nav.Link href="/Partners">Our Partners</Nav.Link>
                        <Link activeClass="active" className="nav-link" to="whyPrana" spy={true} smooth={true} duration={250} >Why Prana</Link>
                        <NavDropdown title="Services"
                                     id="collasible-nav-dropdown"
                                     show={show}
                                     onMouseEnter={showDropdown}
                                     onMouseLeave={hideDropdown}
                                     href={"/services"}
                        >
                            <NavDropdown.Item href="/Services">Practices At Prana</NavDropdown.Item>
                            <NavDropdown.Item href={"/PersonalisedHCP"}>Personalised Health Care Programs</NavDropdown.Item>
                            <hr/>
                            <NavDropdown.Item href={"/InternalMedicine"}>Internal Medicine</NavDropdown.Item>
                            <NavDropdown.Item href={"/MindBodyMedicine"}>Mind Body Medicine</NavDropdown.Item>
                            <NavDropdown.Item href={"/Counselling"}>Counselling and Psychotherapy</NavDropdown.Item>
                            <NavDropdown.Item href={"/Nutrition"}>Personalised Nutrition</NavDropdown.Item>
                            <NavDropdown.Item href={"/Ayurveda"}>Ayurveda</NavDropdown.Item>
                            <NavDropdown.Item href={"/Physiotherapy"}>Physiotherapy and Fitness</NavDropdown.Item>
                            <NavDropdown.Item href={"/NLP"}>Neurolinguistic Programming</NavDropdown.Item>
                            <NavDropdown.Item href={"/Philebotomy"}>Blood Sampling & Diagnostics</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="/center">The Center</Nav.Link>
                        {/*<Nav.Link href="/gallery">Gallery</Nav.Link>*/}
                        {/*<Nav.Link href="/programs">Programs</Nav.Link>*/}
                        <Nav.Link href="/contact">Contact Us</Nav.Link>
                        
                        {/*<Nav.Link href="/BookAppointment">Book an Appointment</Nav.Link>*/}
                        <Nav.Link href="/Login">Login</Nav.Link>
                        <a aria-label="Chat on WhatsApp" href="https://wa.me/918800555532" style={{"marginTop": '5px'}}> 
                            <img alt="Chat on WhatsApp" src="https://firebasestorage.googleapis.com/v0/b/prana-his-database.appspot.com/o/publicsite%2FWhatsAppButtonWhiteSmall.svg?alt=media&token=94c3968c-0899-427d-8b52-8d50881904f4
                    " />
                        </a>

                        </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
}
