import React from 'react';
import '../App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'




export default function HeroImage() {
    const pictureLink1 = 'https://i.imgur.com/ZjX5aCR.png';
    const pictureLink2 = 'https://i.imgur.com/FzgOX1dr.png';
    const pictureLink3 = 'https://i.imgur.com/yvDNify.png';
        return (
            <div>
                <div className="row" style={{padding: "5%"}}>
                    <div className="col-4"><img src={pictureLink1} className="heroImage right-img" alt={"Lotus 1"}/></div>
                    <div className="col-8" style={{paddingTop: "12%"}}>
                        <h1 className="heroTitle">Redefining Healthcare.</h1>
                        <br/>
                         {/* <Link activeClass="active" to="aboutPrana" spy={true} smooth={true} duration={250} containerId="containerElement" >
                         <p className="heroLink" style={{color:'#00b7bd'}}>Learn More</p>
                            </Link> */}
                        {/* <a href={"/#abtPrana"}></a> */}
                    </div>
                </div>
            
                <Element name="aboutPrana">
                
       
            
                <div className="row" style={{padding: "5%"}} >
                    <div className="col-6" style={{paddingTop: "8%"}}>
                        <h1 className="heroTitle">Who We Are</h1>
                        <br/>
                        <h2>Prana</h2>
                        <h3> Breath, original life force</h3>
                        <br /><br /><br />
                        <p className="subs sub2" style={{}}>
                            Prana Integrative Medicine Institute is India’s first holistic healthcare and wellness center that offers evidence based, relationship centered, and personalised care by integrating allopathic and alternative methods of treatment and healing.
                            <br /><br />
                            Prana’s Integrative therapy focuses on the whole person and the unique characteristics of the individual person to create customized healing for their physical, mental, emotional, social and spiritual wellbeing.
                        </p>
                        <br /><br />
                        <div className={"row"}>
                        <div className={"col-md-6 col-sm-12"}>
                        <h3 style={{color:'#00b7bd'}} className={"mobHead"}>Our Mission:</h3>
                        <p className="subs sub2" style={{}}>
                            To offer the highest standard of patient care, customized evidence-based treatment,
                            through integrative medicine that addresses the whole person focusing on physical,
                            mental, emotional, social, and spiritual influences affecting health and wellbeing.
                        </p>
                        </div>
                        <div className={"col-md-6"}>
                            <h3 style={{color:'#00b7bd'}} className={"mobHead"}>Our Vision:</h3>
                            <p className="subs sub2" style={{}}>
                                To transform healthcare and wellbeing paradigms through evidence-based holistic
                                treatment integrating conventional and complimentary systems of medicine, to
                                prevent lifestyle related non-communicable diseases and creating healthy
                                individuals.
                            </p>
                        </div>
                        </div>
                        <br/><br/>
                        <a href={"/Team"}><h5 className="heroSubLink heroLink" style={{color: 'rgb(246, 146, 30)',
    border: '2px solid rgb(246, 146, 30)',
    width: '42vh',    margin: '0 auto',
    padding: '1.5%',
    borderRadius: '5px'}}>Meet The Team</h5></a>
                    </div>

                    <div className="col-6"><img src={pictureLink2} className="heroImage right-img" alt={"Lotus 2"} style={{marginTop:'15%'}}/></div>
                </div>
                </Element>


                <Element name="whyPrana">
                <div className="row" style={{padding: "5%"}}>
                    <div className="col-6"><img src={pictureLink3} className="heroImage left-img" alt={"Lotus 3"} style={{marginTop:'15%'}}/></div>

                    <div className="col-6" style={{paddingTop: "8%"}}>
                        <h1 className="heroTitle">Why Prana</h1>
                        <br/>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                Overcome limitations of
                                allopathic medicine
                            </li>
                            <li className="list-group-item faders b"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                Focus on health and
                                wellbeing of the whole person
                            </li>
                            <li className="list-group-item faders c"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                                Evidence based
                                personalised care
                            </li>
                            <li className="list-group-item faders d"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                Empathetic and deeper
                                patient-physician/practitioner relationship for improved therapy
                            </li>
                            <li className="list-group-item faders e"><i className="fa fa-angle-right fa-fw" aria-hidden="true"></i>
                                Health promotion and
                                attention to lifestyle changes, including nutrition, exercise, stress management and
                                emotional wellbeing, for enhanced resiliency
                            </li>

                        </ul>
                    </div>
                </div>
                </Element>

                <section className="pricing-2 ">
                    <div className="container">

                        <div className="row pricing-tables">
                            <div className="col-md-6 no-pad-right hidden-sm">
                            </div>
                            <div className="col-md-6 no-pad hidden-sm">
                                <div className="pricing-table">
                                    <ul className="features">
                                        <li><strong>
                                            Prana’s Integrative Medicine
                                        </strong></li>
                                        <li>Treatment address the root cause</li>
                                        <li>Individualized therapy</li>
                                        <li>Understands the human body as a complex, interconnected and finely balanced
                                            unit
                                        </li>
                                        <li>The patient is actively included in her/his/their healing journey</li>
                                        <li>Offers long term preventive solutions for renewed energy and wellbeing</li>
                                    </ul>


                                </div>
                            </div>

                            <div className="col-md-6 no-pad-left hidden-sm">
                                <div className="pricing-table">
                                    <ul className="features">
                                        <li><strong>Regular Healthcare</strong></li>
                                        <li>Treatment addresses the symptoms of the disease</li>
                                        <li>One size fits all therapy</li>
                                        <li>Understands the human body as composed of fragmented non connected systems,
                                            each needing a specialist
                                        </li>
                                        <li>Doctor assumes an authoritative role with no involvement of the patient</li>
                                        <li>Offers temporary symptomatic relief; Addresses only the disease, ignoring
                                            the long term side effects
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>


        );


}