import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import {Button} from "react-bootstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class TeamCardNoBio extends React.Component {

    render() {
        return (
            <div>
                <LazyLoadImage src={this.props.member.photolink} style={{width: "300px", height: "300px", objectFit: "cover", objectPosition: "100% 0"}} alt={this.props.member.name+" picture"}/>
                <Accordion className={"acc"}>
                    <Card style={{color:"#00B3B9"}}>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                <h2 style={{color:"#6B7A8F",fontSize:"150%",whiteSpace: "normal"}}>{this.props.member.name}</h2>
                                <p style={{whiteSpace: "normal"}}>{this.props.member.designation}</p>
                            </Accordion.Toggle>
                        </Card.Header>
                    </Card>
                </Accordion>
            </div>

        );
    }
}