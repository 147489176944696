import React from 'react'
import ServiceTemplate from "./serviceTemplate";

const HTMLcont = (
    <>
        <h5 className="subs" style={{"textAlign":"justify"}}>
            Ayurveda is the oldest healthcare system which originated in India more than 5,000
            years ago. The Sanskrit word Ayurveda translates to knowledge of life.
            <br/><br/>
            According to Ayurveda, diseases occur due to imbalances in the body, mind, consciousness,
            and the environment. Ayurveda encourages certain lifestyle interventions and natural
            therapies to reverse these imbalances. Ayurveda treatment typically involves an internal
            purification process, followed by a special diet, herbal remedies, massage therapy, Yoga,
            and Meditation. At Prana, the exact procedure is finalised after a customized evaluation of
            each patient’s constitution.
            <br/><br/>
            The basic principles of Ayurveda include the concepts of universal interconnectedness, the
            body's constitution or Prakriti, and life forces or Doshas.
            <br/><br/>
            Ayurvedic treatments aim to eliminate impurities, reduce symptoms, increase resistance to
            disease, reduce worry, and increase harmony in life. Herbs and other plants, including oils
            and common spices, are used extensively in Ayurveda treatment.
            <br/><br/>
            <bold style={{fontWeight:600}}>At Prana, we integrate the ancient wisdom of Ayurveda with the modern advances of
                conventional system of medicine. Prana is India’s first holistic health centre where
                evidence-based integration is practiced for managing lifestyle diseases.
            </bold>
            <br/><br/>
            <bold style={{fontWeight:600}}>Health issues and conditions managed at Prana:</bold>
        </h5>
        <ul className="list-group">
            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Boosting Immunity
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Diabetes
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                High blood pressure
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                High Cholesterol
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Mental health
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Bloating
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Overweight & Obesity
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Allergies
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Gut health
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Skin & hair conditions
            </li>

            <li className="list-group-item faders a"><i className="fa fa-angle-right fa-fw"aria-hidden="true"></i>
                Chronic Stress
            </li>

        </ul>

    </>
);

const content =
    {
        title: "Ayurveda",
        imgurl: "#",
        para: HTMLcont
    };


class Ayurveda extends React.Component {
    render() {
        return (
            <ServiceTemplate serviceContent={content}/>
        )
    }
}

export default Ayurveda