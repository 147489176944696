import firestore from "../firebase";

const db = firestore.collection("/patients");

class PatientDataService {

    getAll() {
        return db.orderBy('1-04-registerDT',"desc");
    }

    getAllAccess(stage){
        return db.where('100-stage','==',stage);
    }

    getAllDoc(doc){
        return db.where('1-01-POI','==',doc);
    }

    getAllAfter(d){
        let fd = d*1000;
        return db.where('1-04-registerDT','>=',fd)
    }

    create(patient) {
        return db.add(patient);
    }

    update(id, value) {
        return db.doc(id).set(value);
    }

    add(id, value) {
        return db.doc(id).update(value);
    }

    delete(id) {
        return db.doc(id).delete();
    }
}

export default new PatientDataService();