import firestore from "../firebase";

const db = firestore.collection("/events");

class EventDataService {

    getAll() {
        return db;
    }

    create(event) {
        return db.add(event);
    }

    update(id, value) { //fresh update
        return db.doc(id).set(value);
    }

    add(id, value) { //append
        return db.doc(id).update(value);
    }

    delete(id) {
        return db.doc(id).delete();
    }
}

export default new EventDataService();